.skills {
  padding: 0 size(80px);
  max-width: size(1440px);
  margin: 0 auto;
  margin-top: -20vh;

  @include viewport--md {
    padding: 0 16px;
    margin-top: 64px;
  }

  .section-title {
    text-align: center;
    margin-bottom: size(20px);

    @include viewport--md {
      margin-bottom: 20px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, #{size(180px)});
    gap: size(40px);
    list-style: none;
    padding: 0;
    margin: 0;
    justify-items: center;
    justify-content: center;

    @include viewport--md {
      gap: size(10px);
      grid-template-columns: repeat(auto-fill, 166px);
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: size(30px);
    background-color: var(--white-accent);
    padding: size(40px);
    border-radius: size(20px);

    svg {
      fill: none;
      width: size(100px);
      height: size(100px);

      @include viewport--md {
        width: 64px;
        height: 64px;
      }
    }

    p {
      margin: 0;
      font-size: size(24px);
      font-weight: 500;
      line-height: size(28px);
      letter-spacing: 0.02em;

      @include viewport--md {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
