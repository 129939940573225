.btn-rounded {
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  transition: background-color $default-transition-settings,
    color $default-transition-settings,
    border-color $default-transition-settings,
    opacity $default-transition-settings;
  position: relative;

  font-weight: 500;
  font-size: size(36px);
  line-height: size(40px);
  font-family: $font-family--neue-machina;
  letter-spacing: normal;

  padding: size(18px) size(28px) size(20px);
  background-color: var(--btn-bg-color);
  color: $color-default-white;

  border: size(2px) solid var(--btn-bg-color);
  border-radius: size(20px);
  outline: none;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      background-color: var(--btn-bg-color-hover);
      color: var(--btn-text-color-hover);
      border-color: var(--btn-bg-color-hover);
    }
  }

  @include viewport--md {
    font-size: 36px;
    line-height: 40px;
    padding: 18px 22px 20px;
    border-width: 2px;
    border-radius: 20px;
  }

  @include viewport--sm {
    font-size: 22px;
    line-height: 24px;
    border-radius: 25px;
  }

  &--white {
    padding: size(8px) size(18px) size(10px);
    font-size: size(24px);
    line-height: 1.33;
    color: var(--text-color-2);
    background-color: var(--btn-rounded-white-bg-color);
    border-color: var(--grey-border-color);

    @include viewport--md {
      padding: 8px 16px 10px;
      font-size: 24px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 22px;
      border-radius: 15px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        background-color: var(--btn-bg-color-05);
        color: var(--btn-bg-color);
        border-color: var(--btn-bg-color);
      }
    }

    &.is-active {
      background-color: var(--btn-bg-color-opcity);
      color: var(--btn-bg-color);
      border-color: var(--btn-bg-color);
      pointer-events: none;
    }
  }

  &--opacity {
    color: var(--btn-bg-color);
    background-color: var(--btn-bg-color-opcity);
    border-color: $color-transparent;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: var(--btn-bg-color);
        background-color: var(--btn-bg-color-opcity);
        border-color: $color-transparent;
        opacity: 0.6;
      }
    }
  }

  &--transparent {
    color: var(--btn-bg-color);
    background-color: $color-transparent;
    border-color: $color-transparent;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: var(--btn-bg-color-60);
        background-color: $color-transparent;
        border-color: $color-transparent;
      }
    }
  }

  &[disabled] {
    color: $color-default-white;
    background-color: $color-whisper;
    border-color: $color-whisper;
    pointer-events: none;
  }
}
