.butterfly {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.butterfly__el {
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: $color-quartz;
  color: $color-neon-blue;
  transition: bottom $default-transition-settings, top $default-transition-settings;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  html.dark & {
    background: $color-charcoal;
    color: $color-white;
  }
}

.butterfly__el--intro {
  &-1 {
    bottom: 53px;
    left: 96px;
  }

  &-2 {
    top: -23px;
    left: 193px;
  }

  &-3 {
    left: calc(50% + 16px);
    bottom: 100px;
    transition-duration: 0.6s;
  }

  &-4 {
    right: 0;
    top: 20px;
  }
}
