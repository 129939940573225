.blue,
.violet,
.red {
  transition: color $default-transition-settings;

  html.dark & {
    color: $color-torch-red;
  }
}

.blue {
  color: $color-neon-blue;
}

.violet {
  color: $color-vivid-violet;
}

.red {
  color: $color-torch-red;
}
