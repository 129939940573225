.short-intro {
  display: flex;
  padding-top: size(174px);
  padding-bottom: size(104px);

  @include viewport--md {
    padding-top: 102px;
    padding-bottom: 100px;
  }

  @include viewport--sm {
    position: relative;
    padding-bottom: 54px;
  }

  &__left-col {
    width: 57%;
    margin-right: size(128px);

    @include viewport--ml {
      width: 65%;
      margin-right: 55px;
    }

    @include viewport--md {
      width: 100%;
      margin-right: 0;
      margin-left: 42px;
      margin-right: 60px;
    }

    @include viewport--sm {
      padding-top: 215px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__dino {
    display: none;

    @include viewport--md {
      display: block;
      margin-top: -70px;
    }

    @include viewport--sm {
      margin-top: 0;
      position: absolute;
      top: 0;

      &.ligozavr {
        top: 135px;
        left: 115px;
      }
    }

    .ligozavr__light {
      display: none;

      @include viewport--sm {
        display: block;
      }
    }


  }

  &__right-col {
    width: size(352px);

    @include viewport--ml {
      margin-right: 60px;
    }

    @include viewport--md {
      display: none;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__text {
    @include text30;
    line-height: 150%;
    margin: 0 0 size(16px);
  }

  &__btn-wrap {
    margin-top: size(48px);

    @include viewport--sm {
      margin-top: 32px;

      display: flex;
      flex-direction: column;
    }
  }

  &__button {
    margin-right: size(16px);

    @include viewport--sm {
      margin-right: 0;
      margin-bottom: 16px;
      margin-top: 0;
    }

    &--black {
      background-color: transparent;
      border-color: $color-black;
      color: $color-black;

      &:hover,
      &:focus-visible {
        html.dark & {
          color: $color-torch-red;
        }
      }

      html.dark & {
        border-color: $color-white;
        color: $color-white;
      }
    }
  }
}
