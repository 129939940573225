.activities {
  @include section-paddings;

  .section-title {
    max-width: size(860px);
  }

  &--hr {
    @include lightning-bg-blue;
  }
}

.activities__block {
  &:not(:last-of-type) {
    margin-bottom: size(61px);
    padding-bottom: size(67px);
    border-bottom: size(2px) solid $color-whisper;

    @include viewport--md {
      margin-bottom: 42px;
      padding-bottom: 50px;
      border-width: 2px;
    }
  }
}

.activities--hr .activities__block:not(:last-of-type) {
  border-color: $color-white--16a;
}

.activities__title {
  margin-top: 0;
  margin-bottom: size(52px);
  @include text20;

  @include viewport--md {
    margin-bottom: 40px;
  }

  @include viewport--sm {
    margin-bottom: 32px;
  }
}

.activities__list {
  margin: 0 size(-16px) size(-32px);
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @include viewport--md {
    margin: 0 -12px -24px;
  }

  @media (max-width: 499px) {
    flex-direction: column;
    margin: 0 0 -24px;
  }
}

.activities__item {
  margin-bottom: size(32px);
  display: flex;

  padding: 0 size(16px);
  flex-grow: 0;
  flex-shrink: 0;

  width: 33.33333%;

  @include viewport--md {
    padding: 0 12px;
    margin-bottom: 24px;
  }

  @include viewport--sm {
    width: 50%;
    padding: 0 8px;
  }

  @media (max-width: 499px) {
    width: 100%;
    padding: 0;
  }
}

.activities--hr .activity-article__img {
  border: size(2px) solid $color-white;
}
