.page-404 {
  position: relative;
  padding-bottom: size(290px);

  @include viewport--lg {
    padding-bottom: 20%;
  }

  .page-title {
    width: 72%;
    padding-top: 0;

    @include viewport--md {
      width: 85%;
    }

    @include viewport--sm {
      width: auto;
    }
  }
}

.page-404__container {
  padding-top: size(146px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include viewport--md {
    padding-top: 124px;
    flex-direction: column;
  }

  @include viewport--sm {
    padding-top: 46px;
  }
}

.page-404__slash {
  @include text20;
  margin-bottom: size(15px);
  width: 100%;
  flex-shrink: 0;
  color: $color-torch-red;
}

.page-404__main {
  width: 28%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: size(12px);

  @include viewport--md {
    margin-top: 42px;
    padding-bottom: 0;
    width: auto;
    justify-content: normal;
  }
}

.page-404__bg {
  z-index: -1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: size(215px);

  width: 928 / 1440 * 100%;
  max-width: size(928px);

  svg {
    margin-top: auto;
    width: size(928px);
    max-width: 100%;
    height: size(336px);
  }

  @include viewport--static {
    right: calc((100% - 1120px) / 2);

    svg {
      height: auto;
    }
  }

  @media (max-width: 1168px) {
    right: 24px;
  }

  @include viewport--md {
    svg {
      width: 928px;
      //height: 336px;
    }
  }

  @include viewport--sm {
    right: 16px;

    svg {
      //width: 203px;
      //height: 75px;
    }
  }
}
