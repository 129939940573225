.text-page {
  @include section-pb;
}

.text-page__content {
  margin-top: 74px;
  width: size(736px);
  margin-left: auto;

  @include viewport--md {
    max-width: 736px;
    margin-top: 64px;
  }

  @include viewport--sm {
    margin-top: 52px;
    width: 100%;
    margin-left: 0;
  }
}
