.reviews {
  @include section-paddings;

  &--hr {
    padding-top: 0;
  }

  &--no-padding-top {
    padding-top: 0;

    @include viewport--md {
      padding-top: 54px;
    }
  }

  &--results {
    .section-description {
      max-width: 100%;
      margin: 0 auto size(100px);

      @include viewport--static {
        max-width: 1168px;
        padding: 0 24px;
        margin-bottom: 120px;
      }

      @include viewport--md {
        margin-bottom: 100px;
      }

      @include viewport--sm {
        margin-bottom: 80px;
      }
    }
  }

  .section-title {
    padding-right: 100px;

    @include viewport--md {
      padding-right: 24px;
    }

    @include viewport--sm {
      padding-right: 16px;
    }
  }

  .slider__controls {
    top: size(-67px);

    @include viewport--md {
      top: 0;
      left: 24px;
    }

    @include viewport--sm {
      left: 16px;
    }
  }

  &--hr .slider__controls {
    top: size(-8px);

      @include viewport--md {
        top: 0;
        left: 24px;
      }

      @include viewport--sm {
        top: 10px;
        left: 16px;
      }
  }
}
