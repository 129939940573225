.expanding-block {
  @include lightning-bg;
  border: 2px solid $color-black;
  border-bottom: none;

  html.dark & {
    border-color: $color-charcoal;
  }

  &__top {
    padding: size(64px);

    @include viewport--md {
      padding: 32px;
    }

    @include viewport--sm {
      padding: 15px;
    }
  }

  &__bottom {
    max-height: 0;
    transition: max-height $default-transition-settings;
    overflow: hidden;
    padding: 0 size(64px);

    @include viewport--md {
      padding: 0 32px;
    }

    @include viewport--sm {
      padding: 0 15px;
    }

    .is-open & {
      padding-bottom: size(64px);

      @include viewport--md {
        padding-bottom: 32px;
      }

      @include viewport--sm {
        padding-bottom: 15px;
      }
    }
  }

  &__top-text {
    margin: 0;

    @include text30;
  }

  &__bottom-text {
    max-width: size(864px);
    margin: 0 0 size(48px);

    @include text16-24;

    opacity: 0.92;

    @include viewport--sm {
      margin-bottom: 32px;
    }
  }

  &__formula {
    @include text30;
    margin: 0 0 size(8px);

    font-family: $font-family--roboto-mono;

    color: $color-neon-blue;
    color: var(--btn-bg-color);
  }

  &__note {
    display: flex;
    justify-content: space-between;

    @include viewport--sm {
      flex-wrap: wrap;
    }
  }

  &__note-text {
    @include text16-24;

    opacity: 0.92;

    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      max-width: size(529px);
      margin-right: size(175px);

      @include viewport--sm {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &:last-child {
      opacity: 1;
      max-width: size(288px);

      span {
        color: $color-neon-blue;
        color: var(--btn-bg-color);
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 100%;
    border: none;
    border-top: 2px solid $color-black;
    border-bottom: 2px solid $color-black;

    color: $color-default-white;

    background-color: $color-neon-blue;
    background-color: var(--btn-bg-color);

    padding: size(11px);

    cursor: pointer;

    transition: background-color $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        border-color: $color-black;

        svg {
          html.dark & {
            color: $color-black;
          }
        }
      }
    }

    html.dark & {
      border-color: $color-charcoal;
    }

    svg {
      width: size(24px);
      height: size(24px);

      margin-right: size(8px);

      transform: rotate(180deg);
      transition: transform $default-transition-settings;

      .is-open & {
        transform: rotate(360deg);
      }
    }
  }
}
