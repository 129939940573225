.sofia {
  position: fixed;
  bottom: size(24px);
  left: size(24px);
  z-index: 1100;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  display: flex;
  flex-direction: row;
  transition: opacity $default-transition-settings-06, visibility $default-transition-settings-06;

  @media (hover: hover) {
    &:hover {
      .sofia__text-block.active-text {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }
    }
  }

  @media (pointer: coarse) {
    &:hover {
      .sofia__text-block.active-text {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }
    }
  }

  @include viewport--sm {
    width: 234px;

    left: 16px;
    bottom: 16px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &.is-hidden {
    opacity: 0;
    visibility: hidden;

    .sofia__text {
      pointer-events: none;
    }
  }

  &.show.is-hidden {
    pointer-events: none;
  }

  &__img-wrapper {
    min-height: size(136px);
    min-width: size(110px);

    @include viewport--sm {
      min-width: 74px;
      min-height: 91px;
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: opacity $default-transition-settings;

    height: size(136px);
    width: size(110px);

    @include viewport--sm {
      width: 74px;
      height: 91px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    &.active {
      opacity: 1;
    }
  }

  &__text-block {
    position: absolute;
    bottom: 106px;
    min-width: size(208px);
    padding: size(16px);
    border-radius: size(16px) size(16px) size(16px) size(4px);
    box-shadow: 0px 16px 56px rgba(0, 0, 0, 0.16);
    color: $color-black;
    color: var(--text-color);
    background-color: $color-default-white;

    opacity: 0;
    visibility: hidden;
    transform: translateY(size(100px));
    transition: opacity $default-transition-settings, transform $default-transition-settings-06, background-color $default-transition-settings, visibility $default-transition-settings;

    html.dark & {
      background-color: $color-zambezi;
    }

    @include viewport--sm {
      bottom: 71px;
      padding: 8px;
      border-radius: 12px 12px 12px 4px;
    }

    &--black {
      color: $color-default-white;

      background: linear-gradient(109.65deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.16) 100%);
      // background-color: rgba($color-charcoal, 0.8);
      background-color: rgba(#707070, 1);
      backdrop-filter: blur(200px);
    }

    &--purple {
      color: $color-default-white;
      background-color: $color-deep-lilac;
    }

    &.active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  &__text {
    margin: 0;
    font-size: size(14px);
    line-height: size(18px);

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }

    a {
      color: var(--text-content-contrast-color);
      transition: color $default-transition-settings;

      @media (hover: hover) {
        &:hover {
          color: var(--sofia-link-hover);
        }
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    right: size(10px);
    color: var(--text-color);
    z-index: 10;

    @include viewport--sm {
      right: auto;
      left: 60px;
    }

    &::before {
      content: "";
      position: absolute;
      inset: -10px;

      @include viewport--sm {
        inset: -8px;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--dino {
    .sofia__text-block {
      filter: drop-shadow(0px 0px 5px rgba($color-torch-red, 0.5));
    }

    .sofia__img {
      height: size(106px);
      width: size(110px);

      @include viewport--sm {
        width: 74px;
        height: 72px;
      }

      img {
        filter: drop-shadow(0px 0px 5px rgba($color-torch-red, 0.5));
      }
    }
  }
}
