.form-success-message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 13;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;

  opacity: 0;
  visibility: hidden;
  transition: opacity $default-transition-settings, visibility $default-transition-settings;
}

.form-success-message__text {
  margin: auto;

  p {
    @include text30;
    margin: 0;
    text-align: center;

    & + p {
      margin-top: 1rem;
    }
  }
}

.form-success-message__button {
  position: relative;

  width: 200px;
  height: 60px;
  margin: 0 auto 2rem;

  font-size: 0;

  background-color: $color-lime-green;
  border: 0;
  cursor: pointer;

  transition: background-color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      background-color: $color-shamrock;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    width: 60px;
    height: 60px;

    //background-image: url("../img/svg/check-mark.svg");
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIHZpZXdCb3g9IjIwIDIwIDcwIDcwIiAgaGVpZ2h0PSI1MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBzdHJva2U9IndoaXRlIiBkPSJtMzEuNSw0Ni41bDE1LjMsLTIzLjIiLz4KICA8cGF0aCBzdHJva2U9IndoaXRlIiBkPSJtMzEuNSw0Ni41bC04LjUsLTcuMSIvPgogPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-position: 15px 11px;
    background-size: 150%;
    transform: translate(-50%, -50%);
  }
}
