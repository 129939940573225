.postcard-generator {
  padding: 0 0 size(125px) 0;

  &--result {
    padding-top: size(40px);

    @include viewport--md {
      padding-top: 40px;
    }

    @include viewport--sm {
      padding-top: 10px;
    }
  }

  .section-title {
    text-align: center;
    margin: 0 0 size(70px) 0;

    @include viewport--sm {
      text-align: left;
      margin: 0 0 32px 0;
    }

    &::before {
      display: none;
    }
  }

  &__persons-controls {
    margin-bottom: size(140px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: size(15px);

    @include viewport--md {
      margin-bottom: 120px;
      justify-content: flex-start;
      gap: 10px;
    }
  }

  &__pattern-controls {
    width: 100%;
    padding: 0 size(94px);
    margin: 0 0 size(60px) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: size(50px);

    @include viewport--md {
      padding: 0;
      margin: 0 0 25px 0;
      gap: 30px;
    }
  }

  &__pattern {
    position: relative;
    width: size(212px);
    height: size(212px);
    background-color: var(--white-bg-color);
    box-shadow: 0 0 0 size(4px) var(--grey-border-color-2);
    border: none;
    border-radius: calc(60 / 352 * 100%);
    overflow: hidden;
    cursor: pointer;
    object-position: center center;
    transition: box-shadow $default-transition-settings;
    transform: translateZ(0);
    aspect-ratio: 1 / 1;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        box-shadow: 0 0 0 size(15px) var(--btn-bg-color-25);
      }
    }

    @include viewport--sm {
      width: 50%;
      height: unset;
    }

    &.is-active {
      box-shadow: 0 0 0 size(15px) var(--btn-bg-color);
      pointer-events: none;

      @include viewport--md {
        box-shadow: 0 0 0 6px var(--btn-bg-color);
      }
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 110%;
      height: 110%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &__generate {
    display: flex;
    margin: 0 auto size(120px) auto;

    @include viewport--md {
      margin: 0 auto 60px auto;
    }

    @include viewport--sm {
      width: 100%;
    }
  }

  &__result-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: size(520px);
    scroll-margin-top: size(138px);

    @include viewport--md {
      width: 100%;
      scroll-margin-top: 138px;
    }

    @include viewport--sm {
      scroll-margin-top: 92px;
    }

    .animate-wrap.show & {
      scroll-margin-top: size(88px);

      @include viewport--md {
        scroll-margin-top: 88px;
      }

      @include viewport--sm {
        scroll-margin-top: 62px;
      }
    }
  }

  &__result {
    position: relative;
    width: size(410px);
    height: size(410px);
    margin: 0 0 size(30px) 0;
    border-radius: calc(65 / 520 * 100%);
    background-color: $color-charcoal;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    transform: translateZ(0);

    @include viewport--md {
      max-width: 410px;
      max-height: 410px;
      width: 100%;
      height: auto;
      margin: 0 0 25px 0;
    }

    &.is-loading {
      .postcard-generator__loader {
        opacity: 1;
      }

      .postcard-generator__postcard::before {
        background-color: rgba($color-charcoal, 0.6);
      }
    }
  }

  &__postcard {
    position: absolute;
    inset: 0;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba($color-charcoal, 0);
      transition: background-color $default-transition-settings;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--new {
      z-index: 1;

      &::before {
        content: none;
      }
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: size(82px);
    height: size(82px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--btn-bg-color);
    border-radius: size(20px);
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
    transition: opacity $default-transition-settings;
    z-index: 100;

    svg {
      width: size(48px);
      height: size(48px);
      animation: spinner 2s linear infinite;
    }
  }

  &__buttons {
    margin: 0 0 size(90px) 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: size(15px);

    @include viewport--md {
      margin: 0 0 50px 0;
      max-width: 800px;
      width: 100%;
      gap: 16px;
    }

    @include viewport--sm {
      max-width: none;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
    }
  }

  &__result-text {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .postcard-generator__text {
      text-align: center;
    }

    &.is-active {
      display: flex;
    }
  }

  &__emoji {
    margin: size(15px) 0 0 0;
    width: size(65px);
    height: size(65px);

    @include viewport--md {
      margin: 10px 0 0 0;
      width: 65px;
      height: 65px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      user-select: none;
    }
  }

  &__text {
    margin: 0;
    max-width: size(400px);
    font-weight: 500;
    font-size: size(30px);
    line-height: size(40px);

    @include viewport--md {
      font-size: 22px;
      line-height: 30px;
    }

    @include viewport--sm {
      max-width: 303px;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
