.text-content {
  letter-spacing: 0.02em;

  & > *:not(.text-content__table) {
    max-width: size(560px);

    @include viewport--sm {
      max-width: none;
    }
  }

  &.blog-text-page__content {
    & > *{
      max-width: 100%;
    }
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  a:not(.btn) {
    transition:
      color $default-transition-settings,
      opacity $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-torch-red;

        html.dark & {
          color: $color-white;
          opacity: 0.5;
        }
      }
    }
  }

  blockquote {
    position: relative;
    font-weight: 500;
    padding-left: size(18px);
    margin: 4.25em 0;

    &::before {
      content: "";
      position: absolute;
      top: -0.2em;
      bottom: -0.35em;
      left: 0;
      width: size(2px);
      background-color: $color-neon-blue;
      background-color: var(--text-content-contrast-color);
      transition: background-color $default-transition-settings;
    }
  }

  h2 {
    @include text30;
    margin-top: 64 / 30 * 1em;
    margin-bottom: 0.6em;

    & + p {
      margin-top: 1.1em;
    }
  }

  h3 {
    @include text20;
    margin-top: 1.2em;
    margin-bottom: 0.4em;

    & + p {
      margin-top: 0.5em;
    }
  }

  p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  p,
  ul,
  ol {
    color: var(--text-color-72a);
    transition: color $default-transition-settings;
  }

  ul,
  ol {
    padding-left: 0;
    list-style: none;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ol {
    counter-reset: ol-counter;

    li {
      position: relative;
      padding-left: 1.9375em;

      &::before {
        position: absolute;
        left: 0;
        counter-increment: ol-counter;
        content: counter(ol-counter) ".";
        color: $color-neon-blue;
        color: var(--text-content-contrast-color);
        font-weight: bold;

        @include viewport--sm {
          top: 2px;
        }
      }
    }

    ol {
      counter-reset: ol-inner1;

      li {
        padding-left: 3em;

        &::before {
          counter-increment: ol-inner1;
          content: counter(ol-counter) "." counter(ol-inner1) ".";
        }
      }
    }
  }

  li {
    margin-bottom: 0.5em;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: left;

    @include viewport--md {
      width: auto;
    }
  }

  td,
  th {
    border: size(2px) solid $color-black;
    transition: border-color $default-transition-settings;
    vertical-align: top;
  }

  th {
    padding: 0.45em 1em 0.65em;
    font-weight: 500;
  }

  td {
    &:not(:first-child) {
      color: var(--text-color-72a);
      font-size: size(14px);
      line-height: size(20px);

      padding: 1.8em 1.2em;

      @include viewport--sm {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &:first-child {
      font-weight: 500;
      padding: 1.4em 1em;
    }
  }

  html.dark & {
    td,
    th {
      border-color: $color-white;
    }
  }

  & > .link {
    margin-top: 3.45em;
  }

  u {
    text-decoration: none;
    border-bottom: size(1px) solid $color-black;
    border-bottom: size(1px) solid var(--text-color);
    transition: border-bottom-color $default-transition-settings;

    @include viewport--md {
      border-bottom-width: 1px;
    }
  }
}


.text-content ul,
.ul {
  color: var(--text-color-72a);
  padding-left: 0;
  list-style: none;
  transition: color $default-transition-settings;

  &:last-child {
    margin-bottom: 0;
  }

  li {
    padding-left: size(12px);
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: size(12px);
      left: 0;
      width: size(4px);
      height: size(4px);
      background-color: $color-neon-blue;
      background-color: var(--text-content-contrast-color);
      border-radius: 50%;
      opacity: 1;
    }
  }

  @include viewport--md {
    li {
      &::before {
        top: 9px;
        width: 4px;
        height: 4px;
      }
    }
  }

  @include viewport--sm {
    margin-bottom: 1.6em;

    li {
      margin-bottom: 0.58em;
    }
  }
}

.text-content__table {
  margin: 3.75em 0;

  @include viewport--md {
    margin-right: -24px;
    overflow: auto;
  }

  @include viewport--md {
    margin-right: -16px;
  }
}

.text-content__table-inner {
  @include viewport--md {
    display: flex;
    overflow: auto;

    table {
      flex-grow: 1;
    }
  }
}

.text-content__table-padding {
  display: none;

  @include viewport--md {
    display: block;
    flex-shrink: 0;
    width: 24px;
  }

  @include viewport--sm {
    width: 16px;
  }
}

.text-content__right {
  text-align: right;
}
