.tools {
  @include lightning-bg-blue;
  @include section-paddings;
}

.tools__title {
  display: flex;
  flex-direction: column;
  max-width: 49%;
  margin-right: size(50px);
  position: relative;
  width: 100%;

  @media (max-width: 1199px) {
    margin-right: 50px;
    max-width: 100%;
    flex-grow: 1;
  }

  @include viewport--md {
    margin-right: 0;
    max-width: 100%;
    margin-bottom: 48px;
  }

  @include viewport--sm {
    margin-bottom: 32px;
  }
}

.tools__intro {
  display: flex;
  justify-content: space-between;
  margin-bottom: size(32px);

  @include viewport--md {
    display: block;
  }
}

.tools__description {
  @include text20;
  margin: 0 0 size(16px);

  @include viewport--md {
    margin: 16px 0 0;
  }
}

.tools__intro .section-title {
  max-width: 99%;
  margin-bottom: 0;
  padding-bottom: size(32px);

  @media (max-width: 1199px) {
    max-width: 100%;
  }

  @include viewport--md {
    padding-bottom: 16px;
  }

  span {
    display: block;
  }
}

.tools__blocks {
  max-width: 40%;
  width: 40%;

  @media (min-width: $viewport--lg) {
    padding-top: size(20px);
  }

  @media (max-width: 1199px) {
    width: 448px;
    max-width: 448px;
    flex-shrink: 0;
  }

  @include viewport--md {
    max-width: 100%;
    width: 100%;
  }
}

.tools__block {
  margin-bottom: size(16px);

  &:last-child {
    margin-bottom: 0;
  }
}

.tools__block-title {
  @include text20;
  margin: 0 0 size(16px);
}

.tools__list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: size(-8px);
  margin-right: size(-16px);

  @include viewport--md {
    margin-right: -24px;
    margin-left: -24px;
    padding: 0 24px;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include viewport--sm {
    margin-right: -16px;
    margin-left: -16px;
    padding: 0 16px;
  }
}

.tools__item {
  margin-bottom: size(8px);
  margin-right: size(16px);
  padding-bottom: size(32px);
  position: relative;
  width: size(48px);
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    width: auto;
  }

  @include viewport--md {
    padding-right: 24px;
    margin-right: 0;
    margin-bottom: 0;
    padding-bottom: 24px;
  }

  @include viewport--sm {
    padding-right: 16px;
  }
}

.tools__item-ic {
  width: size(48px);
  height: size(48px);
  border: size(2px) solid $color-white--16a;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transition: border-color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    .tools__item:hover &,
    .tools__item:focus & {
      border-color: $color-white;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: size(36px);
    height: size(36px);
    color: $color-neon-blue;
    transition: color $default-transition-settings;

    html.dark & {
      color: $color-eclipse-light;
    }
  }

  @include viewport--md {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-width: 2px;
  }
}

.tools__item-description {
  position: absolute;
  top: size(56px);
  opacity: 0;
  pointer-events: none;
  transition: opacity $default-transition-settings;
  text-align: center;
  width: size(160px);
  left: 50%;
  transform: translateX(-50%);
  font-size: size(12px);
  line-height: size(16px);
  margin: 0;

  @media (hover: hover), screen and (min-width: 0\0) {
    .tools__item:hover &,
    .tools__item:focus & {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media (max-width: 1024px) {
    position: static;
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
    margin-top: 8px;
    width: 100%;
    max-width: 100px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;

    span {
      display: none;
    }
  }

  @include viewport--sm {
    max-width: 130px;
  }
}

.tools__text-wrapper {
  margin: size(16px) 0 size(48px);
}

.tools__text {
  margin: 0;
  font-size: size(16px);
  line-height: size(24px);
  opacity: 0.72;
}

.tools__footer {
  border-top: size(2px) solid $color-white--16a;
  padding-top: size(64px);
  padding-left: size(96px);
  position: relative;
  transition: border-color $default-transition-settings;

  @include viewport--md {
    padding-left: 75px;
    border-width: 2px;
  }

  @include viewport--sm {
    padding-top: 45px;
    padding-left: 0;
  }
}

.tools__footer svg {
  position: absolute;
  top: size(77px);
  left: 0;
  width: size(22px);
  height: size(12px);

  @include viewport--sm {
    display: none;
  }
}

.tools__footer-text {
  @include text30;
  margin: 0;
  max-width: 90%;

  @include viewport--sm {
    max-width: 100%;
  }
}

#paint0_linear-36-pagespeed {
  transition: stop-color $default-transition-settings;

  --color-stop: #{$color-neon-blue};
  --color-bot: #{$color-neon-blue};

  html.dark & {
    --color-stop: #{$color-eclipse-light};
    --color-bot: #{$color-eclipse-light};
  }
}
