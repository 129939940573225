.socials {
  margin-top: 0;
  margin-right: size(-48px);
  margin-bottom: size(-8px);
  max-width: size(320px);

  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @include viewport--md {
    margin-right: -48px;
    margin-bottom: -8px;
    max-width: 320px;
  }

  @media (max-width: 374px) {
    max-width: 300px;
  }
}

.socials__item {
  min-width: size(110px);
  margin-bottom: size(8px);
  margin-right: size(48px);

  @include viewport--md {
    min-width: 111px;
    margin-right: 48px;
    margin-bottom: 8px;
  }

  @media (max-width: 374px) {
    min-width: 90px;
  }
}

.socials__link {
  @include text20;
  transition: opacity $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      opacity: 0.5;
    }
  }
}

.socials--dark {
  &.socials {
    max-width: size(326px);
    margin-right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: size(60px);
    row-gap: size(6px);

    @include viewport--sm {
      column-gap: 15px;
      row-gap: 0;
    }
  }

  .socials__item {
    margin: 0;
  }

  .socials__link {
    color: $color-black;
    letter-spacing: 0;
    transition: opacity $default-transition-settings, color $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        opacity: 0.7;
        color: $color-razzmatazz;
      }
    }
  }
}

// тёмная тема
html.dark {
  .socials--dark {
    .socials__link {
      color: $color-default-white;
    }
  }
}
