.banner {
  position: fixed;
  z-index: 1000;
  top: size(94px);
  right: 5%;

  opacity: 0;
  transform: translateY(-200%);

  transition: transform $default-transition-settings-05, opacity $default-transition-settings, background-color $default-transition-settings, border-color $default-transition-settings;

  &.is-active {
    opacity: 1;
    transform: translateX(0);
  }

  @include viewport--ml {
    right: 0;
    left: 0;
    top: 78px;
  }

  @include viewport--sm {
    top: 52px;
  }

  &__inner {
    position: relative;

    max-width: size(300px);

    background-color: $color-snow;

    box-shadow: 0px size(4px) size(24px) size(5px) rgba(0, 0, 0, 0.1);
    border-radius: size(4px);
    border: size(2px) solid $color-neon-blue;

    transition: transform $default-transition-settings-05, opacity $default-transition-settings, background-color $default-transition-settings, border-color $default-transition-settings;

    @include viewport--ml {
      max-width: 100%;
      width: 100%;

      background-color: $color-neon-blue;

      border-radius: 0;
      border: none;
    }

    .is-active & {
      animation: 5s jump linear infinite;
    }


    html.dark & {
      background-color: $color-nero;
      border: size(2px) solid $color-torch-red;

      @include viewport--ml {
        border: none;
        background-color: $color-torch-red;
      }
    }

    &::before {
      content: "";
      position: absolute;

      width: size(15px);
      height: size(15px);
      transform: rotate(45deg);
      border-top: size(2px) solid $color-neon-blue;
      border-left: size(2px) solid $color-neon-blue;
      background-color: $color-snow;

      z-index: 10000;
      top: -#{size(9px)};
      right: size(53px);

      transition: background-color $default-transition-settings, border-color $default-transition-settings;

      html.dark & {
        background-color: $color-nero;
        border-top: size(2px) solid $color-torch-red;
        border-left: size(2px) solid $color-torch-red;
      }

      @include viewport--ml {
        display: none;
      }
    }

  }

  &__text {
    display: none;

    @include viewport--ml {
      display: block;
      width: 100%;
      height: 100%;

      font-size: 14px;
      padding: 10px 30px 10px 24px;
      margin: 0;
      color: $color-white;
    }

    @include viewport--sm {
      font-size: 12px;
      padding: 10px 30px 10px 16px;
    }
  }

  &__link {
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;

    font-size: size(14px);
    padding: size(20px);

    color: $color-black;

    html.dark & {
      color: $color-white;
    }

    @include viewport--ml {
      display: none;
    }
  }

  &__text-link {
    margin-left: size(5px);
    border-bottom: none;

    @include viewport--ml {
      color: $color-white;
    }
  }

  &__close-btn {
    position: absolute;
    right: size(14px);
    top: size(12px);

    transition: color $default-transition-settings;

    html.dark & {
        color: $color-white;
      }

    svg {
      width: size(12px);
      height: size(12px);


      @include viewport--ml {
        width: 10px;
        height: 10px;
      }
    }

    @include viewport--ml {
      color: $color-white;
      top: 12px;
      right: 12px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-neon-blue;

        html.dark & {
          color: $color-torch-red;
        }

        @include viewport--ml {
          color: $color-white;
        }
      }
    }
  }
}
