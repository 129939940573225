.rate {
  width: 100%;
  //--rate-contrast-color: #{$color-neon-blue};
  display: flex;
  margin-bottom: size(40px);
  padding: size(59px) size(96px) size(64px) size(62px);
  //border: size(2px) solid var(--rate-contrast-color);
  border: size(2px) solid $color-neon-blue;

  transition: border-color $default-transition-settings-06;

  &:last-child {
    margin-bottom: 0;
  }

  @include viewport--md {
    padding: 36px;
  }

  @include viewport--sm {
    margin-bottom: 30px;
    flex-direction: column;
    padding: 20px 20px 24px;
  }

  html.dark & {
    border-color: $color-torch-red;
    .rate__title,
    .rate__img svg,
    .rate__price {
      color: $color-torch-red;
    }

    .rate__feature::before {
      background-color: $color-torch-red;
    }
  }
}

//.rate__title,
//.rate__img svg,
//.rate__price {
//  color: var(--rate-contrast-color);
//}


.rate--big {
  //--rate-contrast-color: #{$color-vivid-violet};

  border-color: $color-vivid-violet;
  .rate__title,
  .rate__img svg,
  .rate__price {
    color: $color-vivid-violet;
  }

  .rate__feature::before {
    background-color: $color-vivid-violet;
  }

  .rate__img {
    svg {
      width: size(114px);
      height: size(126px);
    }


    @include viewport--sm {
      width: 57px;
      height: 63px;
    }
  }
}

.rate--front {
  //--rate-contrast-color: #{$color-torch-red};

  border-color: $color-torch-red;

  .rate__title,
  .rate__img svg,
  .rate__price {
    color: $color-torch-red;
  }

  .rate__feature::before {
    background-color: $color-torch-red;
  }

  .rate__img {
    svg {
      width: size(160px);
      height: size(166px);
    }

    @include viewport--sm {
      width: 80px;
      height: 83px;
    }
  }
}



.rate__left {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 43.4%;
  padding-bottom: 5px;

  @include viewport--md {
    width: 40%;
  }

  @include viewport--sm {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
  }

  @media (max-width: 449px) {
    flex-direction: column;
  }
}

.rate__right {
  width: 56.6%;
  letter-spacing: 0.02em;

  @include viewport--md {
    padding-top: 7px;
  }

  @include viewport--sm {
    width: 100%;
    margin-top: 18px;
    padding-top: 0;
  }

  @media (max-width: 449px) {
    margin-top: 30px;
  }
}

.rate__title {
  @include text30;
  margin-top: 0;
  margin-bottom: size(36px);
  color: $color-neon-blue;
  transition: color $default-transition-settings-06;

  @include viewport--md {
    font-size: 26px;
  }

  @include viewport--sm {
    margin-bottom: 0;
  }

  @media (max-width: 449px) {
    margin-bottom: 18px;
  }
}

.rate__img {
  margin-top: auto;
  display: flex;

  svg {
    width: size(97px);
    height: size(82px);
    max-width: 100%;
    max-height: 100%;
    color: $color-neon-blue;
  }

  @include viewport--sm {
    width: 48px;
    height: 41px;
  }
}

.rate__features {
  margin-top: size(1px);
  max-width: 93%;
  list-style: none;
  padding-left: 0;
  letter-spacing: 0.02em;

  @include viewport--md {
    max-width: none;
  }
}

.rate__feature {
  @include text-opaque;
  position: relative;
  display: flex;

  margin-bottom: size(24px);

  &::before {
    content: "";
    flex-shrink: 0;
    margin-top: size(10px);
    margin-right: size(8px);
    width: size(4px);
    height: size(4px);
    border-radius: 50%;
    background-color: $color-neon-blue;
    //background-color: var(--rate-contrast-color);
    transition: background-color $default-transition-settings-06;
  }

  @include viewport--md {
    &::before {
      width: 4px;
      height: 4px;
    }
  }

  @include viewport--sm {
    margin-bottom: 18px;
  }
}

.rate__options {
  margin: size(32px) size(-16px) size(-32px);
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  letter-spacing: 0.02em;

  @include viewport--md {
    margin: 32 -12px -24px;
  }

  @include viewport--sm {
    flex-direction: column;
    margin: 0;
  }

  &--sale {
    @include viewport--sm {
      flex-direction: row;
    }
  }
}

.rate__option {
  margin-bottom: size(32px);
  padding: 0 size(16px);
  width: 50%;

  @include viewport--md {
    padding: 0 12px;
    margin-bottom: 24px;
  }

  @include viewport--sm {
    width: 100%;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 499px) {
    width: 100%;
    padding: 0;
  }

  .rate__options--sale & {
    width: auto;

    @include viewport--sm {
      font-size: 16px;
      width: auto;
      margin-right: 10px;

      .rate__price {
        position: relative;
        font-size: 16px;
      }
    }

    @media (max-width: 374px) {
      margin-bottom: 10px;

    }
  }
}

.rate__price {
  @include text20;
  margin-top: 0;
  margin-bottom: 0;
  color: $color-neon-blue;
  transition: color $default-transition-settings-06;

  &--old {
    text-decoration: line-through;
  }
}

.rate__description {
  margin-top: size(8px);
  margin-bottom: 0;
  @include text-opaque;
}

.rate__hot {
  margin-left: size(25px);
  padding: size(8px) size(12px);
  font-size: size(14px);
  line-height: size(14px);
  color: $color-default-white;
  background-color: $color-torch-red;
  border-radius: 100px;

  @include viewport--md {
    padding: 8px 12px;
    font-size: 14px;
  }
}

html.dark {
  .rate {
    --rate-contrast-color: #{$color-torch-red};
    border-color: $color-white;
    color: $color-white;

    svg {
      color: $color-white;
    }
  }
}
