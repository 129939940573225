.coloured-section {
  color: $color-white;

  a {
    color: $color-white;
    --link-color: #{$color-white};
  }
}

html.dark {
  .coloured-section {
    background-color: $color-eclipse;
  }
}
