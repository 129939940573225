.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 size(215px);

  @include viewport--static {
    max-width: 1168px;
    padding: 0 24px;
  }

  @include viewport--sm {
    padding: 0 16px;
  }

  &--no-padding {
    @include viewport--sm {
      padding: 0;
    }
  }

  &--small-padding {
    padding: size(35px) size(215px);

    @include viewport--static {
      max-width: 1168px;
      padding: 24px;
    }

    @include viewport--sm {
      padding: 16px;
    }
  }
}
