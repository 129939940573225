.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));
  overflow-x: hidden;

  &--visible {
    overflow: visible;
  }

  &--mobile-visible {
    @include viewport--sm {
      overflow: visible;
    }
  }

  &--main {
    padding-top: 0;
  }

  &--no-scrollbar {
    height: 100vh;
    height: calc(100 * var(--vh, 1vh));

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header-wrap {
    position: relative;
    z-index: 11;
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
    background-color: var(--bg-color);
    position: relative;
    z-index: 10;
    transition: background-color $default-transition-settings-06;
  }
}

.scroll-lock {
  position: fixed;

  overflow-y: auto;
}

.btn-reset {
  padding: 0;

  background: $color-transparent;
  border: none;
  cursor: pointer;
}

@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.no-pointer {
  pointer-events: none;
}

.no-transition {
  transition: none !important;
}

.color-neon-blue {
  color: $color-neon-blue;
}

.yt-api {
  position: relative;

  &::before {
    content: "Загружаю...";
    position: absolute;
    inset: 0;
    font-size: 32px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
