.input-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;

  &--hidden {
    display: none;
  }

  &--required {
    &::after {
      content: "*";
      position: absolute;
      top: 0;
      right: 0;
      color: $color-torch-red;
      color: var(--error-color);
    }
  }

  textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  input,
  textarea {
    width: 100%;
    padding: 0 size(28px) size(10px) 0;
    color: transparent;
    line-height: 1.625;
    letter-spacing: 0.02em;
    border: none;
    border-radius: 0;
    background-color: $color-transparent;
    border-bottom: size(2px) solid $color-white;
    transition: color $default-transition-settings, border-color $default-transition-settings;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $color-white;
      -webkit-box-shadow: inset 0 0 0 1000px transparent;
      transition: background-color 1000000s ease 0s;
    }

    &:hover,
    &:focus {
      outline: none;
    }

    &:focus {
      color: $color-white;
      background-color: $color-transparent;

      & + label {
        top: size(-22px);
        font-size: size(12px);
        //line-height: size(18px);

        @include viewport--md {
          top: -20px;
          font-size: 12px;
          //line-height: 18px;
        }

        @include viewport--sm {
          top: -15px;
          font-size: 10px;
          //line-height: 16px;
        }
      }
    }

    @include viewport--md {
      border-width: 2px;
    }

    @include viewport--sm {
      padding-bottom: 7px;
    }
  }

  label {
    position: absolute;
    top: 1px;
    left: 0;
    transition: top $standard-transition-settings, font-size $standard-transition-settings, line-height $standard-transition-settings;
    pointer-events: none;
    letter-spacing: 0.02em;
    transform: translateZ(0);

    @include viewport--sm {
      top: 3px;
    }
  }

  &--has-value {
    input,
    textarea {
      color: $color-white;

      & + label {
        top: size(-22px);
        font-size: size(12px);

        @include viewport--md {
          top: -20px;
          font-size: 12px;
        }

        @include viewport--sm {
          top: -15px;
          font-size: 10px;
        }
      }
    }
  }

  &--valid {
    svg {
      opacity: 1;
    }
  }

  &--error {
    input,
    textarea {
      border-bottom-color: $color-torch-red;
      border-bottom-color: var(--error-color);

      &:hover,
      &:focus {
        border-bottom-color: $color-torch-red;
        border-bottom-color: var(--error-color);
      }
    }
  }

  &--error-shown {
    p {
      visibility: visible;
      opacity: 1;
    }
  }

  &--radio {
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    position: relative;
    padding-left: size(32px);

    @include viewport--sm {
      padding-left: 24px;
    }

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      clip: rect(0 0 0 0);

      &:checked {
        & + .input-wrapper__bullet {
          background-color: $color-neon-blue;
          background-color: var(--radio-active-color);
          border-color: $color-neon-blue;
          border-color: var(--radio-active-color);
        }
      }

      &:disabled {
        & + .input-wrapper__bullet {
          opacity: 0.6;
          border-color: $color-black;
          border-color: var(--radio-base-color);
        }
      }

      &:focus {
        & + .input-wrapper__bullet {
          outline: solid $color-neon-blue--06a 2px;
          outline: solid var(--outline-color) 2px;
        }
      }

      &:checked:disabled {
        & + .input-wrapper__bullet {
          border-color: $color-black;
          border-color: var(--radio-base-color);
          background-color: $color-black;
          background-color: var(--radio-base-color);
        }
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        .input-wrapper__bullet {
          border-color: $color-neon-blue;
          border-color: var(--radio-active-color);
        }
      }
    }
  }
}

.input-wrapper__text {
  opacity: 0.72;
}

.input-wrapper__error-message {
  @include text12-14;
  margin-top: size(5px);
  margin-bottom: 0;
  width: 100%;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  color: $color-torch-red;
  color: var(--error-color);
  cursor: text;
  transition: max-height $standard-transition-settings, opacity $standard-transition-settings;

  &--shown {
    opacity: 1;
    visibility: visible;
  }

  @include viewport--sm {
    margin-top: 2px;
  }
}

.input-wrapper__bullet {
  top: size(4px);
  left: 0;
  position: absolute;
  width: size(16px);
  height: size(16px);
  border-radius: size(8px);
  border: size(2px) solid $color-black;
  border: size(2px) solid var(--radio-base-color);
  box-shadow: inset 0 0 0 size(3px) $color-snow;
  box-shadow: inset 0 0 0 size(3px) var(--bg-color);

  transition: background-color $default-transition-settings-06, border-color $default-transition-settings-06, box-shadow $default-transition-settings-06;

  @include viewport--md {
    width: 16px;
    height: 16px;
    border-width: 2px;
    box-shadow: inset 0 0 0 3px $color-snow;
    box-shadow: inset 0 0 0 3px var(--bg-color);
  }

  @include viewport--sm {
    top: 3px;
  }
}
