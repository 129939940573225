.slider-controls {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-controls__btn {
  @include reset-btn;
  width: auto;
  height: auto;
  position: static;
  padding: size(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-neon-blue;

  transition: color $default-transition-settings, transform $default-transition-settings;

  &::after {
    display: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      color: $color-torch-red;
    }
  }

  svg {
    width: size(26px);
    height: size(14px);
    transition: transform $default-transition-settings;
  }

  html.dark & {
    color: $color-torch-red;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-white;
      }
    }
  }

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: auto;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-neon-blue;

        svg {
          transform: none;
        }
      }
    }

    html.dark & {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus-visible {
          color: $color-torch-red;
        }
      }
    }
  }

  &.swiper-button-lock {
    display: none;
  }
}

.slider-controls__btn--prev {
  margin-right: size(12px);

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      svg {
        transform: translateX(-5px);
      }
    }
  }
}

.slider-controls__btn--next {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
}
