.hny2024-link {
  display: flex;

  &__svg-text {
    width: size(139px);
    height: size(62px);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__letter {
    position: relative;
    width: size(75px);
    height: size(46px);
    transform: translate(6px, 6px) rotate(-9deg);
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__letter-snow {
    position: absolute;
    inset: 0;
    mix-blend-mode: screen;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
