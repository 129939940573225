.section-title {
  margin-top: 0;
  margin-bottom: size(74px);
  font-weight: 400;
  font-size: size(80px);
  line-height: size(72px);
  color: $color-neon-blue;
  color: var(--title-color-light-bg);
  font-family: $font-family--neue-machina;

  transition: color $default-transition-settings;

  &::before {
    content: "/";
  }

  &--content-in {
    &::before {
      display: none;
    }

    > * {
      &::before {
        content: "/";
      }
    }
  }

  &--pink {
    color: $color-torch-red;
  }

  @media (max-width: 1279px) {
    font-size: 72px;
  }

  @include viewport--md {
    margin-bottom: 48px;
    font-size: 56px;
    line-height: 1.25;
  }

  @include viewport--sm {
    margin-bottom: 32px;
    font-size: 32px;
  }

  .coloured-section & {
    color: $color-white;
    color: var(--title-color-coloured-bg);
  }

  &--hr {
    margin-bottom: size(16px);
    font-size: size(16px);
    line-height: size(24px);

    @include viewport--md {
      font-size: 16px;
      line-height: 24px;
    }

    @include viewport--sm {
      font-size: 14px;
      line-height: 22px;

      margin-bottom: 8px;
    }
  }
}

.section-title--slider {
  padding-right: size(114px);
}
