.formats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 size(80px);
  max-width: size(1440px);
  margin: 0 auto;
  margin-top: size(250px);
  min-height: size(600px);
  margin-bottom: size(250px);

  @include viewport--md {
    padding: 0 16px;
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .section-title {
    text-align: center;
    margin-bottom: size(20px);

    @include viewport--md {
      margin-bottom: 20px;
    }
  }

  &__btns {
    display: flex;
    width: fit-content;
    gap: size(10px);
    padding: size(10px);
    border-radius: size(30px);

    background-color: var(--white-accent);

    @include viewport--md {
      padding: 4px;
      gap: 4px;
    }
  }

  &__btn {
    font-family: inherit;
    background-color: transparent;

    display: inline-flex;
    padding: size(20px) size(42px) size(22px) size(42px);
    font-size: size(24px);
    font-weight: 500;
    line-height: size(28px);
    letter-spacing: 0.02em;
    border: none;
    border-radius: size(20px);
    transition: background-color $default-transition-settings, color $default-transition-settings;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    &.is-active {
      color: var(--link-color);
      background-color: rgba($color-neon-blue, 0.07);
    }

    @include hover-focus {
      color: var(--link-color);
      background-color: rgba($color-neon-blue, 0.07);
    }

    @include viewport--md {
      font-size: 18px;
      line-height: 22px;

      padding: 18px 9px 20px 9px;
    }
  }

  &__tabs {
    width: 100%;
    margin-top: size(20px);
    max-width: size(1120px);

    @include viewport--md {
      margin-top: 10px;
    }
  }

  &__tab {
    background-color: var(--white-accent);
    border-radius: size(60px);
    padding: size(30px) size(40px) size(40px) size(40px);

    @include viewport--md {
      padding: 15px 20px 20px 20px;
      border-radius: 30px;
    }

    h3 {
      font-size: size(42px);
      font-weight: 500;
      line-height: size(44px);
      margin: 0;

      @include viewport--md {
        font-size: 22px;
        line-height: 24px;
        letter-spacing: 0.02em;
      }
    }

    p {
      font-size: size(24px);
      font-weight: 500;
      line-height: size(28px);
      letter-spacing: 0.02em;

      margin: 0;
      margin-top: size(20px);

      @include viewport--md {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  &__send {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: size(20px) size(40px) size(22px) size(40px);
    border-radius: size(20px);
    background-color: var(--btn-bg-color);
    margin-top: size(50px);
    color: $color-default-white;

    font-size: size(24px);
    font-weight: 500;
    line-height: size(28px);
    letter-spacing: 0.02em;

    transition: color $default-transition-settings, background-color $default-transition-settings;

    @include hover-focus {
      color: var(--btn-text-color-hover);
      background-color: var(--btn-bg-color-hover);
    }

    @include viewport--md {
      margin-top: 10px;
      width: 100%;
      font-size: 22px;
      line-height: 24px;
      border-radius: 20px;
    }
  }
}

html.dark {
  .formats__btn {
    &.is-active {
      color: var(--link-color);
      background-color: rgba($color-torch-red, 0.07);
    }

    @include hover-focus {
      color: var(--link-color);
      background-color: rgba($color-torch-red, 0.07);
    }
  }
}
