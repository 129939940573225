.sticky-carousel {
  position: relative;
  padding: 0 size(80px);
  max-width: calc(#{size(1440px)});
  margin: 0 auto;
  width: 100%;
  height: calc(400 * var(--vh, 1vh));

  @include viewport--static {
    padding: 0 80px;
  }

  @include viewport--md {
    height: auto;
    padding: 0 16px;

    .page-title {
      margin-bottom: 21px;
    }
  }

  &.pt-100 {
    padding-top: size(100px);

    .sticky-carousel__items,
    .sticky-carousel__title-wrapper {
      top: 0;
    }

    @include viewport--md {
      padding-top: 64px;
    }
  }

  &__wrapper {
    position: sticky;
    top: 35vh; // header height
    left: 0;
    height: 100vh;

    &--grid {
      top: size(78px);

      .sticky-carousel__items,
      .sticky-carousel__title-wrapper {
        top: calc(#{size(20px)} + #{size(177px)});
      }
    }

    @include viewport--md {
      position: static;
      height: auto;
    }
  }

  &__title-wrapper {
    position: absolute;
    left: 50%;

    display: flex;
    min-width: size(620px);
    max-width: size(620px);
    width: 100%;
    align-items: center;
    background-color: var(--white-accent);
    padding: size(7px) size(12px) size(7px) size(40px);
    border-radius: size(60px);
    height: fit-content;

    visibility: hidden;

    @include viewport--lg {
      min-width: unset;
      max-width: 400px;
      border-radius: 30px;
    }

    @include viewport--md {
      visibility: visible;
      position: static;
      height: auto;
      justify-content: space-between;
      max-width: 100%;
      padding: 15px 8px 20px 20px;
    }
  }

  &__content {
    h2 {
      margin: 0;

      font-size: size(42px);
      font-weight: 500;
      line-height: size(44px);

      @include viewport--lg {
        font-size: 22px;
        line-height: 24px;
        letter-spacing: 0.02em;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: size(24px);
      font-weight: 500;
      line-height: size(28px);
      letter-spacing: 0.02em;

      @include viewport--lg {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    width: size(216px);
    height: size(220px);

    @include viewport--static {
      width: 108px;
      height: 110px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__items {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: size(20px);
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: size(620px);
    visibility: hidden;

    @include viewport--lg {
      max-width: calc(100% - 400px - 40px);
    }

    @include viewport--md {
      visibility: visible;
      position: static;
      height: auto;
      max-width: 100%;
      margin-top: 10px;
      gap: 10px;
    }
  }

  &__item {
    padding: size(30px) size(40px) size(40px) size(40px);
    background-color: var(--white-accent);
    border-radius: size(30px);


    @include viewport--md {
      padding: 15px 20px 20px 20px;
    }

    p {
      font-size: size(20px);
      font-weight: 400;
      line-height: size(24px);
      letter-spacing: 0.02em;
      margin: 0;

      @include viewport--md {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .gradient-text {
    position: absolute;
    left: 0;
    bottom: 30vh;

    @include viewport--md {
      position: static;
    }
  }
}
