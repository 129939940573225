.configurator-parameter {
  margin-bottom: size(38px);
  letter-spacing: 0.02em;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include viewport--sm {
    margin-bottom: 26px;
  }
}

.configurator-parameter__title {
  @include text20;
  margin-top: 0;
  margin-bottom: size(25px);

  @include viewport--sm {
    margin-bottom: 17px;
    font-size: 18px;
  }
}

.configurator-parameter__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.configurator-parameter__option {
  margin-bottom: size(16px);
}
