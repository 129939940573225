.portfolio {
  min-height: 100vh;
  padding-bottom: size(120px);

  @include viewport--md {
    padding-bottom: 0;
  }

  @include viewport--sm {
    padding-top: 18px;
  }

  &--results {
    min-height: auto;
    padding-bottom: size(120px);

    overflow: hidden;

    @include viewport--md {
      padding-bottom: 0;
    }

    .portfolio__project {
      display: block;
    }
  }

  &--blog {
    max-width: size(1000px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: auto;
    padding-bottom: size(60px);

    @include viewport--md {
      padding-bottom: 0;
    }

    .portfolio__project {
      display: block;
    }
  }

  &--price {
    .portfolio__wrapper .container:first-child {
      @include viewport--sm {
        padding-right: 16px;
      }
    }
  }


  &__filters {
    list-style: none;
    margin: 0;
    margin-top: size(40px);
    padding: 0;
    display: flex;
    gap: size(8px);
    font-size: size(14px);
    line-height: size(14px);
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: none;
    }

    @include viewport--md {
      flex-wrap: nowrap;
      gap: 8px;
      font-size: 14px;
      line-height: 14px;
      margin-top: 32px;
      overflow-y: auto;
    }

    @include viewport--sm {
      margin-top: 28px;
      padding-left: 16px;
    }
  }

  &__filter {
    position: relative;
    flex-shrink: 0;

    &-quantity {
      position: absolute;
      right: size(6px);
      top: size(6px);
      font-size: size(10px);
      line-height: size(8px);
      color: $color-torch-red;
      transition: color $default-transition-settings-04;

      @include viewport--md {
        right: 6px;
        top: 6px;
        font-size: 10px;
        line-height: 8px;
      }

      @include viewport--sm {
        right: 4px;
        top: 4px;
        font-size: 8px;
      }
    }
  }

  &__filter:last-child {
    @include viewport--sm {
      position: relative;
    }
  }

  &__filter:last-child::before {
    @include viewport--sm {
      position: absolute;
      content: "";
      top: 0;
      right: -16px;
      width: 16px;
      height: 100%;
      background-color: $color-transparent;
    }
  }

  &__filter-title {
    border: 0;
    padding: size(16px) size(24px);
    background-color: $color-white-smoke-3;
    cursor: pointer;
    transition: background-color $default-transition-settings-04, color $default-transition-settings-04;

    color: $color-black;

    &:hover,
    &:focus-visible {
      background-color: $color-torch-red;
      color: $color-default-white;
      transition: background-color $default-transition-settings-04, color $default-transition-settings-04;

      .portfolio__filter-quantity {
        color: $color-default-white;
        transition: color $default-transition-settings-04;
      }
    }

    &.is-active {
      background-color:  $color-neon-blue;
      color: $color-default-white;

      .portfolio__filter-quantity {
        color: $color-default-white;
      }
    }

    @include viewport--md {
      padding: 16px 24px;
    }

    @include viewport--sm {
      padding: 12px 16px;
    }
  }

  &__projects {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: size(27px);
    transition: transform $default-transition-settings-04, opacity $default-transition-settings-04;

    &.transform {
      opacity: 0;
      transform: translateY(-20px);
    }

    @include viewport--md {
      margin-top: 56px;
    }

    @include viewport--sm {
      margin-top: 36px;
    }
  }

  &__project {
    display: none;
    padding-right: size(20px);

    @include viewport--md {
      padding-right: 0;
      margin-bottom: 32px;
    }

    &:last-child {
      .project {
        border-bottom: 0;
      }
    }

    &.is-active {
      display: block;
    }
  }

  &__btn {
    margin: size(42px) 0 size(25px);
    padding: size(20px) size(40px);

    @include viewport--md {
      margin-top: 0;
      padding: 20px 40px;
    }

    @include viewport--sm {
      margin-bottom: 54px;
    }

    html.dark & {
      color: $color-white;
    }
  }
}
