.header {
  height: size(78px);
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;

  position: fixed;
  z-index: 10;
  transition: background-color $default-transition-settings;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-transparent;
    height: size(2px);
    transition: background-color $default-transition-settings;
  }

  .container {
    max-width: size(1440px);
    padding: 0 size(24px);

    @include viewport--ml {
      height: 100%;
      padding: 0;
    }
  }

  @include viewport--ml {
    display: block;
    padding-top: 0;
    transition: none;

    &::after {
      display: none;
    }
  }

  @include viewport--sm {
    height: 52px;
  }

  &--scrolled {
    background-color: $color-white;
    background-color: var(--bg-color);
    transition: background-color $default-transition-settings;

    &::after {
      background-color: $color-quartz;
      background-color: var(--header-fixed-border);
    }
  }

  &--blog {
    &::after {
      display: none;

      @include viewport--ml {
        display: none;
      }
    }

    .container::after {
      display: none;
    }
  }

  &--opened {
    height: auto;
    bottom: 0;

    @include viewport--ml {
      background-color: $color-white;
      background: var(--bg-color);
    }

    .header__menu {

      @include viewport--sm {
        flex-direction: column;
      }
    }
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include viewport--ml {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    justify-content: flex-start;
    height: 100%;
  }
}

.header__logo-wrapper {
  margin-right: size(30px);
  display: flex;
  vertical-align: middle;

  @include viewport--ml {
    display: block;
    width: 100%;
    position: relative;
    transition: background-color $default-transition-settings;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color-transparent;
      height: 2px;
      transition: background-color $default-transition-settings;
    }

    .header--scrolled & {
      background: var(--bg-color);

      &::after {
        background-color: $color-quartz;
        background-color: var(--header-fixed-border);
      }
    }

    .header--blog & {
      &::after {
        @include viewport--ml {
          display: none;
        }
      }
    }

    .header--opened & {
      &::after {
        display: none;
      }
    }
  }
}

.header__logo {
  display: inline-block;
  vertical-align: middle;

  @include viewport--ml {
    position: relative;
    z-index: 3;
    padding-left: 24px;
    padding-top: 27px;
    height: 78px;
  }

  @include viewport--sm {
    padding-left: 16px;
    padding-top: 17px;
    height: 52px;
  }
}

.header .logo {
  svg {
    width: size(110px);
    height: size(24px);

    @include viewport--sm {
      width: 81px;
      height: auto;
    }
  }

  .letter {
    @include viewport--sm {
      visibility: hidden;
    }
  }
}

.header__navigation {
  display: flex;
  align-items: center;
  position: relative;

  @include viewport--ml {
    position: static;
    flex-grow: 1;
    width: 100%;
    align-items: stretch;
  }
}

.header__menu-toggle {
  display: none;

  @include viewport--ml {
    width: 32px;
    height: 32px;
    outline: none;

    display: flex;
    align-items: center;
    position: absolute;
    z-index: 3;
    right: 24px;
    top: 24px;

    @include viewport--sm {
      right: 16px;
      top: 10px;
    }
  }
}

//transitions mixin
@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

//position absolute mixin
@mixin position-absolute ($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

.header__menu-toggle-in {
  height: 14px;
  cursor: pointer;
  width: 32px;
  position: relative;

  &::before,
  &::after {
    @include transition-mix;
    content: "";
    display: block;
    width: 2px;
    height: 24px;
    border-radius: 10px;
    transform-origin: center center;
    background-color: $color-neon-blue;
    background-color: var(--btn-bg-color);
  }

  &::before {
    opacity: 0;
    @include position-absolute($top: -5px, $left: 15px);
    transform: rotate(-90deg);
  }

  &::after {
    opacity: 0;
    @include position-absolute($top: -5px, $right: 15px);
    transform: rotate(90deg);
  }


  .header__menu-toggle[aria-pressed="true"] & {
    &::before,
    &::after {
      opacity: 1;
      @include transition-mix($delay: 0.2s);
    }

    &::before {
      transform: rotate(-45deg);
      top: -7px;
    }

    &::after {
      transform: rotate(45deg);
      top: -7px;
    }
  }
}

.header__menu-toggle-ic {
  @include transition-mix($delay: 0.2s);
  width: 100%;
  height: 2px;
  background-color: $color-neon-blue;
  background-color: var(--btn-bg-color);
  border-radius: 20px;

  &:nth-child(1) {
    @include position-absolute($top: 0, $left: 0);
  }

  &:nth-child(2) {
    @include position-absolute($top: 6px, $left: 0);
  }

  &:nth-child(3) {
    @include position-absolute($top: 12px, $left: 0);
  }

  .header__menu-toggle[aria-pressed="true"] & {
    @include transition-mix;
    opacity: 0;

    &:nth-child(1) {
      top: 6px;
    }

    &:nth-child(3) {
      top: 6px;
    }
  }
}

.header__menu {
  display: flex;
  align-items: center;

  @include viewport--ml {
    display: none;
    background-color: $color-white;
    background: var(--bg-color);
    position: fixed;
    top: 78px;
    bottom: -1px;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;

    .header--opened & {
      display: block;
      transform: translateX(0);
    }

    .header--menu-shift & {
      transform: translateX(100%);
    }
  }

  @include viewport--sm {
    top: 52px;
  }
}

.header__list {
  transform: translateZ(0);

  margin: 0 size(395px) 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1199px) {
    margin: 0 150px 0 0;
  }

  @include viewport--ml {
    margin: 0;
    width: 100%;
    padding: 50px 0;

    display: block;
  }

  @include viewport--sm {
    padding: 40px 0;
  }

  @media (max-width: 360px) {
    padding: 20px 0;
  }
}

.header__menu-item {
  margin-right: size(24px);

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1199px) {
    margin-right: 15px;
  }

  @include viewport--ml {
    margin-right: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include viewport--ml {
    margin-bottom: 0;
  }

  .hny2024-link {
    display: none;

    @include viewport--sm {
      margin-top: 35px;
      display: flex;
      justify-content: center;
    }
  }
}

.header__menu-link {
  color: $color-black;
  color: var(--text-color);
  font-weight: 500;
  font-size: size(14px);
  line-height: size(14px);
  transition: color $default-transition-settings;
  display: inline-block;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      color: $color-neon-blue;
      color: var(--btn-bg-color);
    }
  }

  &--active {
    pointer-events: none;
    color: $color-neon-blue;
    color: var(--btn-bg-color);
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  @include viewport--ml {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    padding: 18px 24px;
  }

  @include viewport--sm {
    padding: 16px;
    font-size: 24px;
    line-height: 24px;
  }
}

.header__menu-link-in {
  display: inline-block;

  .header__menu-link--external & {
    position: relative;
    padding-right: size(8px);

    svg {
      position: absolute;
      top: size(-2px);
      right: size(-3px);
      width: size(7px);
      height: size(7px);
      color: $color-neon-blue;
      color: var(--btn-bg-color);
      transition: color $default-transition-settings;
    }
  }

  &--testim {
    span {
      background: linear-gradient(to left, #e72e8b 0%, #1f3e92 40%, #1f3e92 60%, #e72e8b 80%);
      background-size: 200% auto;

      color: #000;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      animation: shine 1s linear infinite;
    }

    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        span {
          animation-direction: reverse;
        }
      }
    }
  }
}

.btn.header__menu-button {
  max-width: size(180px);

  @include viewport--ml {
    font-size: 18px;
    padding: 15px 30px;
    max-width: 350px;
    margin-bottom: 50px;
  }

  @include viewport--sm {
    font-size: 14px;
    line-height: 14px;
    padding: 14px;
    width: calc(100% - 32px);
    margin: 0 16px 40px;
  }
}

.header__switcher.theme-switcher {
  position: absolute;
  right: size(218px);
  top: size(8px);

  @media (max-width: 1199px) {
    right: 200px;
  }

  @include viewport--ml {
    z-index: 3;
    right: 90px;
    top: 24px;
  }

  @include viewport--sm {
    right: 72px;
    top: 16px;
  }
}

.hny2024-link.header__hny2024 {
  position: absolute;
  right: size(305px);
  top: size(-8px);
  z-index: 10;

  @include viewport--ml {
    z-index: 3;
    right: 177px;
    top: 5px;
  }

  @include viewport--sm {
    display: none;
  }
}
