.page-contacts {
  padding-bottom: size(120px);

  @include lightning-element-light;
  background-position: 100% 84.4%;
  background-repeat: no-repeat;
  transition: background-image $default-transition-settings;

  @include viewport--static {
    padding-bottom: 79px;
    background-position: 100% 86.8%;
  }

  @include viewport--lg {
    padding-bottom: 120px;
    background-position: 100% 84.7%;
  }

  @include viewport--sm {
    background-position: 100% 97%;
    padding-bottom: 64px;
  }

  .page-title {
    margin-bottom: size(70px);

    @include viewport--lg {
      margin-bottom: 42px;
    }

    @include viewport--md {
      margin-bottom: 22px;
    }
  }

  &__wrapper {
    display: flex;
    border-bottom: size(1px) solid $color-black--16a;

    @include viewport--md {
      flex-direction: column;
      grid-template-columns: 1fr;
    }
  }

  &__feedback {
    width: 50%;
    padding-right: size(130px);
    padding-bottom: size(36px);
    border-right: size(1px) solid $color-black--16a;

    @media (max-width: 1279px) {
      padding-right: 56px;
    }

    @include viewport--lg {
      padding-right: 50px;
      padding-bottom: 41px;
    }

    @include viewport--md {
      width: 100%;
      border-right: 0;
      padding-bottom: 52px;
      padding-right: 34px;
      border-width: 1px;
    }

    @include viewport--sm {
      padding-right: 0;
      border-right: none;
      padding-bottom: 42px;
    }
  }

  &__title {
    @include text30;
    max-width: size(430px);
    margin-top: 0;
    margin-bottom: size(27px);

    @include viewport--lg {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 21px;
    }

    @include viewport--sm {
      margin-bottom: 13px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: size(6px);
    opacity: 0.72;

    @include viewport--static {
      margin-bottom: 8px;
    }

    @include viewport--lg {
      margin-bottom: 6px;
    }

    @include viewport--md {
      letter-spacing: -0.1px;
      margin-bottom: -1px;
    }
  }

  &__subtitle-contacts {
    margin-top: 0;
    margin-bottom: size(6px);
    opacity: 0.72;

    @include viewport--static {
      margin-bottom: 6px;
    }

    @include viewport--lg {
      margin-bottom: 7px;
    }

    @include viewport--md {
      letter-spacing: -0.1px;
      margin-bottom: -1px;
    }
  }

  &__subtitle-socials {
    margin-top: 0;
    margin-bottom: size(3px);
    opacity: 0.72;
  }

  &__form {
    margin-top: size(15px);
  }

  &__contacts {
    padding-bottom: size(64px);
    padding-left: size(130px);
    width: 50%;

    @media (max-width: 1279px) {
      padding-left: 56px;
    }

    @include viewport--lg {
      padding-left: 50px;
    }

    @include viewport--md {
      width: 100%;
      padding-left: 0;
    }

    @include viewport--sm {
      padding-bottom: 38px;
    }
  }

  &__contact {
    margin-bottom: size(24px);

    @include viewport--md {
      margin-bottom: 19px;
    }
  }

  &__contact-text {
    @include text20;
    margin: 0;
    letter-spacing: 0;

    .link {
      white-space: nowrap;
    }

    @include viewport--md {
      margin-top: 4px;
    }
  }

  &__contact-text--call {
    @include viewport--sm {
      letter-spacing: -0.01em;
    }
  }

  &__manager {
    margin-top: size(-10px);
    margin-bottom: size(15px);
    display: flex;
    align-items: center;

    @include viewport--lg {
      flex-direction: column;
      align-items: flex-start;
    }

    @include viewport--md {
      margin-top: -10px;
      margin-bottom: 40px;
      display: block;
    }

    @include viewport--sm {
      margin-bottom: 21px;
    }
  }

  &__manager-img {
    position: relative;
    margin-right: size(29px);
    padding: size(8px) size(8px) size(11px) size(12px);
    flex-shrink: 0;
    width: size(116px);
    height: size(116px);

    &::before {
      position: absolute;
      bottom: size(8px);
      left: size(8px);
      content: "";
      width: size(18px);
      height: size(18px);
      background-color: $color-lime-green;
      border: size(2px) solid $color-neon-blue;
      border-radius: 50%;
      z-index: 2;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: $color-neon-blue;
      border-radius: 50%;
      z-index: -1;
    }

    .swiper-slide {
      border-radius: 50%;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include viewport--lg {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 32px;
    }

    @include viewport--md {
      border-width: 2px;
      margin-left: 0;
    }

    @include viewport--sm {
      width: 116px;
      height: 116px;
      margin-bottom: 20px;
    }
  }

  &__manager-text {
    margin-top: size(-4px);
    max-width: size(380px);
    opacity: 0.72;

    @include viewport--lg {
      max-width: 256px;
    }

    @include viewport--md {
      max-width: 330px;
      margin-top: 0;
    }
  }

  &__socials {
    padding-top: size(42px);
    border-top: size(1px) solid $color-black--16a;

    @include viewport--md {
      padding-top: 39px;
      border-top-width: 1px;
    }

    @include viewport--sm {
      padding-top: 33px;
    }
  }

  &__socials-list {
    margin-top: 0;
    margin-bottom: size(25px);

    @include viewport--sm {
      margin-bottom: 14px;
    }
  }

  &__blog-link {
    margin: 0;
    opacity: 0.72;
  }

  &__blog-link .link {
    color: $color-black;
    border-color: currentColor;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        opacity: 0.7;
        color: $color-razzmatazz;
      }
    }
  }

  &__management {
    margin-top: size(50px);
    padding-top: size(38px);
    border-top: size(1px) solid $color-black--16a;

    @include viewport--static {
      padding-top: 29px;
    }

    @include viewport--lg {
      padding-top: 30px;
    }

    @include viewport--sm {
      margin-top: 38px;
      padding-top: 37px;
    }
  }

  &__management-text {
    margin: 0;
    opacity: 0.72;
  }

  &__management-text .link {
    color: $color-black;
    border-color: currentColor;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        opacity: 0.7;
        color: $color-razzmatazz;
      }
    }
  }

  .b24-form-field {
    margin-bottom: size(13px);

    @include viewport--md {
      margin-bottom: 8px;
    }
  }

  .b24-form-control-group {
    padding-bottom: size(11px);
  }

  .b24-form-control-text {
    margin-bottom: size(15px);

    @include viewport--md {
      margin-bottom: 15px;
    }

    @include viewport--sm {
      margin-bottom: 10px;
    }
  }

  .b24-form-control-filelist {
    margin-top: size(3px);
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container {
    display: flex;
    flex-direction: column;
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container textarea {
    // stylelint-disable-next-line
    height: size(39px) !important;
    // stylelint-disable-next-line
    padding-top: size(10px) !important;
    margin-top: size(18px);
    padding-bottom: size(8px);
    // overflow: visible;

    @include viewport--md {
      // stylelint-disable-next-line
      height: 38px !important;
      margin-top: 18px;
      padding-bottom: 8px;
    }
  }

  .b24-form-header-padding {
    padding: size(9px);
  }

  .b24-form form > div,
  .form--main .input-wrapper {
    width: 100%;
  }

  .b24-form-control-string .b24-form-control,
  .b24-form-control-list .b24-form-control,
  .b24-form-control-text .b24-form-control,
  .b24-form-control-select .field-item {
    border-color: $color-black;
    color: $color-black;
    resize: none;
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control-label {
    font-size: size(16px);
    line-height: size(17px);
    font-family: $font-family--neue-machina;
    color: $color-black;
    top: 50%;
    transform: translateY(-50%);
    display: inline;
    -webkit-line-clamp: 1;
    overflow: hidden;
    // stylelint-disable-next-line
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @include viewport--md {
      font-size: 16px;
      line-height: 17px;
    }

    @media (max-width: 320px) {
      padding: 0;
      font-size: 15px;
    }
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:focus-visible + .b24-form-control-label,
  .b24-form-control-not-empty + .b24-form-control-label {
    // stylelint-disable-next-line
    top: 7px !important;
  }

  .b24-form-control-file {
    border-color: $color-black;
    padding: size(9px) size(10px);

    @include viewport--md {
      padding: 9px 10px;
    }
  }


  .b24-form-control-file .b24-form-control-label {
    color: $color-black;
    font-family: $font-family--neue-machina;
    letter-spacing: 0;
    opacity: 0.72;
    font-size: size(13px);
    line-height: size(17px);

    @include viewport--md {
      font-size: 13px;
      line-height: 17px;
    }
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:-webkit-autofill,
  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:-webkit-autofill:focus,
  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:-webkit-autofill:hover {
    // stylelint-disable-next-line
    -webkit-text-fill-color: $color-black !important;
  }

  .b24-form-control-comment {
    color: $color-black;
    // stylelint-disable-next-line
    font-family: $font-family--neue-machina !important;
    letter-spacing: 0;
    font-size: size(13px);
    line-height: size(17px);

    @include viewport--md {
      font-size: 13px;
      line-height: 17px;
    }
  }

  .b24-form-control-file .b24-form-control {
    border-color: $color-black;
    color: $color-black;
    font-size: size(14px);
    letter-spacing: size(-0.3px);
    margin: size(2px) size(9px) 0 0;
    // stylelint-disable-next-line
    padding: size(8px) size(10px) size(8px) size(38px) !important;
    white-space: wrap;

    @include viewport--md {
      font-size: 14px;
      margin: 4px 9px 0 0;
      // stylelint-disable-next-line
      padding: size(8px) size(16px) size(8px) size(29px) !important;
    }
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control {
    font-size: size(17px);

    @include viewport--md {
      font-size: 17px;
    }
  }

  .b24-form-control-file .b24-form-control::before,
  .b24-form-control-file .b24-form-control::after {
    background-color: $color-black;
  }

  .b24-form-control-file-item-remove::before,
  .b24-form-control-file-item-remove::after {
    background-color: $color-black;
  }

  .b24-form-icon-remove::before,
  .b24-form-icon-remove::after {
    background-color: $color-black;
    opacity: 1;
  }

  .b24-form-icon-remove {
    top: unset;
    bottom: 0;
    height: size(43px);
    opacity: 1;

    @include viewport--md {
      height: 43px;
    }

    @include viewport--sm {
      height: 50px;
    }
  }

  .b24-form-control-list .b24-form-control-container::after {
    top: size(27px);
    right: size(8px);
    width: size(7px);
    height: size(7px);
    border-width: size(2px);
    border-color: $color-black;
    // cursor: pointer;

    @include viewport--md {
      width: 7px;
      height: 7px;
      border-width: 2px;
    }
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field-agreement div .b24-form-control-container .b24-form-control-desc .b24-form-field-agreement-link {
    color: $color-black;
    font-size: size(13px);
    font-family: $font-family--neue-machina;

    @include viewport--md {
      font-size: 13px;
    }
  }

  .b24-form-field-agreement .b24-form-control-required {
    font-size: size(13px);
  }

  .b24-form .b24-form-sign-abuse-link {
    color: $color-black;
    font-size: size(13px);
    font-weight: 300;
    text-decoration: underline;
    text-underline-offset: size(3px);
    text-decoration-skip-ink: none;
    font-family: $font-family--neue-machina;

    @include viewport--md {
      font-size: 13px;
    }
  }

  .b24-form-btn-block {
    padding: 0;
  }

  .b24-form-field-agreement .b24-form-control-container {
    margin-bottom: 0;
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field-agreement div .b24-form-control-container input {
    width: size(15px);
    height: size(15px);
    transform: translateY(2px);
    // cursor: pointer;

    @include viewport--md {
      width: 15px;
      height: 15px;
    }
  }

  .b24-form-control-agreement {
    margin-bottom: size(15px);
  }

  .b24-form-btn-container {
    margin: 0;
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form .b24-form-btn-container .b24-form-btn-block button {
    font-size: size(14px);
    font-weight: 700;
    font-family: $font-family--neue-machina;
    letter-spacing: size(-0.2px);

    @include viewport--md {
      font-size: 14px;
    }
  }

  .b24-form .b24-form-sign-abuse-help {
    margin-left: size(3px);
  }

  .b24-form-control-container.b24-form-control-icon-after::after {
    transition: transform $default-transition-settings;
    transform: rotate(45deg);
    transform-origin: center;
    margin-top: size(-3px);
    margin-right: size(3px);
    opacity: 1;

    @include viewport--md {
      margin-top: -3px;
      margin-right: 3px;
    }
  }

  .b24-form-control-container.b24-form-control-icon-after.is-rotate::after {
    transform: rotate(225deg);
  }

  .b24-form-control-file-item-name-text {
    color: $color-black;
    opacity: 0.72;
  }

  .b24-form-control-file .b24-form-control::after {
    width: size(11px);
    height: size(1px);
    margin-top: size(-0.5px);
    left: size(11px);

    @include viewport--md {
      width: 11px;
      height: 1px;
      margin-top: -0.5px;
      left: 11px;
    }
  }

  .b24-form-control-file .b24-form-control::before {
    height: size(11px);
    width: size(1px);
    left: size(15.5px);
    margin-top: size(-5.5px);

    @include viewport--md {
      height: 11px;
      width: 1px;
      left: 15.5px;
      margin-top: -5.5px;
    }
  }

  .b24-form-field-agreement .b24-form-control-desc {
    opacity: 0.72;
  }

  .b24-form-control-filelist .b24-form-control-file-item {
    border-color: $color-black--16a;
  }
}

// тёмная тема
html.dark {
  .page-contacts {
    @include lightning-element-dark;
  }

  .page-contacts__socials {
    border-color: $color-white--16a;
  }

  .page-contacts__management {
    border-color: $color-white--16a;
  }

  .page-contacts__feedback {
    border-color: $color-white--16a;
  }

  .page-contacts__wrapper {
    border-color: $color-white--16a;
  }

  .page-contacts .b24-form-control-filelist .b24-form-control-file-item {
    border-color: $color-white--16a;
  }
}
