.expertise-slider__list {
  counter-reset: expertise-how-counter;
  margin: 0;

  .slider__slide {
    @include viewport--sm {
      padding-right: 16px;
    }
  }
}

.expertise-slider__step {
  width: size(384px);

  &::before {
    display: block;
    @include text20;
    counter-increment: expertise-how-counter;
    content: ">"counter(expertise-how-counter, decimal-leading-zero);
    color: $color-neon-blue;
    color: var(--text-content-contrast-color);
  }

  @include viewport--sm {
    width: 300px;
  }
}

.expertise-slider__step-title {
  @include text20;
  margin-top: 0;
  margin-bottom: size(9px);
}

.expertise-slider__step-text {
  margin: 0;
  letter-spacing: 0.02em;
  opacity: 0.85;
}
