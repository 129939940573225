.students {
  @include lightning-bg-violet;

  &--results {
    background-color: $color-snow;
    background-image: none;

    html.dark & {
      background-color: $color-nero;
      background-image: none;
    }

    .link {
      color: $color-black;
    }
  }

  &--hr {
    overflow: hidden;
  }
}

.students__container {
  @include section-paddings;
  display: flex;
  justify-content: space-between;

  @include viewport--md {
    flex-direction: column;
  }
}

.students__main {
  width: 544 / 1120 * 100%;

  @include viewport--md {
    margin-bottom: 36px;
    width: 90%;
  }

  @include viewport--sm {
    width: 100%;
  }
}

.students__details {
  width: 448 / 1120 * 100%;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include viewport--md {
    width: 80%;
  }

  @include viewport--sm {
    width: 100%;
  }
}

.students__academy-logo {
  margin-bottom: size(35px);

  svg {
    width: size(160px);
    height: size(54px);
    fill: transparent;
  }

  @include viewport--md {
    margin-bottom: 32px;
  }

  @include viewport--sm {
    margin-bottom: 24px;
  }
}

.students__academy-logo-link {
  display: block;
  width: size(160px);
  transition: opacity $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }
}

.students__academy {
  @include text30;
}

.students__details {
  letter-spacing: 0.02em;

  p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}

.students__btn {
  display: block;
  color: $color-vivid-violet;
  max-width: size(270px);
  padding: size(20px) size(40px);
  font-size: size(14px);
  line-height: 100%;
  border: none;

  .coloured-section & {
    color: $color-vivid-violet;
  }
}

.students__row {
  display: flex;
  align-items: baseline;
}

.students__emoji {
  display: inline;
  width: size(20px);
  height: size(20px);
  margin-left: size(3px);
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }

  @include viewport--md {
    margin-left: 3px;
    width: 20px;
    height: 20px;
  }
}