.principles {
  @include section-paddings;
  position: relative;
  display: flex;
  justify-content: space-between;

  @include viewport--md {
    flex-direction: column;
  }

  .section-title {
    @media (min-width: $viewport--lg) {
      max-width: 45%;
    }
  }
}

.principles__title {
  @include viewport--md {
    margin-bottom: 72px;
  }
}

.principles__next {
  display: none;
  position: absolute;
  left: size(1px);
  bottom: size(-3px);

  @include viewport--md {
    left: auto;
    right: 0;
  }

  @media (max-width: 499px) {
    display: none;
  }
}

.principles__list {
  margin: 0;
  width: 42.9%;
  padding-top: size(7px);
  padding-left: 0;
  list-style: none;
  letter-spacing: 0.02em;

  @include viewport--md {
    margin-top: 0;
    padding-top: 0;
    width: 100%;
  }
}

.principles__item {
  max-width: size(386px);
  margin-bottom: size(32px);
  position: relative;
  padding-left: size(32px);

  &::before,
  &::after {
    position: absolute;
    top: size(10px);
    content: "";
    transition: background-color $default-transition-settings;
  }

  &::before {
    left: 0;
    width: size(8px);
    height: size(8px);
    background-color: $color-neon-blue;
  }

  &::after {
    bottom: size(-42px);
    left: size(3px);
    width: size(2px);
    background-color: $color-black;
    opacity: 0.06;
  }

  &:last-child {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }

  @include viewport--md {
    max-width: 80%;

    &::before {
      width: 8px;
      height: 8px;
    }

    &::after {
      width: 2px;
    }
  }

  @media (max-width: 499px) {
    max-width: none;
  }
}

.principles__item-title {
  margin-top: 0;
  margin-bottom: size(9px);
  color: $color-neon-blue;
  @include text20;

  transition: color $default-transition-settings;
}

.principles__item-text {
  margin: 0;
  color: $color-black--72a;
  transition: color $default-transition-settings;

  html.dark & {
    color: $color-white--72a;
  }
}


.principles__item--violet {
  &::before {
    background-color: $color-vivid-violet;
  }

  .principles__item-title {
    color: $color-vivid-violet;
  }
}

.principles__item--red {
  &::before {
    background-color: $color-torch-red;
  }

  .principles__item-title {
    color: $color-torch-red;
  }
}

html.dark {
  .principles__item {
    &::before {
      background-color: $color-torch-red;
    }

    &::after {
      background-color: $color-white;
    }
  }

  .principles__item-title {
    color: $color-torch-red;
  }
}
