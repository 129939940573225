.page-docs {
  padding-bottom: size(120px);

  @include viewport--static {
    padding-bottom: 80px;
  }

  @include viewport--sm {
    padding-bottom: 60px;
  }

  .page-title {
    margin-bottom: size(50px);

    @include viewport--lg {
      margin-bottom: 40px;
    }

    @include viewport--md {
      margin-bottom: 25px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: size(10px);
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    position: relative;
    padding-left: size(12px);

    &::before {
      content: "";
      position: absolute;
      top: size(12px);
      left: 0;
      width: size(4px);
      height: size(4px);
      background-color: $color-neon-blue;
      background-color: var(--text-content-contrast-color);
      border-radius: 50%;

      @include viewport--md {
        top: 12px;
        width: 4px;
        height: 4px;
      }

      @include viewport--sm {
        top: 10px;
      }
    }
  }
}
