.generator-intro {
  padding-top: size(90px);

  @include viewport--md {
    padding-top: 20px;
  }

  .container {
    display: grid;
    grid-template-columns: size(640px) 1fr;
    column-gap: size(40px);
    row-gap: size(35px);

    @include viewport--lg {
      row-gap: 35px;
      grid-template-columns: 540px 1fr;
    }

    @include viewport--md {
      grid-template-columns: 100%;
    }
  }

  &__content {
    @include viewport--md {
      grid-row: 2/3;
      grid-column: 1/-1;
    }
  }

  &__letter-wrap {
    width: 100%;
  }

  &__letter {
    margin-top: size(36px);
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    @include viewport--md {
      margin: 0 auto;
      width: 57.72%;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }

  &__letter-snow {
    position: absolute;
    inset: 0;
    mix-blend-mode: screen;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: size(33px);
    font-size: size(80px);
    font-weight: 700;
    line-height: size(72px);
    letter-spacing: -0.015em;

    @include viewport--lg {
      font-size: 68px;
      line-height: 1.1;
    }

    @include viewport--md {
      font-size: 56px;
      margin-bottom: 20px;
    }

    @include viewport--sm {
      font-size: 38px;
      line-height: 1;
    }
  }

  &__text {
    font-size: size(30px);
    font-weight: 500;
    line-height: size(40px);

    @include viewport--lg {
      font-size: 24px;
      line-height: 1.14;
    }

    @include viewport--md {
      font-size: 22px;
    }

    @include viewport--sm {
      font-size: 18px;
      line-height: 22px;
    }

    p {
      margin: 0;

      &:not(:first-child) {
        margin-top: 40px;

        @include viewport--md {
          margin-top: 20px;
        }
      }
    }
  }

  &__dino-track {
    position: absolute;
    display: flex;
    align-items: flex-end;
    inset: 0;
    padding: size(8px) size(10px);
    transform: translateZ(0.01px);
    z-index: 1;

    @include viewport--sm {
      padding: 1px 3px;
    }
  }

  --dino-width: 29.23%;
  --dino-animation-time: 8s;

  $step-1-start: 0%;
  $step-1-2: 5%;

  $step-2-1: 10%;
  $step-2: 12.5%;
  $step-2-2: 15%;

  $step-3-1: 45%;
  $step-3: 50%;
  $step-3-2: 55%;
  $step-3-3: 60%;
  $step-3-4: 65%;
  $step-3-5: 70%;

  $step-4-1: 75%;
  $step-4: 77.5%;
  $step-4-2: 80%;

  $step-5-1: 95%;
  $step-5-end: 100%;

  @keyframes dino-move {
    #{$step-1-start} {
      margin-left: 0;
      transform: rotateY(90deg);
    }

    #{$step-1-2} {
      margin-left: 0;
      transform: rotateY(0);
    }

    #{$step-2} {
      margin-left: calc(25% - (var(--dino-width) / 2));
    }

    #{$step-3-1} {
      margin-left: calc(100% - var(--dino-width));
      transform: rotateY(0);
    }

    #{$step-3-2} {
      margin-left: calc(100% - var(--dino-width));
      transform: rotateY(-180deg);
    }

    #{step-4} {
      margin-left: calc(25% - (var(--dino-width) / 2));
    }

    #{$step-5-1} {
      margin-left: 0;
      transform: rotateY(-180deg);
    }

    #{$step-5-end} {
      margin-left: 0;
      transform: rotateY(-90deg);
    }
  }

  @keyframes dino-roar-line {
    #{$step-1-2} {
      opacity: 0;
    }

    #{$step-2-1} {
      opacity: 1;
    }

    #{$step-2-2} {
      opacity: 0;
    }

    #{$step-3-2} {
      opacity: 0;
    }

    #{$step-3-4} {
      opacity: 1;
    }

    #{$step-3-5} {
      opacity: 0;
    }
  }

  &__dino {
    position: relative;
    width: var(--dino-width);
    height: 41%;
    animation: dino-move var(--dino-animation-time) linear 0s infinite;
  }

  .dino-roar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__line {
      opacity: 0;
      animation: dino-roar-line var(--dino-animation-time) ease 0s infinite;
    }
  }
}
