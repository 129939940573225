.vacancies {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.vacancies__item {
  margin-bottom: size(16px);

  &:last-of-type {
    margin-bottom: 0;
  }
}
