.btn {
  flex-shrink: 0;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  transition: background-color $default-transition-settings, color $default-transition-settings, border-color $default-transition-settings;
  position: relative;

  font-weight: 500;
  font-size: size(14px);
  line-height: 1;
  font-family: $font-family--neue-machina;
  letter-spacing: normal;

  padding: size(14px) size(22px);
  max-width: size(350px);
  background: $color-neon-blue;
  background: var(--btn-bg-color);
  color: $color-default-white;

  border: size(2px) solid var(--btn-bg-color);
  outline: none;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      background-color: $color-torch-red;
      border-color: $color-torch-red;
      background-color: var(--btn-bg-color-hover);
      color: var(--btn-text-color-hover);
      border-color: var(--btn-bg-color-hover);
    }
  }

  @include viewport--md {
    max-width: 350px;
    font-size: 14px;
    padding: 14px 22px;
    border-width: 2px;
  }

  .coloured-section & {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-neon-blue;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-white;
        background-color: $color-torch-red;
        border-color: transparent;
      }
    }

    html.dark & {
      color: $color-black;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus-visible {
          color: $color-white;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: size(42px);
    height: size(42px);
  }

  &__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__icon--left {
    left: size(-23px);
  }

  &__icon--right {
    right: size(-23px);
    transform: translateY(-50%) scale(-1, 1);
  }
}

.btn--big {
  padding: size(30px) size(70px);

  @include viewport--md {
    padding: 24px 56px;
  }

  @include viewport--sm {
    padding: 20px 46px;
  }
}

.btn--violet {
  background: $color-vivid-violet;
  background: var(--btn-violet-bg-color);
  border-color: $color-vivid-violet;
  border-color: var(--btn-violet-bg-color);

  &:hover,
  &:focus-visible {
    background-color: $color-deep-magenta;
    background-color: var(--btn-violet-bg-color-hover);
    border-color: $color-deep-magenta;
    border-color: var(--btn-violet-bg-color-hover);
  }
}

.btn--red {
  background: $color-torch-red;
  background: var(--btn-red-bg-color);
  border-color: $color-torch-red;
  border-color: var(--btn-red-bg-color);

  &:hover,
  &:focus-visible {
    background-color: $color-neon-blue;
    background-color: var(--btn-red-bg-color-hover);
    border-color: $color-neon-blue;
    border-color: var(--btn-red-bg-color-hover);
  }
}
