.filters {
  list-style: none;
  margin: 0;
  margin-top: size(40px);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: size(8px);
  font-size: size(14px);
  line-height: size(14px);

  &::-webkit-scrollbar {
    display: none;
  }

  @include viewport--md {
    font-size: 14px;
    line-height: 14px;
    margin-top: 32px;
    overflow-y: auto;
    flex-wrap: nowrap;
    gap: 8px;

    margin-left: -24px;
    margin-right: -24px;

    padding-left: 24px;
    padding-right: 24px;
  }

  @include viewport--sm {
    margin-top: 28px;

    margin-left: 0;
    margin-right: 0;

    padding-left: 0;
    padding-right: 0;

    position: relative;
  }

  &__item {
    position: relative;
    margin-bottom: size(10px);
    flex-shrink: 0;

    @include viewport--md {
      margin-bottom: 0;
    }

    @include viewport--sm {
      &:last-child {
        position: relative;
        display: flex;
        flex-wrap: nowrap;

        .filters__quantity {
          right: 20px;
        }

        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          position: relative;
        }
      }
    }
  }

  &__quantity {
    position: absolute;
    right: size(6px);
    top: size(6px);
    font-size: size(10px);
    line-height: size(8px);
    color: $color-torch-red;
    transition: color $default-transition-settings-04;

    @include viewport--md {
      right: 6px;
      top: 6px;
      font-size: 10px;
      line-height: 8px;
    }

    @include viewport--sm {
      right: 4px;
      top: 4px;
      font-size: 8px;
    }
  }

  &__title {
    border: 0;
    padding: size(16px) size(24px);
    background-color: $color-white-smoke-3;
    cursor: pointer;
    transition: background-color $default-transition-settings-04, color $default-transition-settings-04;

    color: $color-black;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        background-color: $color-torch-red;
        color: $color-default-white;
        transition: background-color $default-transition-settings-04, color $default-transition-settings-04;

        .filters__quantity {
          color: $color-default-white;
          transition: color $default-transition-settings-04;
        }
      }
    }

    &.is-active {
      background-color:  $color-neon-blue;
      color: $color-default-white;

      .filters__quantity {
        color: $color-default-white;
      }
    }

    @include viewport--md {
      padding: 16px 24px;
    }

    @include viewport--sm {
      padding: 12px 16px;
    }
  }
}
