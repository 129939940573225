.slider {
  position: relative;
}

.slider__container {
  padding-left: size(215px);

  @include viewport--static {
    padding-left: calc((100% - 1120px) / 2);
  }

  @media (max-width: 1168px) {
    padding-left: 24px;
  }

  @include viewport--sm {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.slider__wrapper {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.slider__slide {
  width: auto;
  flex-shrink: 0;
  padding-right: 32px;

  @include viewport--sm {
    padding-right: 0;
  }
}

.slider__controls {
  position: absolute;
  top: size(-96px);
  right: size(215px);

  @include viewport--static {
    right: calc((100% - 1120px) / 2 - 6px);
  }

  @media (max-width: 1168px) {
    right: 24px;
  }

  @include viewport--md {
    top: -78px;
  }

  @include viewport--sm {
    top: -59px;
    right: 16px;
  }
}

.slider--small-heading {
  .slider__controls {
    top: size(-77px);

    @include viewport--md {
      top: -70px;
    }

    @include viewport--sm {
      position: static;
      margin-left: auto;
      margin-right: 16px;
      margin-bottom: 20px;
    }
  }
}
