[data-animate] {
  // fade-in-bottom-40px
  .fib-40 {
    transform: translateY(40px);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // fade-in-bottom-60px
  &.fib-60,
  .fib-60 {
    transform: translateY(60px);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // fade-in-bottom-80px
  &.fib-80,
  .fib-80 {
    transform: translateY(80px);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // fade-in-top-60px
  .fit-60 {
    transform: translateY(60px);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // fade-in-top-40px
  .fit-40 {
    transform: translateY(40px);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // fade-in-left-60px
  .fil-60 {
    transform: translateX(-60px);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // fade-in-right-60px
  .fir-60 {
    transform: translateX(60px);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // width-in
  .wi {
    width: 0;

    @include viewport--md {
      width: 100%;
    }
  }

  // move-in-top-100
  .mit-100 {
    transform: translateY(-100%);

    @include viewport--md {
      transform: none;
    }
  }

  // move-in-top-100
  .fit-100 {
    transform: translateY(-100%);
    opacity: 0;

    @include viewport--md {
      transform: none;
    }
  }

  // move-in-left-100
  .mil-100 {
    transform: translateX(-100%);

    @include viewport--md {
      transform: none;
    }
  }

  // move-in-right-100
  .mir-100 {
    transform: translateX(100%);

    @include viewport--md {
      transform: none;
    }
  }

  // fade-in
  .fi {
    opacity: 0;

    @include viewport--md {
      opacity: 1;
    }
  }

  // scale-in
  .si-b {
    @media (min-width: $viewport--lg) {
      &::before {
        opacity: 0;
        transform: scale(2);
      }
    }
  }

  // scale-grow-up-09
  .sgu-05 {
    transform: scale(0.5);
    opacity: 0;

    @include viewport--md {
      transform: none;
      opacity: 1;
    }
  }

  // grow-bottom
  .gb-a {
    @media (min-width: $viewport--lg) {
      &::after {
        opacity: 0;
        bottom: 100%;
      }
    }
  }

  &.d-200,
  .d-200 {
    animation-delay: 0.2s;
  }

  .d-300 {
    animation-delay: 0.3s;
  }

  &.d-400,
  .d-400 {
    animation-delay: 0.4s;
  }

  .d-500 {
    animation-delay: 0.5s;
  }

  &.d-600,
  .d-600 {
    animation-delay: 0.6s;

    &::before,
    &::after {
      animation-delay: 0.6s;
    }
  }

  &.d-700,
  .d-700 {
    animation-delay: 0.7s;

    &::before,
    &::after {
      animation-delay: 0.7s;
    }
  }

  .d-800 {
    animation-delay: 0.8s;

    &::before,
    &::after {
      animation-delay: 0.8s;
    }
  }

  &.d-900,
  .d-900 {
    animation-delay: 0.9s;

    &::before,
    &::after {
      animation-delay: 0.9s;
    }
  }

  .d-1000 {
    animation-delay: 1s;

    &::before,
    &::after {
      animation-delay: 1s;
    }
  }

  .d-1200 {
    animation-delay: 1.2s;

    &::before,
    &::after {
      animation-delay: 1.2s;
    }
  }

  .d-1400 {
    animation-delay: 1.4s;

    &::before,
    &::after {
      animation-delay: 1.4s;
    }
  }

  .d-1600 {
    animation-delay: 1.6s;

    &::before,
    &::after {
      animation-delay: 1.6s;
    }
  }

  .d-1800 {
    animation-delay: 1.8s;
  }

  .d-2000 {
    animation-delay: 2s;
  }

  .du-1500 {
    animation-duration: 1.5s;
  }

  &.show {
    .fib-40 {
      animation-name: fade-in-bottom-40px;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    &.fib-60,
    .fib-60 {
      transform: translateX(60px);
      opacity: 0;

      animation-name: fade-in-bottom-60px;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    &.fib-80,
    .fib-80 {
      transform: translateX(80px);
      opacity: 0;

      animation-name: fade-in-bottom-60px;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    .fit-60 {
      animation-name: fade-in-top-60px;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    .fit-40 {
      animation-name: fade-in-top-40px;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    .fil-60 {
      animation-name: fade-in-left-60px;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    .fir-60 {
      animation-name: fade-in-right-60px;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    .fit-100 {
      animation-name: fade-in-top-100;
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    .fi {
      animation-name: fade-in;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }

    .wi {
      animation-name: width-in;
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        transform: none;
        opacity: 1;
      }
    }

    .mit-100 {
      animation-name: move-in-top-100;
      animation-duration: 0.6s;
      animation-timing-function: $default-transition;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }

    .mil-100 {
      animation-name: move-in-left-100;
      animation-duration: 0.6s;
      animation-timing-function: $default-transition;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }

    .mir-100 {
      animation-name: move-in-right-100;
      animation-duration: 0.6s;
      animation-timing-function: $default-transition;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }

    .si-b {
      &::before {
        @media (min-width: $viewport--lg) {
          animation-name: scale-in;
          animation-duration: 0.4s;
          animation-timing-function: ease-in-out;

          animation-fill-mode: both;
        }
      }
    }

    .sgu-05 {
      animation-name: scale-grow-up-05;
      animation-duration: 0.5s;
      animation-timing-function: $default-transition;

      animation-fill-mode: both;

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }

    .gb-a {
      &::after {
        @media (min-width: $viewport--lg) {
          animation-name: grow-bottom;
          animation-duration: 0.6s;
          animation-timing-function: ease-in-out;

          animation-fill-mode: both;
        }
      }
    }
  }
}
