.wwdid {
  overflow: hidden;

  &--results {
    .section-description {
      margin-bottom: size(74px);

      @include viewport--md {
        margin-bottom: 48px;
      }

      @include viewport--sm {
        margin-bottom: 32px;
      }
    }

    .wwdid__content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.wwdid--hr {
  position: relative;
  overflow: hidden;

  .wwdid__content {
    @include lightning-bg-blue;
    background-image: none;
    background-color: $color-snow;

    color: $color-black;

    padding-top: size(81px);

    html.dark & {
      background-image: none;
    }

    @include viewport--md {
      padding-top: 56px;
    }

    @include viewport--sm {
      padding-top: 28px;
    }
  }

  .wwdid__text {
    color: var(--text-color);
    margin-top: size(32px);
    margin-bottom: size(11px);

    font-size: size(24px);
    line-height: size(32px);
  }

  .wwdid-slider__project-link {
    color: $color-black;
  }

  .slider-controls__btn {
    svg {
      color: $color-black;
    }
  }

  .wwdid__footer {
    border-color:  $color-white--16a;
  }
}

.wwdid__content {
  @include section-paddings;
}

.wwdid .section-title {
  margin-bottom: size(60px);

  @include viewport--md {
    margin-bottom: 48px;
  }

  @include viewport--sm {
    margin-bottom: 32px;
  }
}

.wwdid--hr .section-title {
  margin-bottom: 0;
  position: absolute;
}

.wwdid--results .section-title {
  position: relative;
  margin-bottom: size(74px);

  @include viewport--md {
    margin-bottom: 48px;
  }

  @include viewport--sm {
    margin-bottom: 0;
  }
}

.wwdid__footer {
  border-top: size(2px) solid $color-white--16a;
  padding-top: size(64px);
  margin-top: size(64px);
  transition: border-color $default-transition-settings;

  @include viewport--md {
    border-width: 2px;
  }

  @include viewport--sm {
    padding-top: 30px;
    margin-top: 30px;
  }
}

.wwdid__text {
  margin: 0 0 size(20px);

  @include text30;
}

.wwdid__companies {
  min-width: calc(100% + #{size(430px)});
  margin-bottom: size(-20px);
  margin-left: -#{size(215px)};

  @include viewport--md {
    min-width: calc(100% + 430px);
    margin-left: -215px;
  }

  @include viewport--sm {
    min-width: calc(100% + 32px);
    margin-left: -16px;
  }
}

.wwdid__btn.btn {
  display: block;
  max-width: size(235px);
  margin: size(84px) auto 0;
  padding: size(20px) size(40px);
  border: none;

  font-size: size(14px);
  line-height: 100%;

  transition: color $default-transition-settings, background-color $default-transition-settings;

  @include viewport--sm {
    margin-top: 44px;
  }
}
