.configurator {
  @include section-paddings;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 0;
}

.configurator__title {
  max-width: size(640px);
}

.configurator__parameters {
  width: 58%;

  @include viewport--md {
    width: 100%;
  }
}

.configurator__subtitle {
  @include text30;
  margin-top: 0;
  margin-bottom: size(40px);
  max-width: size(448px);

  @include viewport--sm {
    margin-bottom: 36px;
    font-size: 24px;
    line-height: 32px;
  }
}

.configurator__aside {
  //margin-top: size(3px);
  width: size(352px);

  @include viewport--md {
    margin-top: 40px;
    width: 100%;
    padding-top: 0;
  }

  @include viewport--sm {
    margin-top: 17px;
  }
}
