// форма битрикса
.b24-window-popup-head .b24-window-popup-title {
  white-space: normal;
}

.b24-window-scrollable .b24-window-popup-head {
  padding-right: 65px;
}

.b24-form-dropdown .b24-form-dropdown-container {
  background-color: $color-default-white;
  max-height: size(225px);

  &::-webkit-scrollbar {
    width: size(6px);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: size(3px);
    background-color: $color-white-smoke;
  }
}

.b24-form-dropdown .b24-form-control-list-selector-item-title {
  color: $color-black;
}

.b24-window-popup .b24-window-popup-wrapper {
  background-color: $color-default-white;
}

.b24-window-popup .b24-window-popup-body {
  color: $color-black;
}

.b24-window-popup .b24-window-popup-title {
  color: $color-black;
}

.b24-form-btn-container.b24-form-btn-border {
  color: $color-black;
}

.b24-form-btn-container .b24-form-btn.b24-form-btn-border {
  color: $color-black;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }
}

.b24-form .b24-window-popup-body a {
  color: $color-black;
  transition: opacity $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }
}

.b24-form-field.b24-form-field-list.b24-form-control-list input {
  cursor: pointer;
}

.b24-form-control-file-item-name-text {
  // stylelint-disable-next-line
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  // stylelint-disable-next-line
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.b24-form-control-filelist .b24-form-control-file-item {
  border-color: $color-white--16a;
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container textarea {
  overflow: visible;
}

.b24-form-wrapper .b24-form-sign {
  margin-top: size(15px);
  padding: 0;

  @include viewport--static {
    margin-top: 9px;
  }

  @include viewport--lg {
    margin-top: 10px;
  }
}

.b24-form-control-list .b24-form-control-container::after {
  cursor: pointer;
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field-agreement div .b24-form-control-container input {
  cursor: pointer;
}

.b24-form-dropdown-container .b24-form-dropdown-title {
  color: $color-black;
}

.b24-form .b24-form-header-padding {
  padding: 0;
}

.b24-form .b24-form-dark .b24-form-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.b24-form-state-inner {
  padding: 15px;
}

// тёмная тема
html.dark {
  .b24-form-dropdown .b24-form-dropdown-container {
    background-color: $color-black;
  }

  .b24-form-dropdown .b24-form-control-list-selector-item-title {
    color: $color-default-white;
  }

  .b24-window-popup .b24-window-popup-wrapper {
    background-color: $color-black;
  }

  .b24-window-popup .b24-window-popup-body {
    color: $color-default-white;
  }

  .b24-window-popup .b24-window-popup-title {
    color: $color-default-white;
  }

  .b24-window-popup .b24-form-btn {
    color: $color-default-white;
  }

  .b24-form .b24-window-popup-body a {
    color: $color-default-white;
  }

  .b24-form-btn-container .b24-form-btn.b24-form-btn-border {
    border-color: $color-default-white;
  }

  .b24-form-control-list-selector-item {
    border-color: $color-white--16a;
  }

  .b24-form-control-string .b24-form-control,
  .b24-form-control-list .b24-form-control,
  .b24-form-control-text .b24-form-control,
  .b24-form-control-select .field-item {
    border-color: $color-default-white;
    color: $color-default-white;
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control-label {
    color: $color-default-white;
  }

  .b24-form-control-file {
    border-color: $color-default-white;
  }

  .b24-form-control-file .b24-form-control-label {
    color: $color-default-white;
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:-webkit-autofill,
  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:-webkit-autofill:focus-visible,
  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:-webkit-autofill:hover {
    // stylelint-disable-next-line
    -webkit-text-fill-color: $color-default-white !important;
  }

  .b24-form-control-comment {
    color: $color-default-white;
  }

  .b24-form-control-file .b24-form-control {
    border-color: $color-default-white;
    color: $color-default-white;
  }

  .b24-form-control-file .b24-form-control::before,
  .b24-form-control-file .b24-form-control::after {
    background-color: $color-default-white;
  }

  .b24-form-control-file-item-remove::before,
  .b24-form-control-file-item-remove::after {
    background-color: $color-default-white;
  }

  .b24-form-icon-remove::before,
  .b24-form-icon-remove::after {
    background-color: $color-default-white;
  }

  .b24-form-control-list .b24-form-control-container::after {
    border-color: $color-default-white;
  }

  .b24-form-field-agreement-link {
    color: $color-default-white;
  }

  .b24-form .b24-form-sign-abuse-link {
    color: $color-default-white;
  }

  .b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field-agreement div .b24-form-control-container .b24-form-control-desc .b24-form-field-agreement-link {
    color: $color-default-white;
  }

  .b24-form-control-file-item {
    border-color: $color-white--16a;
  }

  .b24-form-control-file-item-name-text {
    color: $color-default-white;
  }

  .b24-form-dropdown-container .b24-form-dropdown-title {
    color: $color-default-white;
  }
}
