.activity-video {
  width: 100%;
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      .activity-video__img {
        &::before {
          background-color: $color-vivid-violet;
        }
      }

      .activity-video__play {
        border-radius: 50%;
      }
    }
  }

  html.dark & {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        .activity-video__img {
          &::before {
            background-color: $color-torch-red;
          }
        }
      }
    }
  }
}

.activity-video__img {
  position: relative;
  padding-bottom: 69.5%;
  border: size(2px) solid $color-white;
  margin-bottom: size(29px);

  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    opacity: 0.9;

    transition: background-color $default-transition-settings;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $color-black--16a;
  }

  @include viewport--md {
    margin-bottom: 24px;
    border-width: 2px;
  }

  @include viewport--sm {
    margin-bottom: 16px;
  }
}

.activity-video__play {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: size(32px);
  height: size(32px);
  border: size(2px) solid $color-white;
  transition: border-radius $default-transition-settings;
  border-radius: 0;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: size(1px);
    transform: translate(-50%, -50%);
    content: "";
    border-left: size(6px) solid $color-white;
    border-top: size(5px) solid transparent;
    border-bottom: size(5px) solid transparent;
  }
}

.activity-video__title {
  @include text20;
}
