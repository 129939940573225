.do {
  padding: 0 size(80px);
  max-width: size(1440px);
  margin: 0 auto;
  margin-top: size(250px);

  @include viewport--md {
    padding: 0 16px;
    margin-top: 64px;
  }

  .section-title {
    text-align: center;
    margin-bottom: size(20px);

    @include viewport--md {
      margin-bottom: 20px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: size(27px);
    list-style: none;
    margin: 0;
    padding: 0;

    @include viewport--md {
      gap: 16px;
    }
  }

  &__item {
    font-size: size(80px);
    font-weight: 500;
    line-height: size(82px);
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include viewport--md {
      font-size: 30px;
      line-height: 32px;
    }

    color: var(--title-color-light-bg);
  }
}
