.tooltip {
  position: relative;
  display: inline-block;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: size(16px);
    height: size(16px);

    background-color: transparent;

    border: 2px solid $color-whisper;
    border-radius: 50%;

    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;

    transition: border-color $default-transition-settings;

    @include viewport--sm {
      width: 14px;
      height: 14px;
      border: 1px solid $color-whisper;
    }

    &:hover {
      border-color: $color-black;

      html.dark & {
        border-color: $color-white;
        opacity: 0.8;
      }

      &+ .tooltip__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

        @include viewport--sm {
          opacity: 0;
          visibility: hidden;
          transform: none;

          &.is-active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }
    }

    &:focus {
      border-color: $color-black;

      html.dark & {
        opacity: 0.8;
        border-color: $color-white;
      }

      &+ .tooltip__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

        @include viewport--sm {

          opacity: 0;
          visibility: hidden;
          transform: none;

          &.is-active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }
    }

    svg {
      transition: color $default-transition-settings;
      color: $color-black;
      width: size(16px);
      height: size(16px);

      html.dark & {
        color: $color-white;
      }
    }
  }

  &__content {
    position: absolute;
    z-index: 13;
    bottom: calc(100% + #{size(12px)});
    left: -#{size(96px)};
    width: size(208px);
    background: $color-neon-blue;
    border-radius: 16px;
    filter: drop-shadow(0px 8px 16px rgba(44, 57, 242, 0.24));
    color: $color-default-white;

    padding: size(16px);

    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: opacity $default-transition-settings, transform $default-transition-settings, visibility $default-transition-settings;

    pointer-events: none;

    @include viewport--sm {
      position: fixed;
      top: 0;
      left: auto;
      width: 208px;
      height: fit-content;
      padding: 0;
    }

    &.right-offset {
      border-radius: 16px 16px 4px 16px;

      &:after {
        display: none;
      }
    }

    html.dark & {
      background: $color-torch-red;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -#{size(10px)};
      left: 50%;
      transform: translateX(-50%);
      width: size(20px);
      height: size(12px);
      background-image: url('../img/sprite/rectangle-icon.svg');
      background-repeat: no-repeat;
      background-size: cover;

      @include viewport--sm {
        display: none;
      }

      html.dark & {
        background-image: url('../img/sprite/rectangle-icon-pink.svg');
      }
    }
  }

  &__text {
    margin: 0;
    font-size: size(14px);
    font-weight: 400;
    line-height: 129%;

    @include viewport--sm {
      font-size: 12px;
      line-height: 100%;

      margin: 16px;
    }
  }
}
