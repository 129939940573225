.expertise-card__title {
  @include text20;
  margin-top: size(15px);
  margin-bottom: size(9px);

  @include viewport--sm {
    margin-top: 9px;
  }
}

.expertise-card__text {
  margin: 0;
  letter-spacing: 0.02em;
  opacity: 0.72;
}

.expertise-card__counter {
  padding-left: size(7px);
}

.expertise-card__stick {
  margin-right: size(15px);
  display: inline-block;
  width: size(9px);
  height: size(17px);
  transform: skew(-40deg);
  background-color: $color-vivid-violet;
  opacity: 0.08;

  transition: background-color $default-transition-settings;

  html.dark & {
    background-color: $color-torch-red;
  }
}

.expertise-card__stick--active {
  opacity: 1;
}
