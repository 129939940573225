.theme-switcher {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: size(62px);
  height: size(30px);
  border: size(2px) solid $color-quartz;
  cursor: pointer;
  transition: border-color $default-transition-settings;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  html.dark & {
    border-color: $color-charcoal;
  }

  @include viewport--sm {
    width: 42px;
    height: 21px;
  }
}

.theme-switcher__slider {
  position: absolute;
  pointer-events: none;
  top: size(-1px);
  left: size(-1px);
  right: size(-1px);
  bottom: size(-1px);

  &::after {
    top: 0;
    left: 0;
    width: size(30px);
    bottom: 0;
    content: "";
    background-color: $color-quartz;
    position: absolute;
    transform: translateX(0);

    .document-loaded & {
      transition: transform $default-transition-settings, background-color $default-transition-settings;
    }

    html.dark & {
      background-color: $color-charcoal;
    }

    @include viewport--sm {
      width: 20px;
    }
  }

  .theme-switcher input:checked + &::after {
    transform: translateX(size(30px));

    @include viewport--sm {
      transform: translateX(20px);
    }
  }
}

.theme-switcher__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: color $default-transition-settings;

  &--sun {
    width: size(16px);
    height: size(16px);
    left: size(6px);
    color: $color-neon-blue;

    html.dark & {
      color: $color-charcoal;
    }

    @include viewport--sm {
      width: 10px;
      height: 10px;
      left: 4px;
    }
  }

  &--moon {
    right: size(10px);
    width: size(8px);
    height: size(13px);
    color: $color-quartz;

    html.dark & {
      color: $color-default-white;
    }

    @include viewport--sm {
      width: 6px;
      height: 10px;
      right: 6px;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    .theme-switcher:hover &,
    .theme-switcher input:focus + .theme-switcher__slider & {
      &--sun {
        color: $color-neon-blue;

        html.dark & {
          color: $color-default-white;
        }
      }

      &--moon {
        color: $color-neon-blue;

        html.dark & {
          color: $color-default-white;
        }
      }
    }
  }
}
