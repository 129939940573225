.footer {
  position: relative;
  @include lightning-bg-blue;
  padding-top: size(70px);
  padding-bottom: size(32px);
  isolation: isolate;

  @media (max-width: 1024px) {
    padding-top: 84px;
  }

  @include viewport--sm {
    padding-top: 42px;
  }

  &::before {
    z-index: 1;
    content: "";
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-snow;
    background-color: var(--bg-color);
  }

  .b24-form {
    form > div {
      width: 100%;
    }
  }
}

.footer--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer--cover {
  &::before {
    display: block;
  }
}

.footer--hr.footer--cover {
  &::before {
    display: none;
  }
}

.footer__title {
  @include text30;
  margin-top: 0;
  margin-bottom: size(18px);
  max-width: size(430px);

  @include viewport--sm {
    margin-bottom: 21px;
  }
}

.footer__form {
  margin-top: size(15px);

  @include viewport--sm {
    margin-top: 31px;
  }
}

.footer__container {
  display: flex;
  flex-wrap: wrap;
}

.footer__feedback {
  width: 576 / 1120 * 100%;
  padding-right: 128 / 1120 * 100%;
  padding-bottom: size(30px);
  border-right: size(1px) solid $color-white--16a;

  @media (max-width: 1279px) {
    padding-right: 56px;
  }

  @include viewport--md {
    padding-bottom: 52px;
    padding-right: 34px;
    border-width: 1px;
  }

  @include viewport--sm {
    width: 100%;
    padding-right: 0;
    border-right: none;
    padding-bottom: 42px;
    border-bottom: 2px solid $color-white--16a;
  }
}

.footer__contacts {
  width: 542 / 1120 * 100%;
  padding-bottom: size(30px);
  padding-left: 95 / 1120 * 100%;
  border-left: size(1px) solid $color-white--16a;

  @media (max-width: 1279px) {
    padding-left: 50px;
  }

  @include viewport--md {
    padding-bottom: 52px;
    padding-left: 30px;
    border-width: 1px;
  }

  @include viewport--sm {
    width: 100%;
    padding-top: 39px;
    padding-left: 0;
    padding-bottom: 42px;
    border-left: none;
  }
}

.footer__contact {
  margin-bottom: size(24px);

  @include viewport--sm {
    margin-bottom: 27px;
  }
}

.footer__info {
  @include text12-14;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: size(30px);
  border-top: size(2px) solid $color-white--16a;

  & > * {
    margin-right: size(34px);
  }

  @include viewport--md {
    flex-wrap: wrap;
    border-width: 2px;
    line-height: 14px;
  }

  @include viewport--sm {
    padding-top: 39px;
  }
}

.footer__subtitle {
  margin-top: 0;
  margin-bottom: size(-2px);
  letter-spacing: 0.02em;
  opacity: 0.72;
}

.footer__contact-text {
  @include text20;
  margin: 0;

  .link {
    white-space: nowrap;
  }

  @include viewport--md {
    margin-top: 2px;
  }
}

.footer__contact-text--call {
  @include viewport--sm {
    letter-spacing: -0.01em;
  }
}

.footer__manager {
  margin-top: size(-10px);
  margin-bottom: size(40px);
  display: flex;
  align-items: center;

  @include viewport--md {
    margin-top: -10px;
    margin-bottom: 40px;
    display: block;
  }
}

.footer__manager-img {
  position: relative;
  margin-right: size(24px);
  flex-shrink: 0;
  width: size(96px);
  height: size(96px);

  &::before {
    position: absolute;
    bottom: size(6px);
    left: size(7px);
    content: "";
    width: size(15px);
    height: size(15px);
    background-color: $color-lime-green;
    border: size(2px) solid $color-neon-blue;
    border-radius: 50%;
    z-index: 2;
  }

  .swiper-slide {
    border-radius: 50%;
    overflow: hidden;
  }

  html.dark & {
    &::before {
      border-color: $color-night-rider;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include viewport--md {
    border-width: 2px;
    margin-left: 0;
  }

  @include viewport--sm {
    width: 80px;
    height: 80px;
  }
}

.footer__manager-text {
  margin-top: size(-4px);
  max-width: size(380px);
  opacity: 0.72;
  letter-spacing: 0.02em;

  @include viewport--md {
    margin-top: 15px;
  }
}

.footer__socials {
  padding-top: size(55px);
  border-top: size(2px) solid $color-white--16a;

  @include viewport--md {
    padding-top: 39px;
    border-top-width: 2px;
  }
}

.footer__management {
  border-top: size(2px) solid $color-white--16a;
  margin: 0;
  padding: size(40px) 0 0;

  font-size: size(16px);
  line-height: 150%;

  letter-spacing: 0.02em;

  @include viewport--md {
    font-size: 14px;
    border-top-width: 2px;
    padding: 40px 0 0;
  }

  &-text {
    margin: 0;

    span {
      opacity: 0.72;
    }
  }
}

.footer__blog-link {
  margin: 0 0 size(40px);

  font-size: size(16px);
  line-height: 150%;

  letter-spacing: 0.02em;

  @include viewport--md {
    font-size: 14px;
  }

  span {
    opacity: 0.72;
  }
}

.footer__socials-list {
  margin-top: size(9px);
  margin-bottom: size(24px);

  @include viewport--md {
    margin-top: 17px;
  }
}

.footer__copyright {
  width: 252 / 1120 * 100%;
  opacity: 0.72;

  p {
    margin: 0;
  }

  @include viewport--sm {
    margin-right: 0;
    margin-bottom: 24px;
    width: 100%;
  }
}

.footer__policy {
  width: 30%;
  color: $color-white--72a;

  p {
    margin: 0;
  }

  @include viewport--sm {
    margin-right: 0;
    margin-bottom: 23px;
    width: 100%;
  }
}

.footer__specia {
  position: relative;
  top: size(2px);
  margin-left: 1.5%;

  a {
    border-bottom: none;
  }

  svg {
    width: size(110px);
    height: size(18px);
  }
}

.footer__workspace {
  margin-left: auto;
  margin-right: 0;

  a {
    display: flex;
    border-bottom: none;
    color: $color-white--72a;
    transform: translateZ(0);

    html.dark & {
      color: $color-white--72a;
    }
  }

  svg {
    width: size(95px);
    height: size(33px);
  }
}

.footer__design {
  margin-left: size(34px);
  margin-right: 0;
  color: $color-white--72a;

  span {
    display: inline-block;
    transform: translateY(size(-1px));

    @include viewport--md {
      transform: translateY(-1px);
    }
  }

  a {
    border-bottom: none;
  }

  svg {
    width: size(46px);
    height: size(8px);
  }
}

.footer--hr {
  padding-top: size(144px);
  padding-bottom: size(40px);

  @include viewport--md {
    padding-top: 84px;
  }

  @include viewport--sm {
    padding-top: 42px;
  }

  .footer__form {
    margin-top: size(30px);
  }

  .footer__manager {
    margin-top: size(42px);
    margin-bottom: size(77px);

    @include viewport--sm {
      margin-bottom: 30px;
    }
  }

  .footer__manager-img {
    &::before {
      width: size(20px);
      height: size(20px);
      left: size(5px);
      bottom: size(5px);
    }
  }


  .footer__socials {
    padding-top: size(40px);

    @include viewport--sm {
      padding-top: 40px;
    }
  }

  .socials {
    @include viewport--sm {
      max-width: 100%;

      .socials__item {
        margin-right: 20px;
      }
    }
  }

  .footer__contacts {
    padding-bottom: size(64px);
    width: 45%;

    @include viewport--sm {
      padding-bottom: 30px;
      width: 100%;
    }
  }

  .footer__info {
    padding-top: size(40px);
  }
}

.b24-form .b24-form-dark .b24-form-wrapper {
  max-width: 100% !important;
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-padding-side {
  padding: 0 !important;
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus-visible {
    -webkit-text-fill-color: $color-white !important;
    -webkit-box-shadow: inset 0 0 0 1000px transparent !important;
    transition: background-color 1000000s ease 0s !important;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control:focus+.b24-form-control-label, .b24-form-control-not-empty+.b24-form-control-label {
  top: 0 !important;
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control-label {
  font-size: size(13px);
  opacity: 1;
  overflow: visible;

  @include viewport--md {
    font-size: 13px;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field-agreement div .b24-form-control-container .b24-form-control-desc .b24-form-field-agreement-link {
  font-size: size(11px);

  @include viewport--md {
    font-size: 11px;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field-agreement div .b24-form-control-container input {
  width: size(10px);
  height: size(10px);

  @include viewport--md {
    width: 13px;
    height: 13px;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form .b24-form-btn-container .b24-form-btn-block button {
  min-height: size(52px);
  font-size: size(15px);

  @include viewport--md {
    min-height: 52px;
    font-size: 15px;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-control {
  font-size: size(16px);
  height: size(52px);

  @include viewport--md {
    min-height: 52px;
    font-size: 16px;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container textarea {
  height: size(40px) !important;
  min-height: auto !important;
  overflow: hidden;
  padding-top: 0 !important;
  margin-top: 23px;
  font-size: size(14px) !important;

  @include viewport--md {
    min-height: 40px;
    height: size(40px) !important;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-form-field div div .b24-form-control-container .b24-form-dropdown .b24-form-dropdown-container div .b24-form-control-list-selector-item {
  display: block !important;
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-window  .b24-window-popup .b24-window-popup-wrapper div .b24-window-scrollable .b24-window-popup-body {
  font-size: size(14px);
  line-height: size(19px);

  @include viewport--md {
    font-size: 14px;
    line-height: 19px;
  }
}

.b24-form .b24-form-dark .b24-form-wrapper .b24-form-content form div .b24-window  .b24-window-popup .b24-window-popup-wrapper div .b24-window-scrollable .b24-window-popup-head .b24-window-popup-title {
  white-space: unset;
  font-size: size(15px);
  line-height: size(24px);

  @include viewport--md {
    font-size: 15px;
    line-height: 24px;
  }
}

// .b24-form-control-list-selector-item {
//         display: block !important;
//       }

//       .b24-form-control:focus+.b24-form-control-label, .b24-form-control-not-empty+.b24-form-control-label {
//         top: 0 !important;
//       }

//       .b24-form-control {
//         &:-webkit-autofill,
//         &:-webkit-autofill:hover,
//         &:-webkit-autofill:focus {
//           -webkit-text-fill-color: $color-white !important;
//           -webkit-box-shadow: inset 0 0 0 1000px transparent !important;
//           transition: background-color 1000000s ease 0s !important;
//         }
// }

.b24-widget-button-wrapper .b24-widget-button-inner-container .b24-widget-button-inner-mask {
  height: size(82px);
  min-width: size(66px);
  width: calc(100% + #{size(16px)});
  top: -#{size(8px)};
  left: -#{size(8px)};

  @include viewport--md {
    height: 82px;
    min-width: 66px;
    width: calc(100% + 16px);
    top: -8px;
    left: -8px;;
  }
}

.b24-widget-button-wrapper .b24-widget-button-inner-container .b24-widget-button-block {
  width: size(66px);
  height: size(66px);

  @include viewport--md {
    width: 66px;
    height: 66px;
  }

  .b24-widget-button-inner-block {
    height: size(66px);

    @include viewport--md {
      height: 66px;
    }

    // .b24-widget-button-icon-container .b24-crm-button-icon.b24-crm-button-icon-active {
    //   width: size(28px);
    //   height: size(29px);
    // }
  }
}

.footer--simple {
  padding-top: 28px;
  padding-bottom: 34px;

  .footer__info {
    padding-top: 0;
    border-top: none;
  }
}
