.link {
  border-bottom: size(1px) solid $color-neon-blue;
  transition: color $default-transition-settings, opacity $default-transition-settings, border-color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      color: $color-torch-red;
      border-color: $color-torch-red;
    }
  }

  html.dark & {
    color: $color-white;
    border-color: $color-white;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-white;
        border-color: $color-white;
        opacity: 0.5;
      }
    }
  }

  @include viewport--md {
    border-width: 1px;
  }

  &--no-border {
    border: none;
  }
}

.link--border2 {
  border-bottom: size(2px) solid;

  @include viewport--md {
    border-width: 2px;
  }
}

.link--source {
  border-bottom: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: size(14px);
  line-height: 1;
  letter-spacing: normal;

  svg {
    margin-right: 0.5em;
    fill: transparent;

    width: size(16px);
    height: size(23px);
  }

  html.dark & {
    color: $color-torch-red;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-white;
        opacity: 1;
      }
    }
  }

  @include viewport--md {
    font-size: 14px;

    svg {
      width: 16px;
      height: 23px;
    }
  }
}

.link--transparent {
  &,
  .coloured-section & {
    color: $color-white--72a;
    border-bottom-color: $color-white--72a;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-white;
        border-bottom-color: $color-white;
        opacity: 1;
      }
    }
  }
}
