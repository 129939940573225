.reviews-page {
  min-height: 100vh;
  padding-bottom: size(120px);

  @include viewport--md {
    padding-bottom: 0;
  }

  @include viewport--sm {
    padding-top: 18px;
  }

  &__filters {
    @include viewport--sm {
      padding-left: 16px;
    }
  }

  &__reviews-list {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;

    margin-top: size(27px);
    margin-right: -#{size(16px)};

    transition: transform $default-transition-settings-04, opacity $default-transition-settings-04;

    &.transform {
      opacity: 0;
      transform: translateY(-20px);
    }

    @include viewport--md {
      margin-top: 56px;
      margin-right: -16px;
    }

    @include viewport--sm {
      margin-top: 36px;
      margin-right: -8px;
    }

    @media (max-width: 500px) {
      margin-right: 0;
    }
  }

  &__reviews-item {
    display: none;
    width: calc(25% - #{size(16px)});
    margin-right: size(16px);
    margin-bottom: size(32px);

    @include viewport--md {
      width: calc(33% - 16px);
      margin-right: 16px;
    }

    @include viewport--sm {
      width: calc(50% - 8px);
      margin-right: 8px;
    }

    @media (max-width: 500px) {
      width: 100%;
      margin-right: 0;
    }

    &.is-active {
      display: block;
    }
  }

  .review-item {
    transition: transform 0.3s ease;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        transform: scale(1.05);
      }
    }
  }
}
