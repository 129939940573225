.reasons {
  padding: size(160px) 0;

  @include viewport--md {
    padding: 60px 0;
  }

  .section-title {
    text-align: center;
    margin: 0 0 size(70px) 0;

    @include viewport--sm {
      text-align: left;
      margin: 0 0 32px 0;
    }

    &::before {
      display: none;
    }
  }

  &__list {
    list-style: none;
    margin: 0 0 size(120px) 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    gap: size(40px);

    @include viewport--md {
      margin: 0 0 50px 0;
      gap: 14px;
    }

    @include viewport--sm {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    padding: size(18px) size(28px);
    min-height: size(180px);
    width: calc(50% - #{size(20px)});
    background-color: var(--white-bg-color);
    border: 2px solid var(--grey-border-color);
    border-radius: size(40px);
    transition: background-color $default-transition-settings-06,
      border-color $default-transition-settings-06;

    @include viewport--md {
      min-height: 140px;
      padding: 13px 18px;
    }

    @include viewport--sm {
      width: 100%;
    }
  }

  &__text {
    margin: 0;
    max-width: size(400px);
    font-weight: 500;
    font-size: size(30px);
    line-height: size(40px);

    @include viewport--md {
      font-size: 22px;
      line-height: 30px;
    }

    @include viewport--sm {
      max-width: 303px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__icon {
    position: absolute;
    right: size(-32px);
    bottom: size(-32px);
    width: size(200px);
    height: size(200px);
    color: var(--icon-border-color);
    transition: color $default-transition-settings-06;

    @include viewport--ml {
      width: 150px;
      height: 150px;
      right: -36px;
      bottom: -36px;
    }

    @include viewport--md {
      width: 110px;
      height: 110px;
      right: -2px;
      bottom: -14px;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .icon-light {
      transform-origin: center center;
      animation: flicker 0.8s ease infinite alternate;

      &:nth-child(odd) {
        animation-delay: 0.4s;
      }
    }
  }

  &__get-started {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .reasons__text {
      text-align: center;
    }
  }

  &__emoji {
    margin: size(15px) 0 0 0;
    width: size(65px);
    height: size(65px);

    @include viewport--md {
      margin: 10px 0 0 0;
      width: 65px;
      height: 65px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      user-select: none;
    }
  }
}

@keyframes flicker {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
