.experience {
  @include section-paddings;
}

.experience__reviews {
  padding-top: size(61px);

  @include viewport--md {
    padding-top: 42px;
  }
}

.experience__reviews-intro {
  @include text30;
  margin-top: 0;
  margin-bottom: size(51px);
  padding-right: size(120px);

  @include viewport--md {
    margin-bottom: 44px;
  }

  @include viewport--sm {
    margin-bottom: 18px;
    padding-right: 16px;
  }
}
