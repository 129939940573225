.page-description {
  margin: size(24px) 0 size(48px);

  p {
    margin: 0;

    font-size: size(24px);
    line-height: size(34px);
    font-weight: 400;
    letter-spacing: normal;

    @include viewport--md {
      font-size: 28px;
      line-height: 37px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
