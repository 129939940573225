@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes width-in {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes fade-in-top-50 {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-20 {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-40 {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-top-40 {
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-top-20px {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-top-40px {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-top-40 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40%);
  }
}

@keyframes fade-out-top-20px {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fade-out-top-40px {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes fade-in-bottom-60 {
  0% {
    opacity: 0;
    transform: translateY(60%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
} //used

@keyframes fade-out-bottom-20 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}

@keyframes fade-out-bottom-20px {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes fade-out-bottom-40 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(40%);
  }
}

@keyframes fade-out-top-20px {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fade-in-bottom-10px {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-20px {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-bottom-10px {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes fade-in-bottom-30px {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-40px {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-bottom-40px {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(40px);
  }
}

@keyframes fade-in-bottom-60px {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-left-60px {
  0% {
    opacity: 0;
    transform: translateX(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-left-20 {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-right-20 {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-right-40 {
  0% {
    opacity: 0;
    transform: translateX(40%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-right-40px {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-out-right-20 {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20%);
  }
}

@keyframes fade-out-right-40 {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(40%);
  }
}

@keyframes fade-out-right-40px {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(40px);
  }
}

@keyframes fade-in-left-40 {
  0% {
    opacity: 0;
    transform: translateX(-40%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-left-60 {
  0% {
    opacity: 0;
    transform: translateX(-60%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-left-60px {
  0% {
    opacity: 0;
    transform: translateX(-60px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-right-60px {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes move-in-top-100 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes move-in-left-100 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes move-in-right-100 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in-top-60px {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-top-100 {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-left-20 {
  0% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}

@keyframes fade-in-right-40px {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-out-right-40px {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(40px);
  }
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-grow-up-05 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes grow-bottom {
  0% {
    opacity: 0;
    bottom: 100%;
  }

  100% {
    opacity: 0.06;
    bottom: size(-42px);
  }
}

@keyframes jump {
  0% {
    transform: scale(1);
  }

  90% {
    transform: scale(1);
  }
  95% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
