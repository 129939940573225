.wwdid-slider__slide {
  display: flex;
  justify-content: space-between;

  @include viewport--md {
    display: block;
    padding-top: 44px;
  }
}

.wwdid-slider__images {
  width: 53%;
  position: relative;
  margin-right: 16%;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 1279px) {
    width: 500px;
    margin-right: 148px;
  }

  @include viewport--md {
    width: 590px;
    margin-right: 0;
    margin-bottom: 40px;
  }

  @include viewport--sm {
    margin-right: 45px;
    width: calc(100% - 45px);
  }
}

.wwdid-slider__img {
  border: size(2px) solid $color-black;
  border-radius: size(5px);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.wwdid-slider__img--desktop {
  width: 100%;
}

.wwdid-slider__img--mobile {
  right: 0;
  top: 50%;
  width: 16.5%;
  position: absolute;

  transform: translate(50%, -50%);

  @media (max-width: 1279px) {
    width: 98px;
  }

  @include viewport--sm {
    width: 90px;
  }
}

.wwdid-slider__content {
  padding-top: size(44px);
  padding-bottom: size(44px);
  flex-grow: 1;

  @media (max-width: 1279px) {
    padding-top: 34px;
  }

  @include viewport--md {
    padding-top: 0;
  }
}

.wwdid-slider__controls {
  position: absolute;
  top: 0;
  left: 69%;
  z-index: 2;

  @media (max-width: 1279px) {
    left: 648px;
  }

  @include viewport--md {
    left: 0;
  }
}

.wwdid-slider__project-name {
  margin: 0 0 size(8px);
  font-weight: 400;
  font-size: size(20px);
  line-height: size(24px);
}

.wwdid-slider__project-description {
  margin: 0 0 size(16px);
  font-weight: 400;
  font-size: size(16px);
  line-height: size(24px);
  opacity: 0.72;
}

.wwdid-slider__project-link {
  font-weight: 500;
  font-size: size(14px);
  line-height: size(14px);
  display: inline-block;
  position: relative;
  padding-left: size(23px);
  border-bottom: none;
  transition: color $default-transition-settings, transform $default-transition-settings, opacity $default-transition-settings;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: size(16px);
    height: size(23px);
  }
}

.wwdid-slider__pagination {
  position: absolute;
  bottom: 0;
  left: 69%;
  z-index: 2;
  font-weight: 500;
  font-size: size(24px);
  line-height: size(24px);

  display: flex;

  .swiper-pagination-total {
    font-size: size(16px);
    margin-left: size(5px);
  }

  .swiper-pagination-current {
    min-width: size(17px);
    margin-right: size(5px);
  }

  @media (max-width: 1279px) {
    left: 648px;
  }

  @include viewport--md {
    left: 0;
  }
}
