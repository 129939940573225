.project {
  position: relative;
  display: grid;
  grid-template-columns: size(584px) auto size(192px);
  width: 100%;
  padding: size(25px) 0;
  border-bottom: size(2px) solid $color-whisper;
  cursor: pointer;

  color: $color-black;
  align-items: baseline;
  color: var(--text-color);

  @include viewport--xl {
    grid-template-columns: 524px auto 192px;
  }

  @include viewport--md {
    padding-top: 0;
    padding-bottom: 32px;
    grid-template-columns: 360px auto;
    grid-template-areas: "img title"
                         "img type"
                         "img links"
                         "img ."
                         "img ."
                         "img .";
    align-items: start;
    column-gap: 30px;
    row-gap: 16px;
    border-bottom: 2px solid $color-whisper;
    cursor: default;
  }

  @include viewport--sm {
    display: block;
  }

  &:focus-visible,
  &:hover {
    .project__img {
      opacity: 1;
      transition: opacity $default-transition-settings-04;
    }

    .project__title {
      color: $color-neon-blue;
      transition: color $default-transition-settings-04;

      html.dark & {
        color: $color-torch-red;
      }
    }

    // .project__quantity {
    //   display: none;
    // }

    // .project__links {
    //   opacity: 1;
    // }

    // .project__link {
    //   color: $color-neon-blue;

    //   html.dark & {
    //     color: $color-torch-red;
    //   }
    // }
  }

  &__img {
    position: absolute;
    // left: 50%;
    // transform: translate(-50%);
    bottom: size(-200px);
    width: size(360px);
    height: size(200px);
    opacity: 0;
    border-radius: size(10px);
    overflow: hidden;
    z-index: 1;
    transition: opacity $default-transition-settings-04;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include viewport--xl {
      bottom: -200px;
    }

    @include viewport--md {
      position: static;
      width: 360px;
      height: 200px;
      opacity: 1;
      transform: unset;
      border-radius: 10px;
      grid-area: img;
    }

    @include viewport--sm {
      width: 100%;
      height: 50vw;
      margin-bottom: 16px;
    }
  }

  &__title-link {
    align-self: self-start;
    color: var(--text-color);
  }

  &__title {
    margin: 0;
    padding-right: size(20px);
    padding-left: size(72px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: normal;
    font-size: size(20px);
    line-height: size(30px);
    transition: color $default-transition-settings-04;

    @include viewport--md {
      padding-right: 0;
      padding-left: 0;
      font-size: 20px;
      line-height: 20px;
      grid-area: title;
    }

    @include viewport--sm {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__hot,
  &__new {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    left: 0;
    padding: size(8px) size(12px);
    min-width: size(60px);
    font-size: size(14px);
    line-height: size(14px);
    color: $color-default-white;
    background-color: $color-vivid-violet;
    border-radius: 100px;

    @include viewport--md {
      min-width: auto;
      // position: absolute;
      top: 8px;
      left: 8px;
      margin: 0;
      padding: 4px 8px;
      font-size: 12px;
      z-index: 1;
    }
  }

  &__hot {
    background-color: $color-torch-red;
  }

  &__type {
    margin: 0;
    margin-left: size(24px);
    font-size: size(16px);
    line-height: size(20px);
    letter-spacing: 0.02em;

    @include viewport--xl {
      margin-left: 0;
    }

    @include viewport--md {
      font-size: 16px;
      line-height: 20px;
      grid-area: type;
    }

    @include viewport--sm {
      font-size: 14px;
      line-height: 20px;
    }
  }

  // &__quantity {
  //   margin: 0;
  //   font-size: size(14px);
  //   line-height: size(14px);
  //   color: var(--text-color-opacity);

  //   &.is-hidden {
  //     display: none;
  //   }

  //   @include viewport--md {
  //     display: none;
  //   }
  // }

  &__right-col {
    display: flex;
    flex-wrap: wrap;
    gap: size(20px);
    position: relative;
    justify-self: end;

    @include viewport--md {
      margin-top: 16px;
      justify-self: unset;
      grid-area: links;
    }
  }

  // &__links {
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   right: 0;
  //   opacity: 0;
  //   display: flex;
  //   list-style: none;
  //   padding: 0;

  //   &.is-active {
  //     opacity: 1;
  //   }

  //   @include viewport--md {
  //     position: static;
  //     transform: unset;
  //     opacity: 1;
  //   }

  //   @include viewport--sm {
  //     margin-top: 16px;
  //   }
  // }

  // &__link-data {
  //   margin-right: 0;
  //   font-size: size(14px);
  //   line-height: size(14px);
  //   white-space: nowrap;

  //   // &:last-child {
  //   //   margin-right: 0;
  //   // }

  //   @include viewport--md {
  //     //margin-right: 32px;
  //     font-size: 14px;
  //     line-height: 14px;
  //   }

  //   @include viewport--sm {
  //     font-size: 16px;
  //     line-height: 20px;
  //   }
  // }

  &__link {
    position: relative;
    // color: var(--text-color-opacity);
    transition: color $default-transition-settings-04;

    position: relative;

    padding-right: size(7px);

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: calc(100% + #{size(10px)});
      height: size(40px);
      transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus-visible {
      // color: $color-neon-blue;
      transition: color $default-transition-settings-04;
    }

    svg {
      position: absolute;
      top: 0;
      right: -#{size(3px)};
      width: size(7px);
      height: size(7px);
    }
  }

  &__main-link {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
