.loader {
  background: $color-default-white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000000;
  align-items: center;
  justify-content: center;
  display: none;

  animation-name: close-loader;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-delay: 4s;

  html.dark & {
    background: $color-nero;
  }
}

.loader__inner {
  width: 100%;
  position: relative;

  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.loader__sky {
  position: absolute;

  bottom: size(130px);
  height: size(149px);
  right: -100vw;
  left: 0;

  background-image: url("../img/loader/loader-sky.svg");
  background-size: size(1221px) size(149px);
  background-position: 0 top;
  background-repeat: repeat-x;

  @include viewport--md {
    right: -200vw;
  }

  @include viewport--sm {
    right: -300vw;
  }
}

.loader__ground {
  width: 100%;
  position: relative;
  padding-bottom: size(25px);

  &::after {
    content: "";
    position: absolute;
    top: size(1px);
    height: size(3px);
    right: -100vw;
    left: 0;
    background: $color-default-black;

    html.dark & {
      background: $color-default-white;
    }

    @include viewport--md {
      right: -200vw;
    }

    @include viewport--sm {
      right: -300vw;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: size(4px);
    right: -100vw;
    left: 0;

    background-image: url("../img/loader/ground.svg");
    background-size: size(696px) size(4px);
    background-position: 0 bottom;
    background-repeat: repeat-x;

    html.dark & {
      background-image: url("../img/loader/ground-inverse.svg");
    }

    @include viewport--md {
      right: -200vw;
    }

    @include viewport--sm {
      right: -300vw;
    }
  }
}

.loader__dino {
  animation-name: dino-jump, dino-disappears;
  animation-duration: 1.5s, 1s;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: linear, linear;
  animation-iteration-count: 2, 1;
  animation-delay: 0s, 3s;
  margin: 0 auto;

  .loader__dino-img {
    display: block;
    width: size(106px);
    height: size(120px);
    position: relative;
  }

  .loader__dino-img span {
    background-size: size(106px) size(120px);
    background-position: 0 top;
    background-repeat: no-repeat;
    opacity: 1;

    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;

    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: 6;
    animation-delay: 0s;

    &.right-step {
      animation-name: dino-walks-right-step;
    }

    &.both-step {
      animation-name: dino-walks-both-step;
      opacity: 0;
    }

    &.left-step {
      animation-name: dino-walks-left-step;
      opacity: 0;
    }
  }

  .dark-theme {
    display: none;
  }

  html.dark & {
    .light-theme {
      display: none;
    }

    .dark-theme {
      display: block;
    }
  }

  @include viewport--md {
    animation-name: dino-jump, dino-disappears-right;
    animation-duration: 1s, 1s;
    animation-iteration-count: 3, 1;
  }
}

.loader__tools {
  position: absolute;
  right: -100vw;
  left: 0;
  bottom: size(26px);
  line-height: 0;

  display: flex;
  justify-content: space-between;
  padding: 0 34vw 0 70vw;
  margin-bottom: 4px;

  @media (min-width: 1441px) {
    padding: 0 36vw 0 70vw;
  }

  @include viewport--md {
    right: -200vw;
    padding: 0 85vw 0 85vw;
  }

  @include viewport--sm {
    right: -300vw;
    padding: 0 100vw 0 100vw;
  }
}

.loader__tool {
  animation-name: hide-tools;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-delay: 3.5s;

  width: size(56px);
  height: size(56px);
  border-radius: 50%;
  background: $color-quartz;
  color: $color-neon-blue;
  position: relative;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  html.dark & {
    background: $color-charcoal;
    color: $color-default-white;
  }
}

.loader__tool--html svg {
  width: size(28px);
  height: size(32px);
}

.loader__tool--css svg {
  width: size(28px);
  height: size(32px);
}

.loader__tool--js svg {
  width: size(28px);
  height: size(28px);
}

.animation-scene-scroll {
  animation: scene-scroll 4s linear;
  animation-fill-mode: forwards;

  @include viewport--md {
    animation-name: scene-scroll-tablet;
  }

  @include viewport--sm {
    animation-name: scene-scroll-mobile;
  }
}


@keyframes dino-walks-right-step {
  0% {
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes dino-walks-both-step {
  0% {
    opacity: 0;
  }

  24% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  74% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes dino-walks-left-step {
  0% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  74% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes dino-jump {
  30% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(size(-120px));
  }

  65% {
    transform: translateY(size(-120px));
  }

  80% {
    transform: translateY(0);
  }
}

@keyframes dino-disappears {
  0% {
    transform: translateY(0);
  }

  45% {
    transform: translate(size(120px), size(-120px));
  }

  65% {
    transform: translate(size(240px), size(-120px));
  }

  80% {
    opacity: 1;
    transform: translate(size(240px), size(-80px));
  }

  100% {
    opacity: 0;
    transform: translate(size(240px), size(-40px));
  }
}

@keyframes dino-disappears-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(560px);
  }
}

@keyframes scene-scroll {
  80% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(-100vw);
  }
}

@keyframes scene-scroll-tablet {
  80% {
    transform: translateX(-200vw);
  }

  100% {
    transform: translateX(-200vw);
  }
}

@keyframes scene-scroll-mobile {
  80% {
    transform: translateX(-300vw);
  }

  100% {
    transform: translateX(-300vw);
  }
}

@keyframes close-loader {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide-tools {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
