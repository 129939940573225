.page-title {
  font-weight: 400;
  font-size: size(96px);
  line-height: 1.04;
  color: $color-neon-blue;
  color: var(--title-color-light-bg);
  margin: 0;
  font-family: $font-family--neue-machina;
  padding-top: size(78px);
  word-break: break-word;
  transition: color $default-transition-settings;

  @media (max-width: 1279px) {
    font-size: 90px;
  }

  @include viewport--md {
    font-size: 72px;
    padding-top: 50px;
  }

  @include viewport--sm {
    font-size: 38px;
    padding-top: 25px;
  }

  .coloured-section & {
    color: var(--title-color-coloured-bg);
  }
}

.page-title--intro {
  padding-top: size(101px);

  @include viewport--md {
    padding-top: 50px;
  }

  @include viewport--sm {
    padding-top: 25px;
  }
}

.page-title--center {
  text-align: center;
}
