.letter-a {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  svg {
    width: size(708px);
    height: size(770px);
    color: $color-black;
    opacity: 0.06;
    transition: color $default-transition-settings, opacity $default-transition-settings;

    html.dark & {
      color: #ffffff;
      opacity: 0.02;
    }

    @include viewport--sm {
      width: 320px;
      height: 348px;
    }
  }
}
