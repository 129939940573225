.intro-service {
  @include lightning-bg;
  border: size(2px) solid $color-black;
  border: size(2px) solid var(--text-color);
  padding: size(64px) size(32px) size(31px);
  min-height: size(252px);

  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;

  transition: transform 0.3s ease;
  transform-style: preserve-3d;
  will-change: transform;

  cursor: default;

  &::before {
    @include text20;
    content: "</>";
    color: $color-neon-blue;
    color: var(--btn-bg-color);
    position: absolute;
    left: size(32px);
    top: size(24px);
    transition: color $default-transition-settings;

    @include viewport--sm {
      left: 15px;
    }
  }

  html.dark & {
    border-color: $color-charcoal;
  }

  @include viewport--md {
    border-width: 2px;
  }

  @include viewport--sm {
    padding: 64px 15px 30px;
    min-height: 180px;
  }
}

.intro-service__title {
  @include text20;
  margin: 0 0 size(8px);
}

.intro-service__description {
  margin: 0;
  opacity: 0.72;
}

.intro-service .link {
  position: relative;
  align-self: flex-start;
  margin: size(16px) 0 0;
  padding-left: size(23px);

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: size(16px);
    height: size(23px);
  }
}
