.review {
  @include lightning-bg;
  position: relative;
  width: size(544px);
  border: size(2px) solid $color-black;
  padding: size(27px) size(30px) size(30px);

  html.dark & {
    border-color: $color-white;
  }

  @include viewport--lg {
    width: 808px;
  }

  @include viewport--md {
    width: 592px;
    border-width: 2px;
  }

  @include viewport--sm {
    padding: 22px 14px;
    width: 472px;
  }

  @media (max-width: 599px) {
    width: 100%;
  }
}

.review__acc {
  min-height: size(190px);

  @include viewport--sm {
    min-height: 0;
  }
}

.review__text {
  @include text20;

  max-height: size(160px);
  position: relative;
  overflow: hidden;
  transition: max-height $default-transition-settings;

  @media (min-width: $viewport--md) {
    line-height: size(32px);
  }

  @include viewport--sm {
    max-height: none;
  }
}

.review__toggle {
  @include reset-btn;
  color: $color-neon-blue;
  margin: size(3px) 0 size(15px);

  font-size: size(16px);
  line-height: 1.6;
  font-weight: 500;
  position: relative;
  z-index: 2;
  padding-right: size(20px);

  transition: color $default-transition-settings, opacity $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0) {
    &:hover,
    &:focus-visible {
      color: $color-torch-red;
    }
  }

  svg {
    transition: transform $default-transition-settings;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .active & {
    svg {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  html.dark & {
    color: $color-white;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        color: $color-white;
        opacity: 0.5;
      }
    }
  }

  @include viewport--sm {
    display: none;
  }
}

.review__author {
  margin-top: size(17px);
  letter-spacing: 0.02em;
  opacity: 0.72;
  min-height: size(48px);

  @include viewport--sm {
    min-height: 0;
  }
}

.review__footer {
  margin-top: size(8px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: size(64px);
  height: size(64px);
  padding: size(6px);

  svg {
    width: 100%;
    height: 100%;
  }
}

.review__more {
  position: relative;
  bottom: size(3px);
  font-size: size(14px);
  line-height: 1.6;
  font-weight: 500;

  @include viewport--sm {
    font-size: 12px;
  }
}

.review__date {
  position: absolute;
  right: size(32px);
  top: 0;
  transform: translateY(-50%);

  padding: 0 size(12px);

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: size(113px);
  height: size(30px);

  background: $color-default-white;

  border: 2px solid $color-black;
  border-radius: 100px;

  font-size: size(14px);
  line-height: 100%;

  transition: color $default-transition-settings;

  html.dark & {
      color: $color-black;
    }
}
