.rates-table {
  @include section-pt;
  padding-top: 0;

  @include viewport--md {
    padding-top: 0;
  }

  &__table {
    border: 2px solid $color-whisper;
    border-collapse: collapse;

    @include viewport--sm {
      position: relative;
      display: block;
      border: 1px solid $color-whisper;

      margin: 0;
      padding: 0;
    }

    tr {
      &:last-child {
        @include viewport--sm {
          .rates-table__cell {
            border-bottom: none;
          }
        }
      }
    }
  }

  tbody {
    @include viewport--sm {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  &__row {
    &:not(.rates-table__row--head) {
      @include viewport--sm {
        display: flex;
        flex-wrap: wrap;

        overflow: hidden;
      }

      .rates-table__cell {
        @include viewport--sm {
          width: 33%;
          flex-grow: 1;
          border-left: none;

          &:last-of-type {
            border-right: none;
          }
        }
      }

      th {
        @include viewport--sm {
          width: 100%;
          border: none;
        }
      }
    }

    &--head {
      @include viewport--sm {
        display: flex;
        position: sticky;

        overflow: hidden;

        z-index: 12;

        background-color: $color-snow;
        background-color: var(--bg-color);
      }

      &:nth-child(1) {
        @include viewport--sm {
          top: 51px;
        }

        .rates-table__head-title {
          @include viewport--sm {
            flex-grow: 1;
            border: 1px solid $color-whisper;
            border-top: none;
            // border-bottom: 1px solid $color-whisper;
          }

          &:first-of-type {
            @include viewport--sm {
              min-width: calc(66% + 1px);
              border-left: none;
              border-right: none;
            }
          }
          &:last-of-type {
            @include viewport--sm {
              border-right: none;
            }
          }
        }
      }

      &:nth-child(2) {
        @include viewport--sm {
          top: 96px;
        }

        .rates-table__head-title {
          @include viewport--sm {
            flex-grow: 1;
            width: 33%;
            border: 1px solid $color-whisper;
            border-left: none;
            border-top: none;
          }

          &:last-of-type {
            @include viewport--sm {
              border-right: none;
            }
          }
        }
      }

      .rates-table__cell {
        border-top: none;
        border-bottom: none;

        @include viewport--sm {
          display: none;
        }
      }
    }
  }

  &__tooltip {
    display: inline;
    position: relative;
    margin-left: size(7px);

    @include viewport--sm {
      margin-left: 3px;
    }

    .tooltip {
      position: relative;
      top: size(3px);

      @include viewport--sm {
        top: auto;
        bottom: -3px;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;

    .tooltip {
      top: size(4px);

      @include viewport--sm {
        top: auto;
        bottom: -5px;
      }
    }

    .rates-table__head-title & {
      justify-content: center;
    }
  }

  &__cell {
    width: size(280px);
    border: 2px solid $color-whisper;

    text-align: center;

    padding: size(16px);

    @include viewport--md {
      width: auto;
      min-width: 157px;
    }

    @include viewport--sm {
      min-width: auto;
      padding: 12px;
      border: 1px solid $color-whisper;
    }

    svg {
      width: size(32px);
      height: size(32px);

      margin-top: size(5px);

      @include viewport--sm {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__row-title {
    text-align: left;
    border: 2px solid $color-whisper;
    padding: size(16px) size(32px) size(16px) size(16px);

    @include viewport--ml {
      min-width: 280px;
    }

    @include viewport--md {
      min-width: auto;
      width: 250px;
      padding: 8px 12px 8px 8px;
    }

    @include viewport--sm {
      text-align: center;
      margin: 0 auto;

      padding: 12px 16px;
    }

    .rates-table__tooltip {
      margin-left: size(2px);
    }
  }

  &__row-wrap {
    @include viewport--sm {
      max-width: 242px;
      margin: 0 auto;
    }
  }

  &__head-title {
    border: 2px solid $color-whisper;
    border-top: none;
    padding: size(16px);

    vertical-align: top;

    @include viewport--md {
      padding: 12px 8px;
    }

    @include viewport--sm {
      padding: 12px 1px;
    }

    .link {
      @include text20;
      color: $color-black;
      border-color: $color-black;

      @include viewport--md {
        font-size: 14px;
        line-height: 100%;
      }

      &:hover,
      &:focus-visible {
        color: $color-neon-blue;
        color: var(--link-color);

        border-color: $color-neon-blue;
        border-color: var(--link-color);
      }
    }
  }

  &__title-cell {
    @include text20;

    font-weight: 400;

    @include viewport--sm {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  &__price-wrap {
    width: 100%;

    @include viewport--sm {
      padding: 0 12px;
    }
  }

  &__price {
    margin: size(8px) 0 0;
    font-size: size(16px);
    font-weight: 400;
    line-height: 100%;

    letter-spacing: 0.02em;

    opacity: 0.72;

    @include viewport--md {
      font-size: 14px;
      margin-top: 8px;
    }

    @include viewport--sm {
      font-size: 12px;
    }
  }

  &__price-text {
    display: inline-block;
    font-size: size(12px);
    font-weight: 400;
    line-height: 100%;
    max-width: size(200px);

    margin-top: size(5px);

    @include viewport--sm {
      font-size: 10px;
    }
  }

  &__row-title-text {
    display: inline;
    margin: 0;
    font-size: size(16px);
    font-weight: 400;
    line-height: 100%;

    letter-spacing: 0.02em;

    opacity: 0.72;

    @include viewport--sm {
      font-size: 14px;
      opacity: 1;
    }
  }
}
