.dino {
  display: flex;
  align-items: center;

  svg {
    fill: none;
    fill: var(--bg-color);
    transition: fill $default-transition-settings-06, color $default-transition-settings-06;
  }
}

html.dark {
  .dino {
    svg {
      color: $color-white;
    }
  }
}
