.arda .section-title {
  margin-bottom: 0;
}

.arda__description {
  @include text20;
  margin: size(50px) 0 0;

  @include viewport--md {
    margin-top: 30px;
  }
}

.arda__link {
  display: inline-flex;
  max-width: 100%;
  margin-top: size(90px);
  color: $color-white;
  transition: color $default-transition-settings;

  @include viewport--md {
    margin-top: 50px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      color: $color-torch-red;
    }
  }

  svg {
    width: size(350px);
    height: auto;
  }
}
