.intro {
  padding-top: size(78px);
  background-color: transparent;

  &--react {
    .intro__for {
      @include viewport--md {
        padding-right: 220px;
      }

      @include viewport--sm {
        padding-right: 0;
      }
    }
  }

  &--results {
    overflow: hidden;

    .ligozavr {
      margin-left: size(60px);

      @include viewport--md {
        margin-left: 0;
      }
    }

    .intro__what-description {
      font-size: size(24px);

      @include viewport--md {
        font-size: 24px;
        line-height: 1.2;
      }

      @include viewport--sm {
        font-size: 18px;
        line-height: 24px;
      }

      span {
        font-weight: 600;
      }
    }

    .intro__for {
      @include viewport--md {
        max-width: 70%;
      }

      @include viewport--sm {
        max-width: 100%;
      }
    }
  }

  @include viewport--md {
    padding-top: 52px;
  }
}

.intro__row {
  display: flex;
  margin: 0 size(-17px);

  @include viewport--md {
    display: block;
  }
}

.intro__col {
  padding: 0 size(17px);
  flex-grow: 0;
  flex-shrink: 0;
}

.intro__col--right {
  width: 33.333333%;
  max-width: 33.333333%;

  @include viewport--md {
    width: 100%;
    max-width: 100%;
  }
}

.intro__col--left {
  width: 66.666666%;
  max-width: 66.666666%;

  @include viewport--md {
    width: 100%;
    max-width: 100%;
  }
}

.intro__head {
  @include section-pb;
  position: relative;
  padding-top: size(80px);
  min-height: size(710px);

  display: flex;
  align-items: center;

  .intro--small & {
    padding-top: size(30px);

    @include viewport--md {
      padding-top: 0;
    }
  }

  .intro--react & {
    padding-top: size(130px);

    @include viewport--md {
      padding-top: 0;
    }
  }

  @media (max-width: 1024px) {
    min-height: 10px;
  }

  @include viewport--md {
    padding-top: 0;
  }
}

.intro__head .intro__row {
  align-items: flex-end;
  position: relative;
  z-index: 2;

  @include viewport--sm {
    padding-top: 275px;
  }
}

.intro__head .page-title {
  max-width: 92%;

  @include viewport--md {
    margin-bottom: 48px;
    max-width: 500px;
  }

  @include viewport--sm {
    margin-bottom: 20px;
  }
}

.intro__dino.ligozavr {
  @include viewport--md {
    position: absolute;
    right: 105px;
    top: 220px;
  }

  @include viewport--sm {
    right: auto;
    left: 115px;
    top: 200px;
  }

  .intro--small & {
    @include viewport--md {
      top: 220px;
    }

    @include viewport--sm {
      top: 200px;
    }
  }
}

.intro .butterfly {
  @include viewport--md {
    display: none;
  }
}

.intro__for {
  @include text30;
  margin: size(28px) 0 size(10px);

  @include viewport--sm {
    margin: 0;
  }
}

.intro__what {
  @include section-pb;

  &--results {
    padding-bottom: size(50px);

    .intro__feature-ic {
      top: 0;
    }
  }
}

.intro__what-top {
  margin-bottom: size(65px);
}

.intro__what .intro__col--left {
  position: relative;
  padding-bottom: size(50px);

  @include viewport--md {
    max-width: 100%;
    padding-bottom: 0;
  }
}

.intro__what .section-title {
  padding-right: size(70px);
  max-width: 71%;
  margin-bottom: size(53px);

  @media (max-width: 1199px) {
    max-width: 448px;
    padding-right: 0;
  }

  @include viewport--md {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 48px;
  }

  @include viewport--sm {
    margin-bottom: 32px;
  }
}

.intro__what-description {
  padding-right: size(70px);
  max-width: 84%;

  @include text30;

  @media (max-width: 1199px) {
    max-width: 544px;
    padding-right: 0;
  }

  @include viewport--md {
    padding-right: 0;
    margin-bottom: 50px;
    max-width: 620px;
  }

  @include viewport--sm {
    margin-bottom: 30px;
    max-width: 100%;
  }
}

.intro__what-ic {
  position: absolute;
  bottom: 0;
  left: size(17px);
  width: size(12px);
  height: size(22px);

  svg {
    width: size(12px);
    height: size(22px);
    color: var(--text-color);
  }

  @include viewport--md {
    display: none;
  }
}

.intro__features {
  list-style: none;
  padding: 0;
  margin: 0;

  @include viewport--md {
    padding-left: 50px;
  }
}

.intro__feature {
  position: relative;
  margin-bottom: size(32px);

  &:last-child {
    margin-bottom: 0;
  }
}

.intro__feature-ic {
  position: absolute;
  left: size(-50px);
  top: size(7px);
  width: size(32px);
  height: size(32px);
}

.intro__feature-ic svg {
  width: size(32px);
  height: size(32px);
  transition: color $default-transition-settings;
}

.intro__feature--small svg {
  color: $color-neon-blue;
  color: var(--btn-bg-color);
}

.intro__feature--big svg {
  color: $color-vivid-violet;
  color: var(--btn-violet-bg-color);
}

.intro__feature--front svg {
  color: $color-torch-red;
  color: var(--btn-red-bg-color);
}

.intro__feature-title {
  @include text20;

  margin: 0 0 size(12px);
  max-width: 77%;

  @include viewport--md {
    max-width: 270px;
  }
}

.intro__feature-description {
  margin: 0;
  opacity: 0.72;
}

.intro__info {
  @include lightning-bg;
  margin-bottom: size(72px);
  border: 2px solid $color-black;
  border-bottom: none;

  html.dark & {
    border-color: $color-charcoal;
  }
}

.intro__info-top {
  padding: size(64px);

    @include viewport--md {
      padding: 32px;
    }

    @include viewport--sm {
      padding: 15px;
    }
}

.intro__info-text {
  margin: 0;

  @include text30;
}

.intro__info-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 100%;
  border-top: 2px solid $color-black;
  border-bottom: 2px solid $color-black;

  color: $color-default-white;

  background-color: var(--btn-bg-color);

  padding: size(11px);

  transition: background-color $default-transition-settings;

  html.dark & {
    border-color: $color-charcoal;
  }

  svg {
    width: size(24px);
    height: size(24px);

    transform: rotate(180deg);
  }
}

.intro__services {
  list-style: none;
  margin: 0 size(-17px) size(-34px);
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  @include viewport--md {
    margin: 0 -12px -24px;
  }

  @include viewport--sm {
    margin: 0 -8px -16px;
  }
}

.intro__service {
  margin-bottom: size(34px);
  display: flex;

  padding: 0 size(17px);
  flex-grow: 0;
  flex-shrink: 0;

  width: 33.333333%;
  max-width: 33.333333%;

  @include viewport--md {
    width: 50%;
    max-width: 50%;
    padding: 0 12px;
    margin-bottom: 24px;
  }

  @include viewport--sm {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
    padding: 0 8px;
  }
}

.intro__pricing {
  margin-bottom: size(72px);
}
