.logo {
  display: flex;
  color: $color-black;

  transition: color $default-transition-settings;

  .board {
    color: $color-neon-blue;
    transition: color $default-transition-settings;
  }

  html.dark & {
    color: $color-white;

    .board {
      color: $color-torch-red;
    }
  }
}
