// Colors
//---------------------------------
// https://www.htmlcsscolor.com/hex/334482

// Default

$color-default-black:             #000000;
$color-default-white:             #ffffff;
$color-transparent:               rgba(255, 255, 255, 0);

// Base

$color-black:                     #0b0b0b;
$color-black--16a:                rgba($color-black, 0.16);
$color-black--72a:                rgba($color-black, 0.72);
$color-white:                     #ffffff;
$color-white--16a:                rgba($color-white, 0.16);
$color-white--72a:                rgba($color-white, 0.72);
$color-snow:                      #fafafa;
$color-snow-2:                    #fbfbfb;
$color-neon-blue:                 #2c39f2;
$color-neon-blue--06a:            rgba($color-neon-blue, 0.6);
$color-quartz:                    #dadcfa;
$color-torch-red:                 #ff1553;
$color-torch-red--06:             rgba($color-torch-red, 0.6);
$color-alizarin:                  #e01f26;
$color-mona-lisa:                 #ff9898;
$color-bittersweet:               #fa5f5f;
$color-vivid-violet:              #9627a3;
$color-vivid-violet-2:            #861f93;
$color-deep-magenta:              #be10d2;
$color-white-smoke:               #f5f5f5;
$color-white-smoke-2:             #f2f2f2;
$color-white-smoke-3:             #efefef;
$color-nero:                      #292929;
$color-nero-2:                    #1c1c1c;
$color-charcoal:                  #4a4a4a;
$color-charcoal-2:                #424242;
$color-night-rider:               #2b2b2b;
$color-lime-green:                #21ce1d;
$color-shamrock:                  #1ecd97;
$color-eclipse:                   #393939;
$color-eclipse-light:             #373737;
$color-whisper:                   #e6e6e6;
$color-amaranth:                  #e21f53;
$color-midnight-express:          #172240;
$color-deep-cerise:               #e72e8b;
$color-pacific-blue:              #00a0df;
$color-pearl-lusta:               #eee0c6;
$color-bleach-white:              #f2e4cb;
$color-zambezi:                   #5e5e5e;
$color-zambezi-2:                 #5f5f5f;
$color-deep-lilac:                #a64fb1;
$color-perfume:                   #bd9cec;
$color-shark:                     #32353e;
$color-very-light-grey:           #cecece;
$color-white-smoke:               #f8f8f8;
$color-white-smoke-2:             #f7f7f7;
$color-matterhorn:                #4e4e4e;
$color-razzmatazz:                #ff1053;
$color-dim-gray:                  #707070;


// Gradient

$black-to-right:                    linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);

// Typography
//---------------------------------

$font-family--neue-machina:                     "Neue Machina", "Arial", sans-serif;
$font-family--roboto-mono:                      "Roboto Mono", "Arial", sans-serif;

// Animation
//---------------------------------

$default-transition-settings:       0.3s ease;
$default-transition-settings-04:    0.4s ease;
$default-transition-settings-05:    0.5s ease;
$default-transition-settings-06:    0.6s ease;
$modal-transition-settings:         0.6s ease;
$standard-transition-settings:      0.3s ease;
$default-transition:                ease;

// Viewports
//---------------------------------

$viewport--sm:                      320px;
$viewport--480:                     480px;
$viewport--md:                      768px;
$viewport--lg:                      1024px;
$viewport--ml:                      1366px;
$viewport--xl:                      1440px;
$viewport--resize:                  1600px;
$viewport--xxl:                     1920px;

// Retina
//---------------------------------

$retina-dpi:                        144dpi;
$retina-dppx:                       1.5dppx;


// changing theme-colors

:root {
  /* changing variables */
  --text-color: #{$color-black};
  --text-color-2: #{$color-nero};
  --text-color-72a: #{$color-black--72a};
  --bg-color: #{$color-snow};
  --bg-color-accent: #{$color-very-light-grey};
  --bg-blog-color: #{$color-white-smoke-3};
  --shadow-color: #{$color-black--16a};
  --title-color-light-bg: #{$color-neon-blue};
  --title-color-coloured-bg: #{$color-default-white};
  --link-color: #{$color-neon-blue};
  --btn-bg-color: #{$color-neon-blue};
  --btn-bg-color-hover: #{$color-torch-red};
  --btn-text-color-hover: #{$color-white};

  --btn-violet-bg-color: #{$color-vivid-violet};
  --btn-violet-bg-color-hover: #{$color-deep-magenta};

  --btn-red-bg-color: #{$color-torch-red};
  --btn-red-bg-color-hover: #{$color-neon-blue};

  --btn-rounded-white-bg-color: #{$color-white};
  --btn-rounded-white-bg-color-hover: #{$color-white};

  --radio-base-color: #{$color-black};
  --radio-active-color: #{$color-neon-blue};
  --outline-color: #{$color-neon-blue--06a};

  --text-content-contrast-color: #{$color-neon-blue};
  --header-fixed-border: #{$color-quartz};

  --error-color: #{$color-torch-red};

  --map-color: #{$color-vivid-violet-2};

  --text-color-opacity: #{rgba($color-black, 0.48)};
  --sofia-link-hover: #{$color-torch-red};

  --grey-border-color: #{$color-whisper};
  --grey-border-color-2: #{$color-whisper};
  --icon-border-color: #{$color-default-black};
  --white-bg-color: #{$color-default-white};
  --btn-bg-color-opcity: #{rgba($color-neon-blue, 0.1)};
  --btn-bg-color-05: #{rgba($color-neon-blue, 0.05)};
  --btn-bg-color-25: #{rgba($color-neon-blue, 0.25)};
  --btn-bg-color-60: #{rgba($color-neon-blue, 0.6)};

  --white-accent: #{$color-default-white};
}


html.dark {
  --text-color: #{$color-white};
  --text-color-2: #{$color-white};
  --text-color-72a: #{$color-white--72a};
  --bg-color: #{$color-nero};
  --bg-color-accent: #{$color-nero-2};
  --bg-blog-color: #{$color-nero};
  --shadow-color: #{$color-white--16a};
  --title-color-light-bg: #{$color-default-white};
  --title-color-coloured-bg: #{$color-default-white};
  --link-color: #{$color-white};
  --btn-bg-color: #{$color-torch-red};
  --btn-bg-color-hover: #{$color-white};
  --btn-text-color-hover: #{$color-nero};

  --btn-violet-bg-color: #{$color-torch-red};
  --btn-violet-bg-color-hover: #{$color-white};

  --btn-red-bg-color: #{$color-torch-red};
  --btn-red-bg-color-hover: #{$color-white};

  --btn-rounded-white-bg-color: #{$color-transparent};
  --btn-rounded-white-bg-color-hover: #{$color-white};

  --radio-base-color: #{$color-white};
  --radio-active-color: #{$color-torch-red};

  --outline-color: #{$color-torch-red--06};

  --text-content-contrast-color: #{$color-torch-red};
  --header-fixed-border: #{$color-charcoal};

  --error-color: #{$color-bittersweet};

  --map-color: #{$color-nero};

  --text-color-opacity: #{rgba($color-white, 0.48)};
  --sofia-link-hover: #{$color-white};

  --grey-border-color: #{rgba($color-whisper, 0.6)};
  --grey-border-color-2: #{rgba($color-whisper, 0.3)};
  --icon-border-color: #{$color-zambezi-2};
  --white-bg-color: #{$color-nero};
  --btn-bg-color-opcity: #{rgba($color-torch-red, 0.1)};
  --btn-bg-color-05: #{rgba($color-torch-red, 0.05)};
  --btn-bg-color-25: #{rgba($color-torch-red, 0.25)};
  --btn-bg-color-60: #{rgba($color-torch-red, 0.6)};

  --white-accent: #{$color-eclipse};
}
