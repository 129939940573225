.review-item {
  display: flex;
  flex-direction: column;
  background-color: $color-white-smoke-2;

  padding: size(24px);
  height: 100%;

  color: $color-black;
  color: var(--text-color);

  transition: transform $default-transition-settings, background-color $default-transition-settings;

  will-change: transform;

  @include viewport--md {
    padding: 20px;
  }

  // @media (hover: hover), screen and (min-width: 0\0) {
  //   &:hover,
  //   &:focus-visible {
  //     transform: scale(1.05);
  //   }
  // }

  html.dark & {
    background-color: $color-eclipse;
  }

  &__img {
    max-width: size(176px);
    margin: 0 auto size(56px);

    box-shadow: 0px 40px 50px -24px rgba(0, 0, 0, 0.2);

    @include viewport--md {
      margin-bottom: 24px;
    }

    @include viewport--sm {
      margin-bottom: 16px;
    }


    img {
      width: 100%;
      object-fit: cover;
      user-select: none;
      pointer-events: none;
    }
  }

  &__year {
    font-size: size(14px);
    line-height: 100%;

    opacity: 0.32;

    margin: 0 0 size(32px);
  }

  &__company {
    @include text20;

    margin: auto 0 0;

    @media (max-width: 599px) {
      text-align: center;
    }
  }
}
