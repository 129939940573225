@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Neue Machina";

  font-display: swap;
  src:
    url("../fonts/neuemachina-light.woff2") format("woff2"),
    url("../fonts/neuemachina-light.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Neue Machina";

  font-display: swap;
  src:
    url("../fonts/neuemachina-regular.woff2") format("woff2"),
    url("../fonts/neuemachina-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Neue Machina";

  font-display: swap;
  src:
    url("../fonts/neuemachina-medium.woff2") format("woff2"),
    url("../fonts/neuemachina-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: "Neue Machina";

  font-display: swap;
  src:
    url("../fonts/neuemachina-ultrabold.woff2") format("woff2"),
    url("../fonts/neuemachina-ultrabold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto Mono";

  font-display: swap;
  src:
    url("../fonts/roboto-mono-regular.woff2") format("woff2"),
    url("../fonts/roboto-mono-regular.woff") format("woff");
}
