.configurator-result {
  @include viewport--md {
    padding-top: 0;
  }
}

.configurator-result__inner {
  @include lightning-bg;
  padding: size(28px) size(30px) size(32px);
  letter-spacing: 0.02em;
  border: size(2px) solid $color-black--16a;

  html.dark & {
    border-color: $color-white;
  }

  @include viewport--md {
    border-width: 2px;
  }

  @include viewport--sm {
    padding: 22px 14px 22px;
  }
}

.configurator-result__recommended-rates {
  position: relative;
  margin: 0;
  height: size(300px);
  padding-left: 0;
  list-style: none;
  transition: height $default-transition-settings-05;
}

.configurator-result__discuss {
  margin-top: size(26px);
  margin-bottom: 0;
  width: 100%;

  @include viewport--sm {
    margin-top: 18px;
  }
}

.configurator-result__disclaimer {
  @include text12-16;
  margin-top: size(14px);
  margin-bottom: 0;
  opacity: 0.48;
}
