.page {
  z-index: 1;

  padding-top: size(78px);

  @include viewport--md {
    padding-top: 78px;
  }

  @include viewport--sm {
    padding-top: 52px;
  }

  &--no-padding {
    padding-top: 0;
  }

  &--hr {
    padding-top: size(72px);

    @include viewport--md {
      padding-top: 72px;
    }

    @include viewport--sm {
      padding-top: 56px;
    }
  }
}
