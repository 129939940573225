.vacancy {
  border: size(2px) solid $color-black--16a;
  background-color: $color-snow;
  background-color: var(--bg-color);

  transition: border-color $default-transition-settings, background-color $default-transition-settings;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      border-color: $color-black;
    }
  }

  &.active {
    border-color: $color-black;

    .vacancy__title {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  html.dark & {
    border-color: $color-white--16a;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        border-color: $color-white;
      }
    }

    &.active {
      border-color: $color-white;
    }
  }

  @include viewport--md {
    border-width: 2px;
  }
}

.vacancy__title {
  @include reset-btn;
  @include text30;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: size(27px) size(30px);

  svg {
    flex-shrink: 0;
    margin-left: size(8px);
    display: flex;
    position: relative;
    top: size(1px);
    width: size(24px);
    height: size(24px);
    transition: transform $default-transition-settings, color $default-transition-settings, opacity $default-transition-settings;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   bottom: 0;
  //   width: calc(100% - 3.875rem);
  //   height: 2px;
  //   background-color: transparent;
  //   transition: background-color $default-transition-settings;

  //   @include viewport--md {
  //     width: calc(100% - 44px);
  //   }

  //   @include viewport--sm {
  //     width: calc(100% - 28px);
  //   }
  // }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      svg {
        color: $color-neon-blue;
      }
    }
  }

  @include viewport--md {
    padding: 20px 22px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @include viewport--sm {
    padding: 12px 14px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  html.dark & {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        svg {
          color: $color-white;
          opacity: 0.5;
        }
      }
    }
  }
}

.vacancy__content {
  overflow: hidden;
  transition: max-height $default-transition-settings-06;
}

.vacancy__aside {
  grid-column: 2/3;

  margin-top: size(32px);
  margin-right: size(32px);

  @include viewport--md {
    grid-row: 2/3;
    grid-column: 1/2;

    margin: 0;

    width: 100%;
  }
}

.vacancy__text {
  padding: size(34px) size(30px) size(32px);
  position: relative;

  & > *:not(.text-content__table) {
    max-width: none;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: size(30px);
    right: size(30px);
    height: size(2px);
    background-color: $color-black;
    background-color: var(--text-color);

    transition: background-color $default-transition-settings-06;
  }

  h3 {
    margin-top: 2em;
    margin-bottom: 0.2em;

    @include viewport--md {
      margin-top: 1em;
      margin-bottom: 0.52em;
    }
  }

  ul {
    margin-top: 0.5em;
    margin-bottom: 2.5em;

    @include viewport--sm {
      margin-bottom: 1.6em;

      li {
        margin-bottom: 0.58em;
      }
    }
  }

  @include viewport--md {
    padding: 25px 22px 24px;

    &::before {
      left: 22px;
      right: 22px;
      height: 2px;
    }
  }

  @include viewport--sm {
    padding: 19px 14px 15px;

    &::before {
      left: 13px;
      right: 13px;
    }
  }
}
