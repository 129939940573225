.marquee {
  width: 110%;
  display: flex;

  overflow: hidden;

  &__inner {
    display: flex;
  }

  &__item {
    display: flex;
  }

  &[data-reverse="true"] {
    flex-direction: row-reverse;
  }
}
