.price-features {
  @include lightning-bg-blue;

  margin-top: size(144px);
  margin-bottom: size(144px);

  padding-top: size(100px);
  padding-bottom: size(100px);

  @media (max-width: 1024px) {
    margin-top: 80px;
    margin-bottom: 80px;

    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include viewport--sm {
    margin-top: 54px;
    margin-bottom: 54px;

    padding-top: 54px;
    padding-bottom: 54px;
  }

  .section-title {
    font-size: size(60px);
    margin-bottom: size(64px);

    @include viewport--md {
      margin-bottom: 48px;
      font-size: 56px;
    }

    @include viewport--sm {
      margin-bottom: 32px;
      font-size: 32px;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;

    margin-right: -#{size(20px)};

    @media (max-width: 1024px) {
      flex-direction: column;
      margin-right: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: calc(33% - #{size(20px)});
    margin-right: size(20px);

    padding: size(32px);

    border: size(1px) solid $color-white;

    @media (max-width: 1024px) {
      margin-bottom: 20px;
      margin-right: 0;
      width: 100%;
      padding: 20px;
    }
  }

  &__text {
    margin: 0 0 size(16px);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__price {
    margin-top: auto;
    margin-bottom: 0;
  }
}
