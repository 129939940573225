.activity-article {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      .activity-article__img {
        &::after {
          background-color: $color-vivid-violet;
        }
      }
    }
  }

  html.dark & {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        .activity-article__img {
          &::after {
            background-color: $color-torch-red;
          }
        }
      }
    }
  }
}

.activity-article__img {
  position: relative;
  width: 100%;
  margin-bottom: size(29px);
  border: size(2px) solid $color-black;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    opacity: 0.9;

    transition: background-color $default-transition-settings;
  }

  @include viewport--md {
    margin-bottom: 24px;
  }

  @include viewport--sm {
    margin-bottom: 16px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $color-black--16a;
  }
}

.activity-article--telegram .activity-article__img::before {
  padding-bottom: 100%;
}

.activity-article__title {
  @include text20;
}
