.join {
  @include section-paddings;

  .section-title {
    max-width: size(860px);
  }
}

.join__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.join__vacancies {
  width: 736 / 1120 * 100%;

  @include viewport--md {
    width: 100%;
  }
}

.join__aside {
  width: 352 / 1120 * 100%;

  @include viewport--md {
    margin-top: 38px;
    width: 100%;
  }

  @include viewport--sm {
    margin-top: 32px;
  }
}

.join__advantages {
  @include lightning-bg;
  margin-bottom: size(16px);
  padding: size(27px) size(30px) size(33px);
  letter-spacing: 0.02em;
  border: size(2px) solid $color-black--16a;

  html.dark & {
    border-color: $color-white--16a;
  }

  @include viewport--md {
    margin-bottom: 38px;
    padding: 24px 22px 27px;
    border-width: 2px;
  }

  @include viewport--sm {
    margin-bottom: 32px;
    padding: 20px 14px 22px;
  }
}

.join__advantages-title {
  @include text20;
  margin-top: 0;
  margin-bottom: size(24px);

  @include viewport--sm {
    margin-bottom: 16px;
  }
}

.join__advantages-list-title {
  @include text20;
  margin-top: 0;
  margin-bottom: 0.45em;

  @include viewport--sm {
    margin-bottom: 0.5em;
  }
}

.join__contacts {
  @include text20;
  padding: size(27px) size(30px) size(33px);
  border: size(2px) solid $color-neon-blue;
  transition: border-color $default-transition-settings;

  &.hover {
    border-color: $color-torch-red;
  }

  html.dark & {
    border-color: $color-torch-red;

    &.hover {
      border-color: $color-white;
    }
  }

  @include viewport--md {
    padding: 24px 22px 25px;
    border-width: 2px;
  }

  @include viewport--sm {
    padding: 20px 14px 21px;
  }
}

.join__mail {
  html.dark & {
    color: $color-torch-red;
    border-color: $color-torch-red;
  }
}
