.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba($color-default-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include viewport--sm {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;

    @include viewport--sm {
      width: 100%;
      padding: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: size(15px);
    right: size(15px);

    transition: opacity $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        opacity: 0.6;
      }
    }

    svg {
      color: $color-white;
      width: size(20px);
      height: size(20px);
    }

    @include viewport--md {
      top: 15px;
      right: 15px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include viewport--sm {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }


}

.modal--review {
  .modal__wrapper {
    padding: 0;
    background-color: $color-white-smoke;

    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
    height: 100vh;

    @include viewport--md {
      min-height: 100%;
      height: 100%;
    }

    html.dark & {
      background-color: $color-eclipse;
    }
  }

  .modal__content {
    width: 100%;
    height: 100%;

    padding: size(40px);

    display: flex;
    align-items: center;

    @include viewport--md {
      padding: 48px 16px;
    }

    img {
      margin: 0 auto;
      width: auto;
      max-height: 100%;

      @include viewport--md {
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  .modal__close-btn {
    top: size(20px);
    right: size(20px);

    svg {
      color: $color-black;
      color: var(--text-color);
    }
  }
}

.modal--video {
  .modal__wrapper {
    padding: size(80px);

    @include viewport--sm {
      padding: 48px 16px;
    }
  }

  .modal__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .modal__close-btn {
    top: size(-36px);
    right: 0;
  }

  .yt-api,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: var(--bg-color-accent);
  }
}

.modal--position,
.modal--production,
.modal--testing,
.modal--customer-service {
  .modal__wrapper {
    padding: 0;
    // flex-shrink: 0;
    align-items: flex-start;
    height: 100%;
  }

  .modal__content {
    width: 100%;
    min-height: 100%;

    padding: size(60px) size(160px);

    background-color: $color-snow-2;

    html.dark & {
      background-color: $color-nero;
    }

    @include viewport--md {
      padding: 90px 74px 130px;
    }

    @include viewport--sm {
      padding: 32px 16px 130px;
    }
  }

  .modal__close-btn {
    top: size(42px);
    right: size(42px);

    @include viewport--sm {
      top: 16px;
      right: 16px;
    }

    svg {
      color: $color-black;
      color: var(--text-color);
    }
  }
}

.modal--testing {
  .modal__close-btn {
    svg {
      color: $color-default-white;
    }
  }

  .modal__content {
    background-color: $color-midnight-express;
    background-image: url('../img/bg/testing-modal-bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    @include retina {
      background-image: url('../img/bg/testing-modal-bg@2x.png');
    }
  }
}

.modal--position {
  .modal__close-btn {
    top: size(42px);
    right: size(42px);

    @include viewport--sm {
      top: 16px;
      right: 16px;
    }
  }

  .modal__content {
    @media(max-width: 1200px) {
      padding: 104px 60px 120px;
    }

    @include viewport--md {
      padding: 90px 74px 130px;
    }

    @include viewport--sm {
      padding: 32px 16px 130px;
    }
  }
}
