.pricing {
  padding-bottom: size(144px);

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @include viewport--md {
    padding-bottom: 54px;
  }

  &--price {
    @include section-pt;
    padding-bottom: 0;
    padding-top: size(78px);

    @include viewport--md {
      padding-top: 50px;
    }

    @include viewport--md {
      padding-top: 25px;
    }

    .pricing__item {
      padding: 0;

      transition: transform 0.1s ease;
      transform-style: preserve-3d;
      will-change: transform;

    }


  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;

    gap: size(16px);
    justify-content: space-between;

    @include viewport--md {
      grid-template-columns: 1fr;
    }
  }

  &__item-wrapper {
    width: 100%;

    @include viewport--md {
      width: 100%;
    }
  }

  &__item {
    @include lightning-bg;

    display: flex;
    flex-direction: column;
    height: 100%;

    padding: size(32px);

    border: 2px solid $color-black;

    @include viewport--md {
      padding: 15px;
    }

    html.dark & {
      border-color: $color-charcoal;
    }
  }

  &__title {
    margin: 0 0 size(16px);

    @include text20;

    &--price {
      span {
        display: block;
        color: $color-neon-blue;
        color: var(--btn-bg-color);
      }
    }
  }

  &__text {
    margin: 0 0 size(24px);

    @include text16-24;

    opacity: 0.82;

    &:last-child {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  &__price-wrapper {
    display: flex;
    margin-top: auto;

    @include viewport--md {
      flex-wrap: wrap;
    }
  }

  &__price {
    @include text16-24;

    color: $color-neon-blue;
    color: var(--btn-bg-color);

    margin: 0;

    @include viewport--md {
      width: 100%;
      font-size: 18px;
    }

    &:first-child {
      margin-right: size(32px);

      @include viewport--md {
        margin-right: 16px;
      }

      @include viewport--md {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
    }
  }

  &__btn {
    display: block;
    padding: size(20px) size(40px);
    max-width: size(250px);
    margin: size(64px) auto 0;
    border: none;

    @include viewport--md {
      margin-top: 32px;
    }
  }

  &__link {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: $color-default-black;

    padding: size(32px);

    html.dark & {
      color: $color-default-white;
    }

    @include viewport--md {
      padding: 15px;
    }
  }
}
