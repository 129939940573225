.team-member {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 0 size(2px);

  @include viewport--md {
    padding: 0;
  }

  @media (max-width: 499px) {
    width: 100%;
    display: grid;
    grid-template-areas:
      'photo name'
      'photo post';
    grid-template-columns: min-content 1fr;
    text-align: left;
    column-gap: 5%;
    row-gap: 6px;
  }
}

.team-member__img {
  position: relative;
  margin-bottom: size(29px);
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;

  @include viewport--sm {
    margin-bottom: 20px;
  }

  @media (max-width: 499px) {
    width: 90px;
    height: 90px;
    margin: 0;
    grid-row: span 2;
    grid-area: photo;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black--16a;
  }
}

.team-member__name {
  @include text20;
  margin-top: 0;
  margin-bottom: size(9px);

  @media (max-width: 499px) {
    margin: 0;
    align-self: end;
    grid-area: name;
  }
}

.team-member__post {
  margin: 0;
  line-height: 1.5;
  opacity: 0.72;
  max-width: 100%;

  @media (max-width: 499px) {
    align-self: start;
    grid-area: post;
  }
}
