.recommended-rate {
  //--rate-contrast-color: #{$color-neon-blue};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @include viewport--md {
    flex-direction: row;
    flex-wrap: wrap;
  }

  opacity: 0;
  visibility: hidden;

  transition: opacity $default-transition-settings-05, visibility $default-transition-settings-05;

  @include viewport--sm {
    margin-top: -14px;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.recommended-rate--small {
  .recommended-rate__img {
    svg {
      height: size(38px);
    }
  }
}

.recommended-rate--big {
  //--rate-contrast-color: #{$color-vivid-violet};

  .recommended-rate__name,
  .recommended-rate__img svg {
    color: $color-vivid-violet;
  }
}

.recommended-rate--front {
  //--rate-contrast-color: #{$color-torch-red};

  .recommended-rate__name,
  .recommended-rate__img svg {
    color: $color-torch-red;
  }

  .recommended-rate__img {
    margin-left: size(1px);

    @include viewport--md {
      margin-left: 1px;
    }
  }
}

//.recommended-rate__name,
//.recommended-rate__img {
//  color: var(--rate-contrast-color);
//}

.recommended-rate__name,
.recommended-rate__img svg {
  color: $color-neon-blue;
}

.recommended-rate__title {
  @include text20;
  margin-top: 0;
  margin-bottom: size(8px);

  @include viewport--md {
    margin-bottom: 0;
  }

  @include viewport--sm {
    margin-top: 14px;
    font-size: 18px;
    line-height: 24px;
  }
}

.recommended-rate__name {
  display: block;
  transition: color $default-transition-settings-06;
}

.recommended-rate__description {
  opacity: 0.72;

  p {
    margin-top: 0;
    margin-bottom: size(25px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include viewport--md {
    margin-top: 24px;
    width: 100%;

    p {
      margin-bottom: 20px;
    }
  }

  @include viewport--sm {
    margin-top: 18px;
  }
}

.recommended-rate__img {
  margin-bottom: size(10px);
  height: size(44px);

  @include viewport--md {
    margin-right: 32px;
    margin-bottom: 0;
  }

  @include viewport--sm {
    margin-top: 14px;
  }

  svg {
    width: size(45px);
    height: size(44px);
  }
}

html.dark {
  .recommended-rate {
    //--rate-contrast-color: #{$color-torch-red};
    .recommended-rate__name,
    .recommended-rate__img svg {
      color: $color-torch-red;
    }
  }
}
