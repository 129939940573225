.companies__content {
  @include section-paddings;
  @include lightning-bg-blue;
}

.companies__slider {
  min-width: calc(100% + #{size(430px)});
  margin-bottom: size(-20px);
  margin-left: -#{size(215px)};

  @include viewport--md {
    min-width: calc(100% + 430px);
    margin-left: -215px;
  }

  @include viewport--sm {
    min-width: calc(100% + 32px);
    margin-left: -16px;
  }
}
