.gradient-text {
  position: relative;
  margin-top: -80vh;
  display: flex;
  width: 100%;
  justify-content: center;
  height: calc(125 * var(--vh, 1vh));

  @include viewport--md {
    margin-top: 0;
    height: auto;
    padding-top: 44px;
  }

  &__sticky {
    position: relative;
    top: 45vh;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: size(10px);
    height: calc(100 * var(--vh, 1vh));

    @include viewport--md {
      position: static;
      height: auto
    }
  }

  h2 {
    margin: 0;
    max-width: size(560px);

    font-size: size(42px);
    font-weight: 500;
    line-height: size(44px);
    text-align: center;
    margin: 0;

    background: radial-gradient(246.74% 247.42% at 54% -20%, #b283df 17%, #684787 32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include viewport--md {
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
  }

  img {
    width: size(44px);
    height: size(44px);

    @include viewport--md {
      width: 24px;
      height: 24px;
    }
  }
}
