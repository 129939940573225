.ligozavr {
  position: relative;
}

@mixin move-animation($animationName, $animation-duration, $angle, $pos-x, $pos-y) {
  animation-duration: $animation-duration;
  animation-name: $animationName;

  @keyframes #{$animationName} {
    0% {
      opacity: 0;
      transform: translateX(size($pos-x)) translateY(size($pos-y)) rotate($angle) scale(0.9);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      transform: translateX(0) translateY(0) rotate($angle);
    }
  }
}

.ligozavr__ic svg {
  width: size(71px);
  height: size(85px);
}

.ligozavr__light {
  position: absolute;
  transition: color $default-transition-settings;
  opacity: 0;

  animation-timing-function: $default-transition;
  animation-fill-mode: forwards;

  @include viewport--md {
    animation: none;
    opacity: 1;
  }

  &--1 {
    width: size(79px);
    height: size(136px);
    left: size(-102px);
    bottom: size(52px);

    [data-animate].show & {
      @include move-animation(move-1, 0.7s, 0deg, 20px, 20px);

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }
  }

  &--2 {
    width: size(40px);
    height: size(69px);
    left: size(-17px);
    top: size(-119px);
    transform: rotate(20deg);

    [data-animate].show & {
      @include move-animation(move-2, 0.4s, 20deg, 10px, 30px);

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }
  }

  &--3 {
    width: size(101px);
    height: size(174px);
    left: size(60px);
    top: size(-201px);
    transform: rotate(59deg);

    [data-animate].show & {
      @include move-animation(move-3, 0.5s, 59deg, -20px, 30px);

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }
  }

  &--4 {
    width: size(40px);
    height: size(69px);
    left: size(103px);
    bottom: size(39px);
    transform: rotate(79deg);

    [data-animate].show & {
      @include move-animation(move-4, 0.6s, 79deg, -30px, 10px);

      @include viewport--md {
        animation: none;
        opacity: 1;
      }
    }
  }

  &--red {
    color: $color-torch-red;
  }

  &--violet {
    color: $color-vivid-violet;

    html.dark & {
      color: $color-white;
    }
  }

  &--blue {
    color: $color-neon-blue;

    html.dark & {
      color: $color-white;
    }
  }
}

.ligozavr__stroke {
  color: $color-black;
  color: var(--text-color);
  transition: color $default-transition-settings;
}

.ligozavr__bg {
  color: $color-white;
  transition: color $default-transition-settings;

  html.dark & {
    color: $color-nero;
  }
}

.ligozavr__changing-color-el {
  color: $color-neon-blue;
  transition: color $default-transition-settings;

  html.dark & {
    color: $color-torch-red;
  }
}

.ligozavr__changing-letter {
  color: $color-white;
  transition: color $default-transition-settings;

  html.dark & {
    color: $color-black;
  }
}

.ligozavr__speech {
  opacity: 0;
  animation-duration: 1s;
  animation-timing-function: $default-transition;
  animation-iteration-count: infinite;
  animation-name: speech-changing;
  animation-delay: 0.6s;

  @include viewport--md {
    animation: none;
    opacity: 1;
  }
}

@keyframes speech-changing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
