.awards {
  padding-bottom: size(66px);
  border-bottom: size(2px) solid $color-black--16a;
  transition: border-color $default-transition-settings;

  html.dark & {
    border-color: $color-white--16a;
  }

  @include viewport--md {
    padding-bottom: 42px;
    border-width: 2px;
  }
}

.awards__intro {
  margin-top: 0;
  margin-bottom: size(53px);
  max-width: size(736px);
  @include text30;

  @include viewport--md {
    margin-bottom: 44px;
  }

  @include viewport--sm {
    margin-bottom: 36px;
  }
}

.award__intro-color {
  color: $color-vivid-violet;

  transition: color $default-transition-settings;

  html.dark & {
    color: $color-torch-red;
  }
}

.awards__list {
  margin: 0 size(-16px) size(-32px);
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @include viewport--md {
    margin: 0 -12px -24px;
  }

  @media (max-width: 499px) {
    flex-direction: column;
    margin: 0 0 -24px;
  }
}

.awards__item {
  margin-bottom: size(32px);
  display: flex;

  padding: 0 size(16px);
  flex-grow: 0;
  flex-shrink: 0;

  width: 33.33333%;

  @include viewport--md {
    padding: 0 12px;
    margin-bottom: 24px;
  }

  @include viewport--sm {
    width: 50%;
    padding: 0 8px;
  }

  @media (max-width: 499px) {
    width: 100%;
    padding: 0;
  }
}
