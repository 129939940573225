.award {
  width: 100%;
  letter-spacing: 0.02em;
  color: $color-black;
  color: var(--text-color);
  transition: color $default-transition-settings-06;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus-visible {
      .award__title {
        color: var(--text-content-contrast-color);
      }
    }
  }
}

.award__frame {
  @include lightning-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  border: size(2px) solid var(--text-color);
  position: relative;
  padding-bottom: 69.5%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 75%;
    max-height: 75%;
  }

  @include viewport--md {
    border-width: 2px;
  }
}

.award__title {
  margin-top: size(28px);
  margin-bottom: 0;
  @include text20;
  transition: color $default-transition-settings;

  @include viewport--md {
    margin-top: 20px;
  }

  @include viewport--sm {
    margin-top: 16px;
  }
}

.award__description {
  margin-top: size(9px);
  margin-bottom: 0;
  opacity: 0.72;
}

.award--tagline {
  svg {
    width: size(239px);
    height: size(96px);
    fill: $color-alizarin;
    transition: fill $default-transition-settings-06;
  }

  html.dark & {
    svg {
      fill: $color-white;
    }
  }
}

.award--specia {
  svg {
    width: size(208px);
    height: size(33px);
  }
}

.award--lupa {
  svg {
    left: 48%;
    width: size(137px);
    height: size(165px);
  }
}
