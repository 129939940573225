.configurator-panel {
  display: none;

  @include viewport--md {
    z-index: 1;
    display: flex;
    align-items: baseline;
    position: fixed;
    top: 78px;
    left: 0;
    right: 0;
    padding: 11px 24px;

    opacity: 0;
    visibility: hidden;

    font-size: 20px;
    line-height: 1.2;

    background-color: $color-neon-blue;
    color: $color-white;

    transition: visibility $default-transition-settings-05, opacity $default-transition-settings-05, background-color $default-transition-settings-05;
  }

  @include viewport--sm {
    top: 52px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.configurator-panel--big {
  background-color: $color-vivid-violet;
}

.configurator-panel--front {
  background-color: $color-torch-red;
}

.configurator-panel__title {
  margin: 0 10px 0 0;
  font-weight: 400;
}

.configurator-panel__result {
  @include viewport--md {
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity $default-transition-settings;

    &.active {
      display: inline;
      opacity: 1;
    }
  }
}
