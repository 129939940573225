/* stylelint-disable declaration-no-important */

.postcard-share {
  .ya-share2__container {
    @include m-viewport--xl {
      font-size: size(13px) !important;
    }
  }

  .ya-share2__list {
    width: 100% !important;
  }

  .ya-share2__popup {
    width: 100% !important;
    z-index: 10160 !important;
  }

  .ya-share2__popup .ya-share2__item {
    @include m-viewport--xl {
      padding: size(2px) size(8px) !important;
    }
  }

  .ya-share2__popup .ya-share2__title {
    @include m-viewport--xl {
      margin-left: size(10px) !important;
      line-height: size(24px) !important;
    }
  }

  .ya-share2__item_more {
    width: 100% !important;
  }

  .ya-share2__link_more {
    width: 100% !important;
    flex-shrink: 0;
    display: inline-flex !important;
    justify-content: center !important;
    cursor: pointer !important;
    text-align: center !important;
    vertical-align: top !important;
    text-decoration: none !important;
    transition: background-color $default-transition-settings,
      color $default-transition-settings,
      border-color $default-transition-settings,
      opacity $default-transition-settings !important;
    position: relative !important;

    padding: size(18px) size(28px) size(20px) !important;
    background-color: var(--btn-bg-color-opcity) !important;

    border: size(2px) solid $color-transparent !important;
    border-radius: size(20px) !important;
    outline: none !important;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        background-color: var(--btn-bg-color-opcity) !important;
        border-color: $color-transparent !important;
        opacity: 0.6 !important;

        .ya-share2__title {
          color: var(--btn-bg-color) !important;
        }
      }
    }

    @include viewport--md {
      padding: 18px 22px 20px !important;
      border-width: 2px !important;
      border-radius: 25px !important;
    }

    .ya-share2__badge {
      display: none !important;
    }

    .ya-share2__title {
      color: var(--btn-bg-color) !important;
      font-weight: 500 !important;
      font-size: size(36px) !important;
      line-height: size(40px) !important;
      font-family: $font-family--neue-machina !important;
      letter-spacing: normal !important;
      transition: color $default-transition-settings !important;

      @include viewport--md {
        font-size: 36px !important;
        line-height: 40px !important;
      }

      @include viewport--sm {
        font-size: 22px !important;
        line-height: 24px !important;
      }
    }
  }

  .ya-share2__popup_direction_bottom {
    top: calc(100% + 10px) !important;
  }

  .ya-share2__item .ya-share2__badge {
    @include m-viewport--xl {
      border-radius: size(4px) !important;
    }
  }

  .ya-share2__item .ya-share2__icon {
    @include m-viewport--xl {
      height: size(24px) !important;
      width: size(24px) !important;
      background-size: size(24px) size(24px) !important;
    }

    &_copy {
      @include m-viewport--xl {
        background-size: size(16px) size(16px) !important;
      }
    }
  }

  &[disabled],
  &[inert] {
    .ya-share2__link_more {
      background-color: $color-whisper !important;
      border-color: $color-whisper !important;
      pointer-events: none !important;
    }

    .ya-share2__title {
      color: $color-default-white !important;
    }
  }
}
