.appeal {
  margin-top: size(69px);
  display: flex;
  align-items: center;

  @include viewport--md {
    margin-top: 60px;
  }

  @include viewport--sm {
    margin-top: 44px;
  }
}

.appeal__button {
  margin-right: size(100px);
  flex-shrink: 0;

  @include viewport--md {
    margin-right: 50px;
  }
}

.appeal__arrow {
  position: relative;
  margin-left: auto;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  svg {
    min-width: 100%;
    height: auto;
  }

  @include viewport--sm {
    display: none;
  }
}
