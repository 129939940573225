.companies-slider {
  overflow: hidden;
  white-space: nowrap;

  ul {
    display: flex;

    list-style: none;
    margin: 0;
    margin-right: -20px;
    padding: 0;

    transform: translate3d(-50%, 0, 0);
  }

  .swiper-wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.companies-slider__item {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: size(165px);
  height: size(120px);
  margin-right: 20px;

  transition: opacity 0.3s;
}

.companies-slider__item svg {
  width: size(140px);
  height: size(120px);

  color: $color-neon-blue;
  transition: color $default-transition-settings;

  html.dark & {
    color: $color-eclipse;
  }
}

.companies-slider__item--hr {
  height: size(110px);

  svg {
    color: $color-snow;
    opacity: 0.3;

    &:last-of-type {
      display: none;
      opacity: 0.6;
    }

    html.dark & {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: block;
      }
    }
  }

  // .companies-slider__link {
  //   color: $color-snow;
  //   opacity: 0.3;

  //   html.dark & {
  //     color: $color-eclipse;
  //     opacity: 0.6;
  //   }
  // }
}

// .companies-slider__link {
//   display: flex;
//   max-height: 100%;

//   color: $color-white;
//   transition: opacity $default-transition-settings;

//   @media (hover: hover), screen and (min-width: 0\0) {
//     &:hover {
//       opacity: 0.5;
//     }
//   }
// }
