.form {
  position: relative;

  form {
    transition: opacity $default-transition-settings, visibility $default-transition-settings;
  }

  .input-wrapper {
    margin-bottom: size(30px);

    @include viewport--sm {
      margin-bottom: 18px;
    }
  }

  &--main {
    form {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }

    .input-wrapper {
      width: 100%;

      &--main {
        width: calc(50% - 5px);

        &:first-of-type {
          margin-right: auto;
        }

        @include viewport--md {
          width: 100%;
        }

        label {
          white-space: nowrap;
        }
      }
    }
  }
}

.form--success {
  form {
    opacity: 0;
    visibility: hidden;
  }

  .form-success-message {
    opacity: 1;
    visibility: visible;
  }
}

.form--error {
  .form__error-message {
    opacity: 1;
    visibility: visible;
  }
}

.form__bottom {
  display: flex;
  align-items: center;

  @media (max-width: 899px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.form__disclaimer {
  @include text12-14;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: size(24px);
  max-width: size(350px);
  color: $color-white--72a;
  letter-spacing: 0.02em;

  @include viewport--md {
    margin-left: 24px;
    max-width: 350px;
  }

  @media (max-width: 899px) {
    margin-top: 15px;
    margin-left: 0;
  }
}

.form__error-message {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--error-color);

  max-height: 0;
  visibility: hidden;
  opacity: 0;

  transition: visibility $default-transition-settings, opacity $default-transition-settings, max-height $default-transition-settings;
}

.form__submit {
  @media (max-width: 899px) {
    width: 100%;
  }
}

.form__submit--loader {
  position: relative;
  pointer-events: none;

  span {
    visibility: hidden;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    width: 7px;
    height: 7px;

    background-color: rgba($color-neon-blue, 0.75);
    border-radius: 50%;
    box-shadow: -14px 0 0 $color-neon-blue, 14px 0 0 rgba($color-neon-blue, 0.5);
    transform: translate(-50%, -50%);

    animation: loader 1.5s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    background-color: rgba($color-neon-blue, 0.15);
  }
}

@keyframes loader {
  0% {
    background-color: rgba($color-neon-blue, 0.75);
    box-shadow: 14px 0 0 $color-neon-blue, -14px 0 0 rgba($color-neon-blue, 0.5);
  }

  25% {
    background-color: rgba($color-neon-blue, 0.5);
    box-shadow: 14px 0 0 rgba($color-neon-blue, 0.75), -14px 0 0 rgba($color-neon-blue, 0.75);
  }

  50% {
    background-color: rgba($color-neon-blue, 0.75);
    box-shadow: 14px 0 0 rgba($color-neon-blue, 0.5), -14px 0 0 $color-neon-blue;
  }

  75% {
    background-color: $color-neon-blue;
    box-shadow: 14px 0 0 rgba($color-neon-blue, 0.75), -14px 0 0 rgba($color-neon-blue, 0.75);
  }

  100% {
    background-color: rgba($color-neon-blue, 0.75);
    box-shadow: 14px 0 0 $color-neon-blue, -14px 0 0 rgba($color-neon-blue, 0.5);
  }
}
