.reviews-slider {
  .swiper-container {
    margin-top: size(-30px);
    margin-bottom: size(-30px);

    @include viewport--md {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .swiper-wrapper {
    padding-top: size(50px);
    padding-bottom: size(50px);

    @include viewport--md {
      padding-top: 44px;
      padding-bottom: 50px;
    }
  }

  .slider__slide {
    width: size(288px);
    height: auto;

    @include viewport--md {
      width: 288px;
    }

    @include viewport--sm {
      padding-right: 20px;
    }

    @media (max-width: 599px) {
      padding-right: 0;
    }

    .review-item {
      height: 100%;
    }
  }

  &__item {
    transition: transform 0.3s ease;
    transform-style: preserve-3d;
    will-change: transform;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus-visible {
        transform: scale(1.05);
      }
    }

    &:last-child {
      @include lightning-bg;
      position: relative;
      width: size(288px);
      padding: size(27px) size(30px) size(30px);
      margin-right: size(96px);

      display: flex;
      flex-direction: column;

      height: auto;

      transition: transform $default-transition-settings;

      html.dark & {
        border-color: $color-white;
      }

      @include viewport--md {
        width: 256px;
        border-width: 2px;

        min-height: 337px;

        margin-right: 85px;
      }

      @include viewport--sm {
        padding: 22px 14px;
        margin-right: 0;
      }

      @media (max-width: 599px) {
        width: 100%;
        height: 100%;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus-visible {
          transform: scale(1.05);
          .reviews-slider__all-link {
            color: $color-neon-blue;
          }
        }
      }
    }
  }

  &__item-wrapper {
    height: 100%;
  }

  &__all-link {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $color-black;
    @include text20;

    transition: color $default-transition-settings, opacity $default-transition-settings;

    html.dark & {
      color: $color-default-white;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus-visible {
          color: $color-torch-red !important;
        }
      }
    }

    span {
      position: relative;

      &::after {
        content: attr(data-quantity);
        position: absolute;
        right: 0;
        transform: translateX(100%);
        font-size: size(10px);
        line-height: size(8px);

        color: $color-torch-red;
      }

    }
  }
}
