.team {
  @include section-pb;

  &--results {
    padding-bottom: 0;
  }
}

.team__list {
  margin: size(-32px) size(-16px) size(103px);
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @include viewport--md {
    margin: -32px -12px 74px;
  }

  @include viewport--sm {
    margin-bottom: 46px;
  }

  @media (max-width: 499px) {
    flex-direction: column;
    margin: -24px 0 46px;
  }
}

.team__member {
  margin-top: size(32px);
  display: flex;

  padding: 0 size(16px);
  flex-grow: 0;
  flex-shrink: 0;

  width: 25%;

  @include viewport--md {
    width: 25%;
    padding: 0 12px;
    margin-top: 24px;
  }

  @include viewport--sm {
    width: 50%;
    padding: 0 8px;
  }

  @media (max-width: 499px) {
    width: 100%;
    padding: 0;
  }
}

.team__more {
  @include text30;
  max-width: size(900px);
  position: relative;
  margin-bottom: size(107px);
  padding-left: size(96px);

  // &--results {
  //   margin: size(80px) auto;

  //   @include viewport--md {
  //     margin: 46px auto;
  //   }
  // }

  @include viewport--md {
    margin-bottom: 74px;
  }

  @include viewport--sm {
    margin-bottom: 46px;
    padding-left: 64px;
  }

  @media (max-width: 499px) {
    padding-left: 0;
  }
}

.team__more-icon {
  display: flex;
  position: absolute;
  left: 0;
  top: size(14px);

  @media (max-width: 499px) {
    display: none;
  }

  svg {
    width: size(24px);
    height: size(14px);

    @include viewport--md {
      width: 24px;
      height: 14px;
    }
  }
}

.team__more-text {
  span {
    font-weight: 600;
  }
}

.team__photo {
  position: relative;
  margin: 0;
  border: size(2px) solid $color-black;
  transition: border $default-transition-settings;

  @include viewport--md {
    border-width: 2px;
  }

  html.dark {
    border-color: $color-white;
  }

  img {
    width: 100%;
  }
}

.team__photo-caption {
  position: absolute;
  top: size(32px);
  right: size(32px);
  max-width: size(197px);
  color: $color-white;
  letter-spacing: 0.02em;
  text-align: right;

  @include viewport--md {
    top: 24px;
    right: 24px;
  }

  @include viewport--sm {
    top: 0;
    left: 12px;
    right: 12px;
    max-width: none;
    font-size: 12px;
  }
}
